/*----------------------------------------
    Vendor Dokan Store Grid Page
-----------------------------------------*/
.vendor-toolbox .toolbox-sort select {
    width: 15.2rem;
    padding: 0.61em 2.5rem 0.61em 1.5rem;
    font-size: 1.4rem;
    border-color: #edecec;
  }
  .vendor-toolbox .select-box::before {
    right: 1rem;
    margin-top: -0.1rem;
  }
  
  .vendor-map-filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    background: #f5f5f5;
    padding: 2rem 2rem 1rem;
  }
  .vendor-map-filter .form-control {
    margin-right: 1rem;
    background: #fff;
    border: none;
    min-height: 4.4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .vendor-map-filter input {
    width: 58.9rem;
  }
  .vendor-map-filter .select-box::before {
    z-index: 2;
    font-size: 1.2rem;
    right: 2.3rem;
  }
  .vendor-map-filter select {
    min-width: 22rem;
    font-size: 1.4rem;
    color: #333;
  }
  .vendor-map-filter select option {
    font-size: 1.4rem;
  }
  .vendor-map-filter .btn {
    padding: 0.93em 2.97em;
  }
  
  .vendor-filter,
  .vendor-filter-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .vendor-filter {
    padding: 3rem 0 1rem;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    border-bottom: 1px solid #eee;
  }
  .vendor-filter .select-box::before {
    font-weight: 400;
    right: 1.4rem;
    margin-top: -0.1rem;
  }
  .vendor-filter select {
    font-size: 1.3rem;
    border-color: #ccc;
    border-radius: 0.3rem;
    color: #333;
    padding-left: 1.5rem;
    min-width: 19rem;
    max-width: none;
  }
  .vendor-filter select option {
    font-size: 1.4rem;
  }
  .vendor-filter .btn {
    padding: 0.81em 1.4em;
    background-color: #eee;
    border-color: #eee;
    font-size: 1.3rem;
    color: #333;
  }
  .vendor-filter .btn:hover, .vendor-filter .btn:focus, .vendor-filter .btn:active {
    background-color: #dcdcdc;
    border-color: #dcdcdc;
  }
  
  .store-wcmp .store-banner img {
    min-height: 31.8rem;
  }
  
  .store-wcmp-banner {
    margin-bottom: 3.5rem;
  }
  .store-wcmp-banner .store-media img {
    min-height: 39rem;
  }
  
  .tab-vendor-products.tab-nav-underline .nav-link {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .tab-vendor-products.tab-nav-underline .nav-link::after {
    border-top-width: 2px;
  }
  .tab-vendor-products.tab-nav-underline .nav-item:not(:last-child) {
    margin-right: 1rem;
  }
  .tab-vendor-products .toolbox {
    padding: 0.7rem 0 1rem;
  }
  .tab-vendor-products .toolbox label {
    font-size: 1.4rem;
  }
  .tab-vendor-products .comments {
    margin: 0;
    padding: 0;
  }
  .tab-vendor-products .comment {
    border: none;
    padding-top: 2rem;
    font-size: 1.4rem;
  }
  .tab-vendor-products .comment p {
    margin-bottom: 0;
  }
  .tab-vendor-products .ratings-container {
    font-size: 1.4rem;
  }
  .tab-vendor-products .ratings-container .ratings::before {
    color: #FF7272;
  }
  .tab-vendor-products .comment-author {
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
  }
  .tab-vendor-products .comment-date {
    font-size: 1.4rem;
    color: #666;
  }
  
  .review-title {
    font-size: 1.8rem;
  }
  
  .widget-search-products .input-wrapper-inline .form-control {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(100% - 9.3rem);
            flex: 0 0 calc(100% - 9.3rem);
    max-width: calc(100% - 9.3rem);
  }
  
  .store-wcfm-banner {
    margin-bottom: 2.7rem;
  }
  .store-wcfm-banner .store-media img {
    min-height: 46rem;
  }
  
  .widget-coupons .coupon {
    padding: 1.2rem 2rem;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 3px;
    font-weight: 600;
    display: inline-block;
    border: 1px dashed #666;
  }
  .widget-coupons .coupon:hover .coupon-tip {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%, 2rem);
            transform: translate(-50%, 2rem);
  }
  .widget-coupons .coupon-tip {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 15.8rem;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid #17a2b8;
    background-color: #fff;
    border-radius: 0.5rem;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s, -webkit-transform 0.3s;
    z-index: 1;
  }
  .widget-coupons .coupon-tip::before, .widget-coupons .coupon-tip::after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .widget-coupons .coupon-tip::after {
    border: 3px solid transparent;
    border-bottom: 7px solid #fff;
  }
  .widget-coupons .coupon-tip::before {
    border: 4px solid transparent;
    border-bottom: 8px solid #17a2b8;
  }
  
  .vendor-sidebar .widget-title i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    margin-top: -0.5rem;
    line-height: 0;
  }
  .vendor-sidebar .widget-title i.w-icon-truck {
    font-size: 2rem;
  }
  
  .widget-location .google-map {
    height: 28rem;
    background-color: #ddd;
  }
  
  .policies-area {
    margin-bottom: 2.5rem;
    border-bottom: 1px solid #eee;
  }
  .policies-area .title {
    font-weight: 600;
    margin-bottom: 3rem;
  }
  .policies-area p {
    padding-bottom: 2.1rem;
  }
  
  .tab-vendor-wcfm .nav-link {
    padding: 1.5rem 0;
    font-size: 1.5rem;
    text-transform: capitalize;
  }
  .tab-vendor-wcfm .tab-pane {
    padding: 3rem 0;
  }
  
  .review-area {
    margin-bottom: 3rem;
    padding: 3rem;
    border: 1px solid #eee;
    border-radius: 0.3rem;
  }
  .review-area .form-control {
    margin-bottom: 2.2rem;
    background: #fff url(../../../public/assets/images/vendor/wcfm/write.jpg) no-repeat 10px center;
    min-width: 4.6rem;
    padding-left: 3.5rem;
    color: #777;
  }
  .review-area .btn {
    padding-left: 1.35em;
    padding-right: 1.35em;
    border-color: #eee;
    background-color: #eee;
    color: #333;
  }
  .review-area .btn:hover, .review-area .btn:focus, .review-area .btn:active {
    border-color: #333;
    background-color: #333;
    color: #fff;
  }
  
  .reviewers-picture figure {
    width: 3.6rem;
    height: 3.6rem;
    border: 2px solid #fff;
    border-radius: 50%;
  }
  .reviewers-picture figure:not(:first-child) {
    margin-left: -1.5rem;
  }
  
  .reviewer-name {
    font-size: 1.6rem;
  }
  .reviewer-name a {
    color: #333;
  }
  .reviewer-name a:hover {
    color: #552278;
  }
  
  .review-ratings {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #eee;
    padding-bottom: 2.6rem;
    margin-bottom: 3.5rem;
  }
  .review-ratings .ratings-container {
    margin-bottom: 1.3rem;
    font-size: 1.4rem;
  }
  .review-ratings .ratings-full {
    margin-right: 0.8rem;
  }
  .review-ratings label {
    white-space: nowrap;
  }
  .review-ratings .review-ratings-right {
    margin-right: 6rem;
  }
  
  .average-rating {
    display: inline-block;
    background: #4e4e4e;
    color: #fff;
    font-size: 2.6rem;
    padding: 0.1rem 1.3rem;
    border-radius: 0.3rem;
    margin-bottom: 0.5rem;
  }
  
  .user-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .user-wrap .review-ratings {
    display: block;
    margin-right: 1.5rem;
    margin-bottom: 0;
    border: none;
    padding: 0;
  }
  
  .user-photo {
    margin-right: 3.6rem;
  }
  .user-photo figure {
    width: 70px;
    margin-bottom: 1.5rem;
  }
  .user-photo label {
    display: block;
    margin-bottom: 0.3rem;
  }
  .user-photo .score {
    background: #666;
    color: #fff;
    padding: 0.1rem 1.2rem;
    border-radius: 0.3rem;
  }
  
  .user-info {
    max-width: 49.4rem;
    margin-right: auto;
    padding-right: 2rem;
  }
  .user-info p {
    font-size: 1.4rem;
    line-height: 1.78;
  }
  
  .vendor-wcfm-map .google-map {
    background-color: #ebebeb;
  }
  
  .widget-filter .form-control {
    height: 4.6rem;
    max-width: 100%;
    padding-left: 1.5rem;
    border-color: #eee;
    border-radius: 0.3rem;
    font-size: 1.3rem;
    color: #666;
    margin-bottom: 1rem;
  }
  .widget-filter .widget-body {
    padding: 0.4rem 0 2.3rem;
  }
  
  .vendor-sidebar .widget-search-form .widget-body {
    padding: 0 0 3.5rem;
  }
  
  .wcfm-toolbox {
    padding-bottom: 1rem;
  }
  .wcfm-toolbox select {
    font-size: 1.3rem;
    border-color: #eee;
    width: 24rem;
    max-width: none;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
  }
  .wcfm-toolbox option {
    font-size: 1.3rem;
  }
  .wcfm-toolbox .showing-info {
    color: #333;
    font-weight: 400;
  }
  
  .store-wcfm .store-content {
    max-width: none;
  }
  
  .default-section {
    margin-bottom: 1.7rem;
  }
  
  .vendors-section {
    background-color: #f8f8f8;
  }
  .vendors-section .vendor-widget {
    margin-bottom: 0;
  }
  
  @media (max-width: 1036px) {
    .store-wcfm .store-footer {
      padding-top: 5rem;
    }
    .store-wcfm .seller-brand {
      right: auto;
      left: 2rem;
      top: -39%;
    }
  }
  @media (max-width: 991px) {
    .store-wcmp .store-banner img {
      min-height: auto;
      max-height: 20rem;
    }
  
    .review-area {
      padding: 2rem;
    }
  
    .default-section {
      margin-bottom: 5rem;
    }
  }
  @media (max-width: 767px) {
    .review-ratings .review-ratings-right {
      margin-right: 0;
    }
  
    .user-wrap .review-ratings {
      display: none;
    }
  
    .user-photo {
      margin-right: 2rem;
    }
  
    .user-info {
      padding-right: 0;
    }
  }
  @media (max-width: 575px) {
    .store-list:hover .seller-brand img {
      -webkit-transform: translateY(-1rem);
              transform: translateY(-1rem);
    }
  }
  @media (max-width: 479px) {
    .wcfm-toolbox {
      display: block;
    }
  
    .review-ratings {
      display: block;
    }
    .review-ratings .ratings-container {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
    }
  
    .average-rating {
      margin-top: 1rem;
    }
  
    .tab-vendor-products .nav-tabs {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
  }