.chat-tooltip {
  position: relative;
  text-align: center;
  cursor: not-allowed;
}

.chat-tooltip::after {
  background-color: #552278;
  border-radius: 10px;
  color: #fff;
  display: none;
  padding: 10px 10px;
  position: absolute;
  text-align: center;
  z-index: 1500;
}

.chat-tooltip::before {
  background-color: #552278;
  content: " ";
  display: none;
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 1900;
}

.chat-tooltip:hover::after {
  display: block;
  width: 200px;
}

.chat-tooltip:hover::before {
  display: block;
}

.chat-tooltip.top::after {
  content: "top";
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

.chat-tooltip.top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.chat-tooltip.bottom::after {
  content: "Sign in to chat with supplier.";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
  font-size: 65%;
}

.chat-tooltip.bottom::before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.chat-tooltip.right::after {
  content: "right";
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 10px));
}

.chat-tooltip.right::before {
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 5px), -50%) rotate(45deg);
}

.chat-tooltip.left::after {
  content: "Sign in to chat with supplier.";
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 10px));
  font-size: 65%;
}

.chat-tooltip.left::before {
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
}

.follow-seller-btn2 {
  display: inline-flex !important;
  position: absolute !important;
  top: -42px !important;
  right: 10px !important;
}