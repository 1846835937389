.wolmart {

    /*! CSS Used from: Embedded */
    .anticon {
        display: inline-block;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .anticon>* {
        line-height: 1;
    }

    .anticon svg {
        display: inline-block;
    }

    .anticon::before {
        display: none;
    }

    /*! CSS Used from: Embedded */
    :where(.css-fpg3f5)[class^="ant-select"],
    :where(.css-fpg3f5)[class*=" ant-select"] {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-size: 14px;
        box-sizing: border-box;
    }

    :where(.css-fpg3f5)[class^="ant-select"]::before,
    :where(.css-fpg3f5)[class*=" ant-select"]::before,
    :where(.css-fpg3f5)[class^="ant-select"]::after,
    :where(.css-fpg3f5)[class*=" ant-select"]::after {
        box-sizing: border-box;
    }

    :where(.css-fpg3f5)[class^="ant-select"] [class^="ant-select"],
    :where(.css-fpg3f5)[class*=" ant-select"] [class^="ant-select"],
    :where(.css-fpg3f5)[class^="ant-select"] [class*=" ant-select"],
    :where(.css-fpg3f5)[class*=" ant-select"] [class*=" ant-select"] {
        box-sizing: border-box;
    }

    :where(.css-fpg3f5)[class^="ant-select"] [class^="ant-select"]::before,
    :where(.css-fpg3f5)[class*=" ant-select"] [class^="ant-select"]::before,
    :where(.css-fpg3f5)[class^="ant-select"] [class*=" ant-select"]::before,
    :where(.css-fpg3f5)[class*=" ant-select"] [class*=" ant-select"]::before,
    :where(.css-fpg3f5)[class^="ant-select"] [class^="ant-select"]::after,
    :where(.css-fpg3f5)[class*=" ant-select"] [class^="ant-select"]::after,
    :where(.css-fpg3f5)[class^="ant-select"] [class*=" ant-select"]::after,
    :where(.css-fpg3f5)[class*=" ant-select"] [class*=" ant-select"]::after {
        box-sizing: border-box;
    }

    :where(.css-fpg3f5).ant-select {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    :where(.css-fpg3f5).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #ffffff;
        border: 1px solid #d9d9d9;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    :where(.css-fpg3f5).ant-select:not(.ant-select-customize-input) .ant-select-selector input {
        cursor: pointer;
    }

    :where(.css-fpg3f5).ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        outline: none;
        appearance: none;
    }

    :where(.css-fpg3f5).ant-select .ant-select-selection-item {
        flex: 1;
        font-weight: normal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    :where(.css-fpg3f5).ant-select .ant-select-arrow {
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.25);
        font-style: normal;
        line-height: 1;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        top: 50%;
        inset-inline-start: auto;
        inset-inline-end: 11px;
        height: 12px;
        margin-top: -6px;
        font-size: 12px;
        pointer-events: none;
    }

    :where(.css-fpg3f5).ant-select .ant-select-arrow>* {
        line-height: 1;
    }

    :where(.css-fpg3f5).ant-select .ant-select-arrow svg {
        display: inline-block;
    }

    :where(.css-fpg3f5).ant-select .ant-select-arrow .anticon {
        vertical-align: top;
        transition: transform 0.3s;
    }

    :where(.css-fpg3f5).ant-select .ant-select-arrow .anticon>svg {
        vertical-align: top;
    }

    :where(.css-fpg3f5).ant-select .ant-select-clear {
        position: absolute;
        top: 50%;
        inset-inline-start: auto;
        inset-inline-end: 11px;
        z-index: 1;
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-top: -6px;
        color: rgba(0, 0, 0, 0.25);
        font-size: 12px;
        font-style: normal;
        line-height: 1;
        text-align: center;
        text-transform: none;
        background: #ffffff;
        cursor: pointer;
        opacity: 0;
        transition: color 0.2s ease, opacity 0.3s ease;
        text-rendering: auto;
    }

    :where(.css-fpg3f5).ant-select .ant-select-clear:before {
        display: block;
    }

    :where(.css-fpg3f5).ant-select .ant-select-clear:hover {
        color: rgba(0, 0, 0, 0.45);
    }

    :where(.css-fpg3f5).ant-select:hover .ant-select-clear {
        opacity: 1;
    }

    :where(.css-fpg3f5).ant-select-single {
        font-size: 14px;
    }

    :where(.css-fpg3f5).ant-select-single .ant-select-selector {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        display: flex;
        border-radius: 6px;
    }

    :where(.css-fpg3f5).ant-select-single .ant-select-selector .ant-select-selection-search {
        position: absolute;
        top: 0;
        inset-inline-start: 11px;
        inset-inline-end: 11px;
        bottom: 0;
    }

    :where(.css-fpg3f5).ant-select-single .ant-select-selector .ant-select-selection-search-input {
        width: 100%;
    }

    :where(.css-fpg3f5).ant-select-single .ant-select-selector .ant-select-selection-item {
        padding: 0;
        line-height: 30px;
        transition: all 0.3s, visibility 0s;
    }

    :where(.css-fpg3f5).ant-select-single .ant-select-selector .ant-select-selection-item {
        position: relative;
        user-select: none;
    }

    :where(.css-fpg3f5).ant-select-single .ant-select-selector:after,
    :where(.css-fpg3f5).ant-select-single .ant-select-selector .ant-select-selection-item:after {
        display: inline-block;
        width: 0;
        visibility: hidden;
        content: "\a0";
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-inline-end: 18px;
    }

    :where(.css-fpg3f5).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 32px;
        padding: 0 11px;
    }

    :where(.css-fpg3f5).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 30px;
    }

    :where(.css-fpg3f5).ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
        line-height: 30px;
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg {
        font-size: 16px;
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg .ant-select-selector {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 16px;
        line-height: 1.5714285714285714;
        list-style: none;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        display: flex;
        border-radius: 8px;
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-search {
        position: absolute;
        top: 0;
        inset-inline-start: 11px;
        inset-inline-end: 11px;
        bottom: 0;
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-search-input {
        width: 100%;
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-item {
        padding: 0;
        line-height: 38px;
        transition: all 0.3s, visibility 0s;
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-item {
        position: relative;
        user-select: none;
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg .ant-select-selector:after,
    :where(.css-fpg3f5).ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-item:after {
        display: inline-block;
        width: 0;
        visibility: hidden;
        content: "\a0";
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg.ant-select-show-arrow .ant-select-selection-item {
        padding-inline-end: 20px;
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 40px;
        padding: 0 11px;
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 38px;
    }

    :where(.css-fpg3f5).ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector:after {
        line-height: 38px;
    }

    :where(.css-fpg3f5).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
        border-color: #4096ff;
    }

    /*! CSS Used from: Embedded */
    :where(.css-fpg3f5)[class*=" ant-cascader"] {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-size: 14px;
        box-sizing: border-box;
    }

    :where(.css-fpg3f5)[class*=" ant-cascader"]::before,
    :where(.css-fpg3f5)[class*=" ant-cascader"]::after {
        box-sizing: border-box;
    }

    :where(.css-fpg3f5).ant-cascader {
        width: 184px;
    }

    /*! CSS Used from: https://sell.commercial.supplyzone.ai/static/css/5.72191ca7.chunk.css */
    ._2ZqBe {
        text-anchor: middle;
        fill: #2a2a2a;
        font-family: Helvetica-Neue-Medium;
    }

    ._RPY5U {
        text-anchor: middle;
        fill: #2a2a2a;
        font-family: Helvetica-Neue-Medium;
    }

    ._36LeG {
        fill: #4d535b;
    }

    ._ITSBs {
        fill: #4d535b;
    }

    [data-icon]:before {
        content: attr(data-icon);
    }

    [data-icon]:before {
        font-family: "socicon" !important;
        font-style: normal !important;
        font-weight: 400 !important;
        -webkit-font-feature-settings: normal !important;
        font-feature-settings: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /*! CSS Used from: https://sell.commercial.supplyzone.ai/static/css/main.92a532cb.chunk.css */
    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    h3 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 600;
        line-height: 1.2;
        color: #181c32;
    }

    h3 {
        font-size: calc(1.26rem + .12vw);
    }

    @media (min-width:1200px) {
        h3 {
            font-size: 1.35rem;
        }
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ul {
        padding-left: 0.75rem;
    }

    ul {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    a {
        color: #552278;
        text-decoration: none;
    }

    a:hover {
        color: #0c1d37;
        /* text-decoration: underline; */
    }

    img,
    svg {
        vertical-align: middle;
    }

    label {
        display: inline-block;
    }

    button {
        border-radius: 0;
    }

    button:focus:not(:focus-visible) {
        outline: 0;
    }

    button,
    input,
    select {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    button,
    select {
        text-transform: none;
    }

    select {
        word-wrap: normal;
    }

    select:disabled {
        opacity: 1;
    }

    [type=button],
    button {
        -webkit-appearance: button;
    }

    [type=button]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
    }

    [type=search] {
        outline-offset: -2px;
        -webkit-appearance: textfield;
    }

    [hidden] {
        display: none !important;
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(var(--bs-gutter-y)*-1);
        margin-right: calc(var(--bs-gutter-x)*-0.5);
        margin-left: calc(var(--bs-gutter-x)*-0.5);
    }

    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x)*0.5);
        padding-left: calc(var(--bs-gutter-x)*0.5);
        margin-top: var(--bs-gutter-y);
    }

    @media (min-width:768px) {
        .col-md-3 {
            flex: 0 0 auto;
            width: 25%;
        }

        .col-md-9 {
            flex: 0 0 auto;
            width: 75%;
        }
    }

    @media (min-width:992px) {
        .col-lg-1 {
            flex: 0 0 auto;
            width: 8.33333333%;
        }

        .col-lg-2 {
            flex: 0 0 auto;
            width: 16.66666667%;
        }

        .col-lg-3 {
            flex: 0 0 auto;
            width: 25%;
        }

        .col-lg-4 {
            flex: 0 0 auto;
            width: 33.33333333%;
        }

        .col-lg-5 {
            flex: 0 0 auto;
            width: 41.66666667%;
        }

        .col-lg-6 {
            flex: 0 0 auto;
            width: 50%;
        }

        .col-lg-9 {
            flex: 0 0 auto;
            width: 75%;
        }

        .col-lg-12 {
            flex: 0 0 auto;
            width: 100%;
        }
    }

    @media (min-width:1200px) {
        .col-xl-2 {
            flex: 0 0 auto;
            width: 16.66666667%;
        }

        .col-xl-3 {
            flex: 0 0 auto;
            width: 25%;
        }

        .col-xl-4 {
            flex: 0 0 auto;
            width: 33.33333333%;
        }

        .col-xl-5 {
            flex: 0 0 auto;
            width: 41.66666667%;
        }

        .col-xl-6 {
            flex: 0 0 auto;
            width: 50%;
        }

        .col-xl-9 {
            flex: 0 0 auto;
            width: 75%;
        }

        .col-xl-12 {
            flex: 0 0 auto;
            width: 100%;
        }
    }

    .col-form-label {
        font-weight: 500;
        color: #3f4254;
    }

    .col-form-label {
        padding-top: calc(.75rem + 1px);
        padding-bottom: calc(.75rem + 1px);
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.5;
    }

    .form-text {
        margin-top: .5rem;
        /*font-size: .925rem;*/
        color: #a1a5b7;
    }

    .form-control {
        display: block;
        width: 100%;
        padding: .75rem 1rem;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.5;
        color: #181c32;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e4e6ef;
        -webkit-appearance: none;
        appearance: none;
        border-radius: .475rem;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    @media (prefers-reduced-motion:reduce) {
        .form-control {
            transition: none;
        }
    }

    .form-control:focus {
        color: #181c32;
        background-color: #fff;
        border-color: #b5b5c3;
        outline: 0;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 0 .25rem rgba(26, 61, 118, .25);
    }

    .form-control::placeholder {
        color: #a1a5b7;
        opacity: 1;
    }

    .form-control:disabled {
        background-color: #eff2f5;
        opacity: 1;
    }

    .form-control-lg {
        min-height: calc(1.5em + 1.65rem + 2px);
        padding: .825rem 1.5rem;
        font-size: 1.15rem;
        border-radius: .475rem;
    }

    .form-select {
        display: block;
        width: 100%;
        padding: .75rem 3rem .75rem 1rem;
        -moz-padding-start: calc(1rem - 3px);
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.5;
        color: #181c32;
        background-color: #fff;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%237e8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 16px 12px;
        border: 1px solid #e4e6ef;
        border-radius: .475rem;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        appearance: none;
    }

    @media (prefers-reduced-motion:reduce) {
        .form-select {
            transition: none;
        }
    }

    .form-select:focus {
        border-color: #b5b5c3;
        outline: 0;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 0 .25rem rgba(26, 61, 118, .25);
    }

    .form-select:disabled {
        background-color: #eff2f5;
    }

    .form-check {
        display: block;
        min-height: 1.5rem;
        padding-left: 2.25rem;
        margin-bottom: .125rem;
    }

    .form-check .form-check-input {
        float: left;
        margin-left: -2.25rem;
    }

    .form-check-input {
        width: 1.75rem;
        height: 1.75rem;
        margin-top: -.125rem;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        border: 1px solid rgba(0, 0, 0, .25);
        -webkit-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    @media (prefers-reduced-motion:reduce) {
        .form-check-input {
            transition: none;
        }
    }

    .form-check-input[type=checkbox] {
        border-radius: .45em;
    }

    .form-check-input[type=radio] {
        border-radius: 50%;
    }

    .form-check-input:active {
        -webkit-filter: brightness(90%);
        filter: brightness(90%);
    }

    .form-check-input:focus {
        border-color: #b5b5c3;
        outline: 0;
        box-shadow: none;
    }

    .form-check-input:checked {
        background-color: #552278;
        border-color: #552278;
    }

    .form-check-input:checked[type=checkbox] {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='11' fill='none'%3E%3Cpath d='M11.043 1.029a.792.792 0 111.206 1.025l-6.73 7.917a.792.792 0 01-1.128.079L.828 6.883A.792.792 0 111.88 5.7l2.958 2.629 6.205-7.3z' fill='%23fff'/%3E%3C/svg%3E");
    }

    .form-check-input:checked[type=radio] {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
    }

    .form-check-input:disabled {
        pointer-events: none;
        -webkit-filter: none;
        filter: none;
        opacity: .5;
    }

    .form-check-input:disabled~.form-check-label {
        opacity: .5;
    }

    .form-switch {
        padding-left: 3.75rem;
    }

    .form-switch .form-check-input {
        width: 3.25rem;
        margin-left: -3.75rem;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
        background-position: 0;
        border-radius: 3.25rem;
        transition: background-position .15s ease-in-out;
    }

    @media (prefers-reduced-motion:reduce) {
        .form-switch .form-check-input {
            transition: none;
        }
    }

    .form-switch .form-check-input:focus {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23b5b5c3'/%3E%3C/svg%3E");
    }

    .form-switch .form-check-input:checked {
        background-position: 100%;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    }

    .btn {
        display: inline-block;
        font-weight: 500;
        line-height: 1.5;
        color: #181c32;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        background-color: initial;
        border: 1px solid transparent;
        padding: .75rem 1.5rem;
        font-size: 1.1rem;
        border-radius: .475rem;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    @media (prefers-reduced-motion:reduce) {
        .btn {
            transition: none;
        }
    }

    .btn:hover {
        color: #181c32;
        text-decoration: none;
    }

    .btn:focus {
        outline: 0;
        box-shadow: 0 0 0 .25rem rgba(26, 61, 118, .25);
    }

    .btn:active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .btn:active:focus {
        box-shadow: 0 0 0 .25rem rgba(26, 61, 118, .25), inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .btn:disabled {
        pointer-events: none;
        opacity: .6;
        box-shadow: none;
    }

    .btn-primary {
        color: #fff;
        background-color: #552278;
        border-color: #552278;
        box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(0, 0, 0, .075);
    }

    .btn-primary:focus,
    .btn-primary:hover {
        color: #fff;
        background-color: #163464;
        border-color: #15315e;
    }

    .btn-primary:focus {
        box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 .25rem rgba(60, 90, 139, .5);
    }

    .btn-primary:active {
        color: #fff;
        background-color: #15315e;
        border-color: #142e59;
    }

    .btn-primary:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .25rem rgba(60, 90, 139, .5);
    }

    .btn-primary:disabled {
        color: #fff;
        background-color: #552278;
        border-color: #552278;
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .nav-link {
        display: block;
        padding: .5rem 1rem;
        color: #552278;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    }

    @media (prefers-reduced-motion:reduce) {
        .nav-link {
            transition: none;
        }
    }

    .nav-link:focus,
    .nav-link:hover {
        color: #0c1d37;
        text-decoration: none;
    }

    .tab-content>.tab-pane {
        display: none;
    }

    .tab-content>.active {
        display: block;
    }

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: initial;
        border: 1px solid #eff2f5;
        border-radius: .475rem;
    }

    .card-body {
        flex: 1 1 auto;
        padding: 1rem;
    }

    .card-header {
        padding: .5rem 1rem;
        margin-bottom: 0;
        background-color: #fff;
        border-bottom: 1px solid #eff2f5;
    }

    .card-header:first-child {
        border-radius: calc(.475rem - 1px) calc(.475rem - 1px) 0 0;
    }

    .overflow-auto {
        overflow: auto !important;
    }

    .d-flex {
        display: flex !important;
    }

    .flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .align-items-center {
        align-items: center !important;
    }

    .mt-0 {
        margin-top: 0 !important;
    }

    .mt-5 {
        margin-top: 1.25rem !important;
    }

    .mt-10 {
        margin-top: 2.5rem !important;
    }

    .me-3 {
        margin-right: .75rem !important;
    }

    .me-4 {
        margin-right: 1rem !important;
    }

    .mb-3 {
        margin-bottom: .75rem !important;
    }

    .mb-5 {
        margin-bottom: 1.25rem !important;
    }

    .mb-10 {
        margin-bottom: 2.5rem !important;
    }

    .p-5 {
        padding: 1.25rem !important;
    }

    .p-7 {
        padding: 1.75rem !important;
    }

    .py-8 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .pt-0 {
        padding-top: 0 !important;
    }

    .pt-3 {
        padding-top: .75rem !important;
    }

    .fw-bold {
        font-weight: 500 !important;
    }

    @media (min-width:992px) {
        .text-lg-end {
            text-align: right !important;
        }
    }

    .border-transparent {
        border-color: transparent !important;
    }

    .text-muted {
        color: #a1a5b7 !important;
    }

    .text-gray-800 {
        color: #3f4254 !important;
    }

    a,
    a:hover {
        transition: color .2s ease, background-color .2s ease;
    }

    .nav-line-tabs {
        border-bottom: 1px solid #eff2f5;
    }

    .nav-line-tabs .nav-item {
        margin-bottom: -1px;
    }

    .nav-line-tabs .nav-item .nav-link {
        color: #a1a5b7;
        border: 0;
        border-bottom: 1px solid transparent;
        transition: color .2s ease, background-color .2s ease;
        padding: .5rem 0;
        margin: 0 1rem;
    }

    .nav-line-tabs .nav-item:first-child .nav-link {
        margin-left: 0;
    }

    .nav-line-tabs .nav-item:last-child .nav-link {
        margin-right: 0;
    }

    .nav-line-tabs .nav-item .nav-link.active,
    .nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
        background-color: initial;
        border: 0;
        border-bottom: 1px solid #552278;
        transition: color .2s ease, background-color .2s ease;
    }

    .nav-stretch,
    .nav-stretch .nav-item {
        align-items: stretch;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .nav-stretch .nav-item {
        display: flex;
    }

    .nav-stretch .nav-link {
        display: flex;
        align-items: center;
    }

    .card {
        border: 0;
        box-shadow: 0 0 20px 0 rgba(76, 87, 125, .02);
    }

    .card .card-header {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        min-height: 70px;
        padding: 0 2.25rem;
        background-color: initial;
    }

    .card .card-body {
        padding: 2rem 2.25rem;
    }

    .card .card-header-stretch {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        align-items: stretch;
    }

    @media (max-width:767.98px) {
        .card>.card-header:not(.flex-nowrap) {
            padding-top: .5rem;
            padding-bottom: .5rem;
        }
    }

    .btn {
        outline: none !important;
    }

    .btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
        box-shadow: none !important;
    }

    .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
        border: 0;
        padding: calc(.75rem + 1px) calc(1.5rem + 1px);
    }

    .btn.btn-outline-dashed {
        border: 1px dashed #e4e6ef;
    }

    .btn.btn-primary {
        color: #fff;
        border-color: #552278;
        background-color: #552278;
    }

    .btn.btn-primary:active:not(.btn-active),
    .btn.btn-primary:focus:not(.btn-active),
    .btn.btn-primary:hover:not(.btn-active) {
        color: #fff;
        border-color: #194c9e;
        background-color: #194c9e !important;
    }

    .btn.btn-outline.btn-outline-dashed.btn-outline-default {
        color: #7e8299;
        border: 1px dashed #e4e6ef;
    }

    .btn.btn-outline.btn-outline-dashed.btn-outline-default:active:not(.btn-active),
    .btn.btn-outline.btn-outline-dashed.btn-outline-default:focus:not(.btn-active),
    .btn.btn-outline.btn-outline-dashed.btn-outline-default:hover:not(.btn-active) {
        color: #552278;
        border-color: #552278;
        background-color: #f1faff !important;
    }

    .form-control {
        box-shadow: none !important;
    }

    .form-control.form-control-solid {
        border-color: #f5f8fa;
        color: #5e6278;
    }

    .form-control.form-control-solid::placeholder {
        color: #a1a5b7;
    }

    .form-control.form-control-solid:active,
    .form-control.form-control-solid:focus {
        background-color: #eef3f7;
        border-color: #eef3f7;
        color: #5e6278;
        transition: color .2s ease, background-color .2s ease;
    }

    .form-select {
        box-shadow: none !important;
    }

    .form-select.form-select-solid {
        background-color: #f5f8fa;
        border-color: #f5f8fa;
        color: #5e6278;
        transition: color .2s ease, background-color .2s ease;
    }

    .form-select.form-select-solid::placeholder {
        color: #a1a5b7;
    }

    .form-select.form-select-solid:active,
    .form-select.form-select-solid:focus {
        background-color: #eef3f7;
        border-color: #eef3f7 !important;
        color: #5e6278;
        transition: color .2s ease, background-color .2s ease;
    }

    .form-check .form-check-label {
        cursor: pointer;
    }

    .form-check .form-check-input {
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
    }

    .form-check.form-check-custom {
        display: flex;
        align-items: center;
        padding-left: 0;
        margin: 0;
    }

    .form-check.form-check-custom .form-check-input {
        margin: 0;
        float: none;
        flex-shrink: 0;
    }

    .form-check.form-check-custom .form-check-label {
        margin-left: .55rem;
    }

    .form-check:not(.form-switch) .form-check-input[type=checkbox] {
        background-size: 60% 60%;
    }

    .form-check.form-check-solid .form-check-input {
        border: 0;
        background-color: #eff2f5;
    }

    .form-check.form-check-solid .form-check-input:active,
    .form-check.form-check-solid .form-check-input:focus {
        -webkit-filter: none;
        filter: none;
        background-color: #eff2f5;
    }

    .form-check.form-check-solid .form-check-input:checked {
        background-color: #552278;
    }

    .form-switch.form-check-solid .form-check-input {
        height: 2.25rem;
        border-radius: 3.25rem;
    }

    .form-switch.form-check-solid .form-check-input,
    .form-switch.form-check-solid .form-check-input:checked {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    }

    .form-switch.form-check-solid .form-check-input:checked {
        -webkit-filter: none;
        filter: none;
    }

    @media (min-width:992px) {

        div,
        span,
        ul {
            scrollbar-width: thin;
            scrollbar-color: #eff2f5 transparent;
        }

        div::-webkit-scrollbar,
        span::-webkit-scrollbar,
        ul::-webkit-scrollbar {
            width: .4rem;
            height: .4rem;
        }

        div::-webkit-scrollbar-thumb,
        span::-webkit-scrollbar-thumb,
        ul::-webkit-scrollbar-thumb {
            background-color: #eff2f5;
        }

        div:hover,
        span:hover,
        ul:hover {
            scrollbar-color: #e9edf1 transparent;
        }

        div:hover::-webkit-scrollbar-thumb,
        span:hover::-webkit-scrollbar-thumb,
        ul:hover::-webkit-scrollbar-thumb {
            background-color: #e9edf1;
        }
    }

    body a:active,
    body a:focus,
    body a:hover,
    html a:active,
    html a:focus,
    html a:hover {
        text-decoration: none !important;
    }

    .flex-box-center-children {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-content: space-around;
        justify-content: center;
        align-items: center;
    }

    .form-control.form-control-solid {
        border-color: #552278;
    }

    .form-control-lg {
        border-radius: .3rem;
    }

    label.file-uploader {
        display: flex !important;
        justify-content: center;
    }

    label.file-uploader>label>div>span:nth-child(2) {
        visibility: hidden;
        display: none;
    }

    ul li.progress-stepper-wrapper {
        display: flex;
        border-left: 3px solid #ccc;
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 5px;
        background-color: #fbf9f9;
        margin: 5px auto;
        border-radius: 5px;
    }

    ul li.progress-stepper-wrapper.active {
        background-color: #e2edff;
        border-left: 5px solid #552278;
        cursor: crosshair;
    }

    ul li.progress-stepper-wrapper:hover {
        background-color: 3px solid #ddd;
        border-left: 3px solid #552278;
        cursor: pointer;
    }

    .form-control.form-control-solid {
        background-color: #f5f8fa;
        transition: color .2s ease, background-color .2s ease;
    }

    .progress-stepper-value {
        font-weight: 700;
    }

    .w-100 {
        width: 100% !important;
    }

    .form-control.form-control-solid {
        background-color: #fff;
        border-color: #cac9ca;
        color: #333;
    }

    ul li.progress-stepper-wrapper {
        background-color: initial;
        border-left: 5px solid transparent;
        cursor: crosshair;
    }

    label.file-uploader {
        border: none !important;
    }

    .btn.btn-outline.btn-outline-dashed.btn-outline-default {
        color: #7e8299 !important;
        border: 1px dashed #e4e6ef !important;
    }

    .card {
        box-shadow: .1rem .1rem .5rem .25rem rgba(172, 173, 180, .5) !important;
        border-radius: 12px !important;
    }

    .form.mt-18 {
        margin-top: 60px;
    }

    .ant-select-selector:hover,
    .form-control.form-control-solid:active,
    .form-control.form-control-solid:focus,
    .form-select.form-select-solid:hover,
    .rsw-editor:hover {
        background-color: #fcfcfc !important;
        border: 2px solid #2178ff !important;
        color: #5e6278 !important;
        transition: color .2s ease, background-color .2s ease;
    }

    ._2ZqBe,
    ._RPY5U {
        text-anchor: middle;
        fill: #2a2a2a;
        font-family: inherit !important;
    }

    .ant-select-selector,
    .form-control.form-control-solid,
    .form-select.form-select-solid,
    .rsw-editor {
        border: 2px solid #dadce3 !important;
        background-color: #fcfcfc !important;

        border-radius: 7px !important;
        font-size: 16px !important;
        font-family: inherit !important;
        width: 100% !important;
        display: block !important;
    }

    .header-search .ant-select-selector {
        border-radius: 0px !important;
        border: 0px !important;
    }

    .header-search .ant-select-selector:hover {
        border: 0px !important;
    }

    .form .text-lg-end {
        text-align: left !important;
    }

    .btn.btn-primary.me-4 {
        border-radius: 5px !important;
        padding: 10px 20px !important;
    }

    /*! CSS Used from: Embedded */
    .rsw-editor {
        border: 1px solid #ddd;
        border-radius: .375rem;
        display: flex;
        flex-direction: column;
        min-height: 100px;
        overflow: hidden;
    }

    .rsw-ce {
        flex: 1 0 auto;
        outline: none;
        padding: .5rem;
    }

    .rsw-ce[contentEditable=true]:empty:not(:focus):before {
        color: grey;
        content: attr(placeholder);
    }

    .rsw-separator {
        align-self: stretch;
        border-right: 1px solid #ddd;
        display: flex;
        margin: 0 3px;
    }

    .rsw-dd {
        box-sizing: border-box;
        outline: none;
    }

    .rsw-btn {
        background: transparent;
        border: 0;
        color: #222;
        cursor: pointer;
        font-size: 1em;
        height: 2em;
        outline: none;
        padding: 0;
        width: 2em;
    }

    .rsw-btn:hover {
        background: #eaeaea;
    }

    .rsw-toolbar {
        align-items: center;
        background-color: #f5f5f5;
        border-bottom: 1px solid #ddd;
        display: flex;
    }

    /*! CSS Used from: Embedded */
    .dtlKip {
        position: relative;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        min-width: 322px;
        max-width: 508px;
        height: 48px;
        border: 2px dashed rgb(6, 88, 194);
        padding: 8px 16px 8px 8px;
        border-radius: 5px;
        cursor: pointer;
        -webkit-box-flex: 0;
        flex-grow: 0;
    }

    .dtlKip>input {
        display: block;
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }

    .gwDiyc {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-flex: 1;
        flex-grow: 1;
    }

    .gwDiyc>span {
        font-size: 12px;
        color: rgb(102, 102, 102);
    }

    .gwDiyc .file-types {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100px;
    }

    .cKeOpf {
        font-size: 14px;
        color: rgb(102, 102, 102);
    }

    .cKeOpf span {
        text-decoration: underline;
    }

    /*! CSS Used fontfaces */
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEz0dL-vwnYh2eg.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEzQdL-vwnYh2eg.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEzwdL-vwnYh2eg.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEzMdL-vwnYh2eg.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEz8dL-vwnYh2eg.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEz4dL-vwnYh2eg.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEzAdL-vwnYg.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc3CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc-CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc2CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc5CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc1CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc0CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc6CsTYl4BO.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xFIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xMIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xEIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xLIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xHIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xGIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xIIzIXKMny.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc3CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc-CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc2CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc5CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc1CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc0CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc6CsTYl4BO.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic3CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic-CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic2CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic5CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic1CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic0CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic6CsTYl4BO.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc3CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc-CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc2CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc5CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc1CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc0CsTYl4BOQ3o.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc6CsTYl4BO.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxFIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxMIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxEIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxLIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxHIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxGIzIXKMnyrYk.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxIIzIXKMny.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCxc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCxc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCRc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfABc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCBc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBxc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCxc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfChc4AMP6lbBP.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBBc4AMP6lQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: "socicon";
        src: url(https://sell.commercial.supplyzone.ai/static/media/socicon.ec23f2c6.eot);
        src: url(https://sell.commercial.supplyzone.ai/static/media/socicon.ec23f2c6.eot) format("embedded-opentype"), url(https://sell.commercial.supplyzone.ai/static/media/socicon.fa1cafd6.woff) format("woff"), url(https://sell.commercial.supplyzone.ai/static/media/socicon.578ecee5.ttf) format("truetype"), url(https://sell.commercial.supplyzone.ai/static/media/socicon.d1edd88f.svg) format("svg");
        font-weight: 400;
        font-style: normal;
    }

    .ant-switch-inner {
        line-height: 1.8;
    }
}