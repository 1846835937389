.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .product-thumbs-wrap .swiper-slide{
    cursor: pointer;
  }

  .zoom-container{
    position: absolute;
    width: 400px;
    height: 400px;
    right: 10%;
    z-index: 1;
    display: none;
  }