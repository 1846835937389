/*! CSS Used from: https://accounts.commercial.supplyzone.ai/static/css/main.2a791702.chunk.css */

.profile-wizard {

  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  h1,
  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #181c32;
  }

  h1 {
    font-size: calc(1.3rem + 0.6vw);
  }

  @media (min-width: 1200px) {
    h1 {
      font-size: 1.75rem;
    }
  }

  h3 {
    font-size: calc(1.26rem + 0.12vw);
  }

  @media (min-width: 1200px) {
    h3 {
      font-size: 1.35rem;
    }
  }

  ul {
    padding-left: 2rem;
  }

  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  a {
    color: #552278;
    text-decoration: none;
  }

  a:hover {
    color: #0c1d37;
    text-decoration: underline;
  }

  img,
  svg {
    vertical-align: middle;
  }

  label {
    display: inline-block;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
  }

  button,
  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button {
    text-transform: none;
  }

  [type="submit"],
  button {
    -webkit-appearance: button;
  }

  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }

  .container,
  .container-fluid,
  .container-xxl {
    width: 100%;
    padding-right: 1.5rem;
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: 1.5rem;
    padding-left: var(--bs-gutter-x, 1.5rem);
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }

  @media (min-width: 1400px) {

    .container,
    .container-xxl {
      max-width: 1320px;
    }
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
  }

  .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  @media (min-width: 992px) {
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }

  .col-form-label {
    font-weight: 500;
    color: #3f4254;
  }

  .col-form-label {
    padding-top: calc(0.75rem + 1px);
    padding-bottom: calc(0.75rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #181c32;
    background-color: #fff;
    border: 1px solid #e4e6ef;
    border-radius: 0.475rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }

  .form-control:focus {
    color: #181c32;
    background-color: #fff;
    border-color: #b5b5c3;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
      0 0 0 0.25rem rgba(26, 61, 118, 0.25);
  }

  .form-control::placeholder {
    color: #a1a5b7;
    opacity: 1;
  }

  .form-control:disabled {
    background-color: #eff2f5;
    opacity: 1;
  }

  .form-control-lg {
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 0.5rem;
    font-size: 1.15rem;
    border-radius: 0.475rem;
  }

  .btn {
    display: inline-block;
    font-weight: 500;
    line-height: 1.5;
    color: #181c32;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: initial;
    border: 1px solid transparent;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 0.475rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }

  .btn:hover {
    color: #181c32;
    text-decoration: none;
  }

  .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(26, 61, 118, 0.25);
  }

  .btn:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .btn:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(26, 61, 118, 0.25),
      inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .btn:disabled {
    pointer-events: none;
    opacity: 0.6;
    box-shadow: none;
  }

  .btn-primary {
    color: #fff;
    background-color: #552278;
    border-color: #552278;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
      0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .btn-primary:focus,
  .btn-primary:hover {
    color: #fff;
    background-color: #163464;
    border-color: #15315e;
  }

  .btn-primary:focus {
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
      0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(60, 90, 139, 0.5);
  }

  .btn-primary:active {
    color: #fff;
    background-color: #15315e;
    border-color: #142e59;
  }

  .btn-primary:active:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
      0 0 0 0.25rem rgba(60, 90, 139, 0.5);
  }

  .btn-primary:disabled {
    color: #fff;
    background-color: #552278;
    border-color: #552278;
  }

  .btn-outline-primary {
    color: #552278;
    border-color: #552278;
  }

  .btn-outline-primary:hover {
    color: #fff;
    background-color: #552278;
    border-color: #552278;
  }

  .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(26, 61, 118, 0.5);
  }

  .btn-outline-primary:active {
    color: #fff;
    background-color: #552278;
    border-color: #552278;
  }

  .btn-outline-primary:active:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
      0 0 0 0.25rem rgba(26, 61, 118, 0.5);
  }

  .btn-outline-primary:disabled {
    color: #552278;
    background-color: initial;
  }

  .btn-sm {
    padding: 0.55rem 1.25rem;
    font-size: 0.925rem;
    border-radius: 0.475rem;
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #552278;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none;
    }
  }

  .nav-link:focus,
  .nav-link:hover {
    color: #0c1d37;
    text-decoration: none;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: initial;
    border: 1px solid #eff2f5;
    border-radius: 0.475rem;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1rem;
  }

  .card-title {
    margin-bottom: 0.5rem;
  }

  .card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #eff2f5;
  }

  .card-header:first-child {
    border-radius: calc(0.475rem - 1px) calc(0.475rem - 1px) 0 0;
  }

  .card-footer {
    padding: 0.5rem 1rem;
    background-color: #fff;
    border-top: 1px solid #eff2f5;
  }

  .card-footer:last-child {
    border-radius: 0 0 calc(0.475rem - 1px) calc(0.475rem - 1px);
  }

  .breadcrumb {
    flex-wrap: wrap;
    margin-bottom: 1rem;
    list-style: none;
  }

  .breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
  }

  .breadcrumb-item+.breadcrumb-item:before {
    float: left;
    padding-right: 0.5rem;
    color: #7e8299;
    content: "/";
    content: var(--bs-breadcrumb-divider, "/");
  }

  .overflow-auto {
    overflow: auto !important;
  }

  .d-flex {
    display: flex !important;
  }

  .position-relative {
    position: relative !important;
  }

  .border-0 {
    border: 0 !important;
  }

  .border-top {
    border-top: 1px solid #eff2f5 !important;
  }

  .border-0 {
    border-width: 0 !important;
  }

  .w-5px {
    width: 5px !important;
  }

  .w-200px {
    width: 200px !important;
  }

  .h-2px {
    height: 2px !important;
  }

  .h-5px {
    height: 5px !important;
  }

  .h-55px {
    height: 25px !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .flex-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  .flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .justify-content-end {
    justify-content: flex-end !important;
  }

  .justify-content-center {
    justify-content: center !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .align-items-start {
    align-items: flex-start !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .mx-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mt-3 {
    margin-top: 0.75rem !important;
  }

  .mt-4 {
    margin-top: 1rem !important;
  }

  .mt-auto {
    margin-top: auto !important;
  }

  .me-1 {
    margin-right: 0.25rem !important;
  }

  .me-3 {
    margin-right: 0.75rem !important;
  }

  .me-5 {
    margin-right: 1.25rem !important;
  }

  .me-6 {
    margin-right: 1.5rem !important;
  }

  .me-7 {
    margin-right: 1.75rem !important;
  }

  .mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-4 {
    margin-bottom: 1rem !important;
  }

  .mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .ms-2 {
    margin-left: 0.5rem !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .p-9 {
    padding: 2.25rem 0 !important;
  }

  .px-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .pt-1 {
    padding-top: 0.25rem !important;
  }

  .pt-9 {
    padding-top: 2.25rem !important;
  }

  .pe-2 {
    padding-right: 0.5rem !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }

  .fs-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-5 {
    font-size: 1.15rem !important;
  }

  .fs-6 {
    font-size: 1.075rem !important;
  }

  .fs-7 {
    font-size: 0.95rem !important;
  }

  .fs-8 {
    font-size: 0.85rem !important;
  }

  .fw-bold {
    font-weight: 500 !important;
  }

  .fw-bolder {
    font-weight: 600 !important;
  }

  .bg-light {
    background-color: #f5f8fa !important;
  }

  .bg-success {
    background-color: #50cd89 !important;
  }

  .rounded {
    border-radius: 0.475rem !important;
  }

  @media (min-width: 576px) {
    .w-sm-300px {
      width: 300px !important;
    }

    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
  }

  @media (min-width: 992px) {
    .gap-lg-3 {
      grid-gap: 0.75rem !important;
      gap: 0.75rem !important;
    }

    .mb-lg-0 {
      margin-bottom: 0 !important;
    }

    .py-lg-6 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
  }

  @media (min-width: 1200px) {
    .mb-xl-10 {
      margin-bottom: 2.5rem !important;
    }
  }

  @media (min-width: 1200px) {
    .fs-2 {
      font-size: 1.5rem !important;
    }

    .fs-3 {
      font-size: 1.35rem !important;
    }
  }

  .bg-light {
    --bg-color: 245, 248, 250;
  }

  .bg-success {
    --bg-color: 80, 205, 137;
  }

  .border-transparent {
    border-color: transparent !important;
  }

  .flex-stack {
    justify-content: space-between;
    align-items: center;
  }

  .text-hover-primary {
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  .text-hover-primary:hover {
    transition: color 0.2s ease, background-color 0.2s ease;
    color: #552278 !important;
  }

  .text-active-primary {
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  .text-active-primary.active {
    transition: color 0.2s ease, background-color 0.2s ease;
    color: #552278 !important;
  }

  .text-dark {
    color: #181c32 !important;
  }

  .text-muted {
    color: #a1a5b7 !important;
  }

  .text-gray-400 {
    color: #b5b5c3 !important;
  }

  .text-gray-800 {
    color: #3f4254 !important;
  }

  a,
  a:hover {
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  .nav-line-tabs {
    border-bottom: 1px solid #eff2f5;
  }

  .nav-line-tabs .nav-item {
    margin-bottom: -1px;
  }

  .nav-line-tabs .nav-item .nav-link {
    color: #a1a5b7;
    border: 0;
    border-bottom: 1px solid transparent;
    transition: color 0.2s ease, background-color 0.2s ease;
    padding: 0 !important;
    margin: 0 1rem;
  }

  .nav-line-tabs .nav-item:first-child .nav-link {
    margin-left: 0;
  }

  .nav-line-tabs .nav-item:last-child .nav-link {
    margin-right: 0;
  }

  .nav-line-tabs .nav-item .nav-link.active,
  .nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
    background-color: initial;
    border: 0;
    border-bottom: 1px solid #552278;
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  .nav-line-tabs.nav-line-tabs-2x {
    border-bottom-width: 2px;
  }

  .nav-line-tabs.nav-line-tabs-2x .nav-item {
    margin-bottom: -2px;
  }

  .nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link,
  .nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link.active,
  .nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link:hover:not(.disabled) {
    border-bottom-width: 2px;
  }

  .nav-stretch,
  .nav-stretch .nav-item {
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .nav-stretch .nav-item {
    display: flex;
  }

  .nav-stretch .nav-link {
    display: flex;
    align-items: center;
  }

  .card {
    border: 0;
    box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.02);
  }

  .card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: 70px;
    padding: 0 2.25rem;
    background-color: initial;
  }

  .card .card-header .card-title {
    display: flex;
    align-items: center;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }

  .card .card-header .card-title {
    font-weight: 500;
    font-size: 1.275rem;
    color: #181c32;
  }

  .card .card-header .card-title h3 {
    margin-bottom: 0;
  }

  .card .card-footer {
    background-color: initial;
  }

  @media (max-width: 767.98px) {
    .card>.card-header:not(.flex-nowrap) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .breadcrumb {
    display: flex;
    align-items: center;
    background-color: initial;
    padding: 0;
    margin: 0;
  }

  .breadcrumb .breadcrumb-item {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 0.5rem;
  }

  .breadcrumb .breadcrumb-item:last-child {
    padding-right: 0;
  }

  .breadcrumb .breadcrumb-item:after {
    content: "/";
    padding-left: 0.5rem;
  }

  .breadcrumb .breadcrumb-item:before,
  .breadcrumb .breadcrumb-item:last-child:after {
    display: none;
  }

  .breadcrumb-separatorless .breadcrumb-item:after {
    display: none;
  }

  .btn {
    outline: none !important;
  }

  .btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
    box-shadow: none !important;
  }

  .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(0.75rem + 1px) calc(1.5rem + 1px);
  }

  .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-sm {
    padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
  }

  .btn.btn-outline-dashed {
    border: 1px dashed #e4e6ef;
  }

  .btn.btn-primary {
    color: #fff;
    border-color: #552278;
    background-color: #552278;
  }

  .btn.btn-primary:active:not(.btn-active),
  .btn.btn-primary:focus:not(.btn-active),
  .btn.btn-primary:hover:not(.btn-active) {
    color: #fff;
    border-color: #194c9e;
    background-color: #194c9e !important;
  }

  .btn.btn-active-light-primary:active:not(.btn-active),
  .btn.btn-active-light-primary:focus:not(.btn-active),
  .btn.btn-active-light-primary:hover:not(.btn-active) {
    color: #552278;
    border-color: #f1faff;
    background-color: #f1faff !important;
  }

  .btn.btn-active-light-primary.btn-outline:not(.btn-outline-default) {
    border-color: #552278 !important;
  }

  .btn.btn-light-success {
    color: #50cd89;
    border-color: #e8fff3;
    background-color: #e8fff3;
  }

  .btn.btn-light-success:active:not(.btn-active),
  .btn.btn-light-success:focus:not(.btn-active),
  .btn.btn-light-success:hover:not(.btn-active) {
    color: #fff;
    border-color: #50cd89;
    background-color: #50cd89 !important;
  }

  .btn.btn-outline.btn-outline-dashed.btn-outline-default {
    color: #7e8299;
    border: 1px dashed #e4e6ef;
  }

  .btn.btn-outline.btn-outline-dashed.btn-outline-default:active:not(.btn-active),
  .btn.btn-outline.btn-outline-dashed.btn-outline-default:focus:not(.btn-active),
  .btn.btn-outline.btn-outline-dashed.btn-outline-default:hover:not(.btn-active) {
    color: #552278;
    border-color: #552278;
    background-color: #f1faff !important;
  }

  .form-control {
    box-shadow: none !important;
  }

  .form-control.form-control-solid {
    border-color: #f5f8fa;
    color: #5e6278;
  }

  .form-control.form-control-solid::placeholder {
    color: #a1a5b7;
  }

  .form-control.form-control-solid:active,
  .form-control.form-control-solid:focus {
    background-color: #eef3f7;
    border-color: #eef3f7;
    color: #5e6278;
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  .required:after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: #f1416c;
    padding-left: 0.25rem;
    font-weight: 700;
  }

  .image-input {
    position: relative;
    display: inline-block;
    border-radius: 0.475rem;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .image-input:not(.image-input-empty) {
    background-image: none !important;
  }

  .image-input .image-input-wrapper {
    width: 120px;
    height: 120px;
    border-radius: 0.475rem;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .image-input.image-input-outline .image-input-wrapper {
    border: 3px solid #fff;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  }

  .symbol {
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.475rem;
  }

  .bullet {
    display: inline-block;
    background-color: #b5b5c3;
    border-radius: 6px;
    width: 8px;
    height: 4px;
    flex-shrink: 0;
  }

  @media (min-width: 992px) {

    div,
    span,
    ul {
      scrollbar-width: thin;
      scrollbar-color: #eff2f5 transparent;
    }

    div::-webkit-scrollbar,
    span::-webkit-scrollbar,
    ul::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
    }

    div::-webkit-scrollbar-thumb,
    span::-webkit-scrollbar-thumb,
    ul::-webkit-scrollbar-thumb {
      background-color: #eff2f5;
    }

    div:hover,
    span:hover,
    ul:hover {
      scrollbar-color: #e9edf1 transparent;
    }

    div:hover::-webkit-scrollbar-thumb,
    span:hover::-webkit-scrollbar-thumb,
    ul:hover::-webkit-scrollbar-thumb {
      background-color: #e9edf1;
    }
  }

  body a:active,
  body a:focus,
  body a:hover,
  html a:active,
  html a:focus,
  html a:hover {
    text-decoration: none !important;
  }

  canvas {
    -webkit-user-select: none;
    user-select: none;
  }

  @media (min-width: 992px) {

    .container,
    .container-fluid,
    .container-xxl {
      padding: 0 30px;
    }
  }

  @media (max-width: 991.98px) {

    .container,
    .container-fluid,
    .container-xxl {
      max-width: none;
      padding: 0 15px;
    }
  }

  .toolbar {
    background-color: #fff;
  }

  @media (min-width: 992px) {
    .toolbar-fixed .toolbar {
      height: var(--kt-toolbar-height);
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 99;
      box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.05);
    }

    .header-fixed.toolbar-fixed .toolbar {
      padding: 0;
      top: 65px;
      border-top: 1px solid #eff2f5;
    }

    .aside-enabled.aside-fixed.toolbar-fixed .toolbar {
      left: 265px;
      transition: left 0.3s ease;
    }

    .aside-enabled.aside-fixed.toolbar-fixed[data-kt-aside-minimize="on"] .toolbar {
      left: 75px;
      transition: left 0.3s ease;
    }
  }

  @media (max-width: 991.98px) {
    .toolbar-tablet-and-mobile-fixed .toolbar {
      height: var(--kt-toolbar-height-tablet-and-mobile);
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 99;
      box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.05);
    }

    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .toolbar {
      padding: 0;
      top: 55px;
      border-top: 1px solid #eff2f5;
    }
  }

  @media (min-width: 992px) {

    .container,
    .container-fluid,
    .container-xxl {
      padding: 0 30px;
    }
  }

  @media (max-width: 991.98px) {

    .container,
    .container-fluid,
    .container-xxl {
      max-width: none;
      padding: 0 15px;
    }
  }

  .form-control.form-control-solid {
    border-color: #552278;
  }

  .btn-sm {
    padding: 0.35rem 1.25rem !important;
    font-size: 1rem;
    border-radius: 0.275rem;
  }

  .form-control-lg {
    border-radius: 0.3rem;
  }

  label.file-uploader {
    display: flex !important;
    justify-content: center;
  }

  label.file-uploader>label>div>span:nth-child(2) {
    visibility: hidden;
    display: none;
  }

  .form-control.form-control-solid {
    background-color: #f5f8fa;
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  #kt_content_container {
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
    max-width: inherit !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .w-100 {
    width: 100% !important;
  }

  .full-width-justify {
    display: flex !important;
    justify-content: space-between !important;
    align-content: center !important;
    width: 100% !important;
  }

  .btn.btn-active-light-primary:hover:not(.btn-active) {
    background-color: #32a9f4 !important;
  }

  @media (min-width: 992px) {
    .header-fixed.toolbar-fixed .toolbar {
      padding: 0;
      top: 0;
      border-top: 1px solid #eff2f5;
    }
  }

  #kt_app_toolbar {
    margin-right: 40px;
  }

  .p-0 {
    padding: 0;
  }

  .form-control.form-control-solid {
    background-color: #fff;
    border-color: #cac9ca;
    color: #333;
  }

  label.file-uploader {
    border: none !important;
  }

  .btn.btn-outline.btn-outline-dashed.btn-outline-default {
    color: #7e8299 !important;
    border: 1px dashed #e4e6ef !important;
  }

  .toolbar {
    display: flex;
    align-items: center;
    background-color: #000;
    padding: 0.5rem;
    color: #fff !important;
    border: none !important;
    left: 0 !important;
    height: 70px !important;
    transition: left 0.3s ease;
  }

  @media (min-width: 641px) {
    h1.page-heading {
      margin-left: 193px;
    }

    #kt_app_toolbar_container {
      margin-left: 0;
      border: none;
      max-width: calc(100vw - 90px) !important;
    }

    h1.page-heading {
      color: #000 !important;
      margin-left: 0 !important;
      font-size: 18px !important;
      font-weight: 600 !important;
    }

    #kt_toolbar {
      display: none !important;
    }
  }

  @media (max-width: 991.98px) {
    #kt_app_toolbar_container {
      margin-left: 75px;
    }

    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .toolbar {
      top: 0 !important;
    }

    #kt_app_toolbar {
      margin-top: 132px;
      color: #fff;
      border: none;
      width: 100% important;
      display: block;
      background-color: #3f3f3f;
      max-width: 100vw !important;
      margin-right: 0;
      display: none;
    }

    .btn.btn-active-light-primary:hover:not(.btn-active) {
      background-color: rgba(0, 160, 255, 0.14901960784313725) !important;
    }

    #kt_app_toolbar_container {
      margin-left: -15px;
      border: none;
      margin-top: -45px;
      position: absolute;
      width: 100vw;
      background-color: #fff;
    }
  }

  .card {
    box-shadow: 0.1rem 0.1rem 0.5rem 0.25rem rgba(172, 173, 180, 0.5) !important;
    border-radius: 10px !important;
  }

  .form-control.form-control-solid {
    border: 2px solid #dadce3 !important;
    background-color: #fcfcfc !important;
  }

  .form-control.form-control-solid:active,
  .form-control.form-control-solid:focus {
    background-color: #fcfcfc !important;
    border: 2px solid #2178ff !important;
    color: #5e6278 !important;
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  /*! CSS Used from: Embedded */
  .dtlKip {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-width: 322px;
    max-width: 508px;
    height: 48px;
    border: 2px dashed rgb(6, 88, 194);
    padding: 8px 16px 8px 8px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-flex: 0;
    flex-grow: 0;
  }

  .dtlKip>input {
    display: block;
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .gwDiyc {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }

  .gwDiyc>span {
    font-size: 12px;
    color: rgb(102, 102, 102);
  }

  .gwDiyc .file-types {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
  }

  .cKeOpf {
    font-size: 14px;
    color: rgb(102, 102, 102);
  }

  .cKeOpf span {
    text-decoration: underline;
  }

  /*! CSS Used from: Embedded */
  .StepperContainer-0-2-1 {
    display: flex;
    padding: 24px;
    align-items: flex-start;
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: row;
  }

  .StepContainer-0-2-2 {
    flex: 1;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
  }

  div#RFS-StepMain button {
    font-size: 2rem !important;
    border: 3px solid #b4b4b4 !important;
  }

  div#RFS-StepMain button.active {
    background-color: #fff !important;
    border: 3px solid #552278 !important;
    font-size: 2rem !important;
  }

  div#RFS-StepMain button span {
    font-weight: 600 !important;
    font-size: 24px !important;
    color: #b4b4b4 !important;
  }

  div#RFS-StepMain button.completed {
    border: 3px solid #552278 !important;
  }

  div#RFS-StepMain button.completed span {
    font-weight: 600 !important;
    font-size: 24px !important;
    color: #552278 !important;
  }

  div#RFS-StepMain button.active span {
    color: #552278 !important;
  }

  #RFS-ConnectorContainer,
  .ConnectorContainer-d0-0-2-16 {
    top: calc(2em - 1px) !important;
    z-index: -1 !important;
  }

  #RFS-Connector {
    display: block;
    border-top: 1px solid;
    border-color: #552278;
  }

  [class^="StepButton-"].completed {
    background-color: rgb(26 61 118) !important;
    border: 3px solid #552278 !important;
    color: #ffffff !important;
  }

  [class^="StepButton-"].completed>span {
    color: #ffffff !important;
  }

  #RFS-ConnectorContainer,
  .ConnectorContainer-d0-0-2-16 {
    top: calc(2em - 1px) !important;
    z-index: 1 !important;
    height: 2px;
    border: 1px solid #552278 !important;
    opacity: 1;
    display: block;
    left: calc((-50% + 2em) - 1px);
    right: calc((50% + 2em) - 1px);
  }

  .d-flex.justify-content-end.py-6.px-9 {
    padding-right: 0 !important;
  }


  .table {
    --bs-table-color-type: initial;
    --bs-table-bg-type: initial;
    --bs-table-color-state: initial;
    --bs-table-bg-state: initial;
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: var(--bs-body-bg);
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
    border-color: var(--bs-table-border-color);
  }

  .table>:not(caption)>*>* {
    padding: .5rem .5rem;
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
  }

  .table>tbody {
    vertical-align: inherit;
  }

  .table>thead {
    vertical-align: bottom;
  }

  .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: var(--bs-table-striped-bg);
  }

  .table-hover>tbody>tr:hover>* {
    --bs-table-color-state: var(--bs-table-hover-color);
    --bs-table-bg-state: var(--bs-table-hover-bg);
  }

  .ant-col-8 {
    margin-bottom: 24px;

  }

  .card-footer.d-flex.justify-content-end.py-6.px-9 {
    padding-bottom: 0 !important;
  }

}

.profile-wizard .row {
  margin-top: 5px;
  margin-bottom: 5px;
}

.profile-wizard .text-muted {
  color: #000000 !important;
  font-weight: 400 !important;
}

.security {

  /*! CSS Used from: https://preview.keenthemes.com/metronic8/demo1/assets/plugins/global/plugins.bundle.css */
  .fv-plugins-icon-container {
    position: relative;
  }

  .fv-plugins-message-container--enabled {
    display: block;
  }

  .ki-duotone {
    font-family: keenicons-duotone !important;
    speak: never;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: relative;
    display: inline-flex;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ki-shield-tick .path1:before {
    content: "\ee59";
    opacity: .3;
  }

  .ki-shield-tick .path2:before {
    content: "\ee5a";
    position: absolute;
    left: 0;
  }

  .fv-plugins-message-container {
    --input-invalid-color: var(--bs-danger);
    margin-top: .3rem;
  }

  .fv-plugins-message-container.invalid-feedback {
    display: block;
    font-weight: 400;
  }

  .ki-duotone {
    line-height: 1;
    font-size: 1rem;
    color: var(--bs-text-muted);
  }

  /*! CSS Used from: https://preview.keenthemes.com/metronic8/demo1/assets/css/style.bundle.css */
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  h3,
  h4 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 600;
    line-height: 1.2;
    color: var(--bs-heading-color);
  }

  h3 {
    font-size: calc(1.26rem + .12vw);
  }

  @media (min-width:1200px) {
    h3 {
      font-size: 1.35rem;
    }
  }

  h4 {
    font-size: 1.25rem;
  }

  a {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    text-decoration: none;
  }

  a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
    text-decoration: none;
  }

  label {
    display: inline-block;
  }

  button {
    border-radius: 0;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
  }

  button,
  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button {
    text-transform: none;
  }

  [role=button] {
    cursor: pointer;
  }

  [type=button],
  button {
    -webkit-appearance: button;
  }

  [type=button]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
  }

  .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }

  @media (min-width:992px) {
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }
  }

  .form-label {
    margin-bottom: .5rem;
    font-size: 1.05rem;
    font-weight: 500;
    color: var(--bs-gray-800);
  }

  .form-text {
    margin-top: .5rem;
    font-size: .95rem;
    color: var(--bs-text-muted);
  }

  .form-control {
    display: block;
    width: 100%;
    padding: .775rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--bs-gray-700);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: 1px solid var(--bs-gray-300);
    border-radius: .475rem;
    box-shadow: false;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  @media (prefers-reduced-motion:reduce) {
    .form-control {
      transition: none;
    }
  }

  .form-control:focus {
    color: var(--bs-gray-700);
    background-color: var(--bs-body-bg);
    border-color: var(--bs-gray-400);
    outline: 0;
    box-shadow: false, 0 0 0 .25rem rgba(62, 151, 255, .25);
  }

  .form-control::placeholder {
    color: var(--bs-gray-500);
    opacity: 1;
  }

  .form-control:disabled {
    color: var(--bs-gray-500);
    background-color: var(--bs-gray-200);
    border-color: var(--bs-gray-300);
    opacity: 1;
  }

  .form-control-lg {
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: .825rem 1.5rem;
    font-size: 1.15rem;
    border-radius: .625rem;
  }

  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .5rem;
    font-size: .95rem;
    color: var(--bs-form-invalid-color);
  }

  .btn {
    --bs-btn-padding-x: 1.5rem;
    --bs-btn-padding-y: 0.775rem;
    --bs-btn-font-size: 1.1rem;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.475rem;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    box-shadow: var(--bs-btn-box-shadow);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  @media (prefers-reduced-motion:reduce) {
    .btn {
      transition: none;
    }
  }

  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }

  .btn:first-child:active,
  :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    box-shadow: var(--bs-btn-active-shadow);
  }

  .btn:disabled {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
    box-shadow: none;
  }

  .btn-light {
    --bs-btn-color: #000000;
    --bs-btn-bg: #F9F9F9;
    --bs-btn-border-color: #F9F9F9;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #d4d4d4;
    --bs-btn-hover-border-color: #c7c7c7;
    --bs-btn-focus-shadow-rgb: 212, 212, 212;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #c7c7c7;
    --bs-btn-active-border-color: #bbbbbb;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #F9F9F9;
    --bs-btn-disabled-border-color: #F9F9F9;
  }

  .btn-primary {
    --bs-btn-color: #000000;
    --bs-btn-bg: #3E97FF;
    --bs-btn-border-color: #3E97FF;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #5ba7ff;
    --bs-btn-hover-border-color: #51a1ff;
    --bs-btn-focus-shadow-rgb: 53, 128, 217;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #65acff;
    --bs-btn-active-border-color: #51a1ff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #3E97FF;
    --bs-btn-disabled-border-color: #3E97FF;
  }

  .card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color);
    --bs-card-border-radius: 0.625rem;
    --bs-card-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
    --bs-card-inner-border-radius: calc(0.625rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: transparent;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    color: var(--bs-body-color);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    box-shadow: var(--bs-card-box-shadow);
  }

  .card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
  }

  .card-title {
    margin-bottom: var(--bs-card-title-spacer-y);
    color: var(--bs-card-title-color);
  }

  .card-header {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    margin-bottom: 0;
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  }

  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
  }

  .d-flex {
    display: flex !important;
  }

  .d-none {
    display: none !important;
  }

  .border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }

  .border-0 {
    border: 0 !important;
  }

  .border-top {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }

  .border-primary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
  }

  .border-0 {
    border-width: 0 !important;
  }

  .flex-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  .align-self-center {
    align-self: center !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .me-2 {
    margin-right: .5rem !important;
  }

  .me-4 {
    margin-right: 1rem !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-1 {
    margin-bottom: .25rem !important;
  }

  .mb-3 {
    margin-bottom: .75rem !important;
  }

  .mb-4 {
    margin-bottom: 1rem !important;
  }

  .mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .ms-auto {
    margin-left: auto !important;
  }

  .p-6 {
    padding: 1.5rem !important;
  }

  .p-9 {
    padding: 2.25rem !important;
  }

  .px-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .pe-7 {
    padding-right: 1.75rem !important;
  }

  .fs-6 {
    font-size: 1.075rem !important;
  }

  .fs-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fw-semibold {
    font-weight: 500 !important;
  }

  .fw-bold {
    font-weight: 600 !important;
  }

  .text-nowrap {
    white-space: nowrap !important;
  }

  .text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }

  .rounded {
    border-radius: .475rem !important;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  @media (min-width:768px) {
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }

    .mb-md-0 {
      margin-bottom: 0 !important;
    }
  }

  @media (min-width:992px) {
    .mb-lg-0 {
      margin-bottom: 0 !important;
    }
  }

  @media (min-width:1200px) {
    .mb-xl-10 {
      margin-bottom: 2.5rem !important;
    }
  }

  @media (min-width:1200px) {
    .fs-2tx {
      font-size: 2.75rem !important;
    }
  }

  h3,
  h4 {
    outline: 0;
  }

  .separator {
    display: block;
    height: 0;
    border-bottom: 1px solid var(--bs-border-color);
  }

  .separator.separator-dashed {
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-dashed-color);
  }

  .card {
    border: 1px solid var(--bs-card-border-color);
  }

  .card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: 70px;
    padding: 0 2.25rem;
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
    border-bottom: 1px solid var(--bs-card-border-color);
  }

  .card .card-header .card-title {
    display: flex;
    align-items: center;
    margin: .5rem;
    margin-left: 0;
  }

  .card .card-header .card-title {
    font-weight: 500;
    font-size: 1.275rem;
    color: var(--bs-text-dark);
  }

  .card .card-header .card-title h3 {
    margin-bottom: 0;
  }

  .card .card-body {
    padding: 2rem 2.25rem;
    color: var(--bs-card-color);
  }

  @media (max-width:767.98px) {
    .card>.card-header:not(.flex-nowrap) {
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
  }

  .btn {
    --bs-btn-color: var(--bs-body-color);
    outline: 0 !important;
  }

  .btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg):not(.shadow-xs) {
    box-shadow: none;
  }

  .btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(.775rem + 1px) calc(1.5rem + 1px);
  }

  .btn.btn-light {
    color: var(--bs-light-inverse);
    border-color: var(--bs-light);
    background-color: var(--bs-light);
  }

  .btn.btn-light:active:not(.btn-active),
  .btn.btn-light:focus:not(.btn-active),
  .btn.btn-light:hover:not(.btn-active) {
    color: var(--bs-light-inverse);
    border-color: var(--bs-light-active);
    background-color: var(--bs-light-active) !important;
  }

  .btn.btn-primary {
    color: var(--bs-primary-inverse);
    border-color: var(--bs-primary);
    background-color: var(--bs-primary);
  }

  .btn.btn-primary:active:not(.btn-active),
  .btn.btn-primary:focus:not(.btn-active),
  .btn.btn-primary:hover:not(.btn-active) {
    color: var(--bs-primary-inverse);
    border-color: var(--bs-primary-active);
    background-color: var(--bs-primary-active) !important;
  }

  .btn.btn-active-light-primary:active:not(.btn-active),
  .btn.btn-active-light-primary:focus:not(.btn-active),
  .btn.btn-active-light-primary:hover:not(.btn-active) {
    color: var(--bs-primary);
    border-color: var(--bs-primary-light);
    background-color: var(--bs-primary-light) !important;
  }

  .btn.btn-color-gray-400 {
    color: var(--bs-text-gray-400);
  }

  .form-control.form-control-solid {
    background-color: var(--bs-gray-100);
    border-color: var(--bs-gray-100);
    color: var(--bs-gray-700);
    transition: color .2s ease;
  }

  .form-control.form-control-solid::placeholder {
    color: var(--bs-gray-500);
  }

  .form-control.form-control-solid:active,
  .form-control.form-control-solid:focus {
    background-color: var(--bs-gray-200);
    border-color: var(--bs-gray-200);
    color: var(--bs-gray-700);
    transition: color .2s ease;
  }

  @media (min-width:992px) {

    div,
    span {
      scrollbar-width: thin;
      scrollbar-color: var(--bs-scrollbar-color) transparent;
    }

    div::-webkit-scrollbar,
    span::-webkit-scrollbar {
      width: var(--bs-scrollbar-size);
      height: var(--bs-scrollbar-size);
    }

    div ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    div ::-webkit-scrollbar-thumb {
      border-radius: var(--bs-scrollbar-size);
    }

    div::-webkit-scrollbar-thumb,
    span::-webkit-scrollbar-thumb {
      background-color: var(--bs-scrollbar-color);
    }

    div::-webkit-scrollbar-corner,
    span::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    div:hover,
    span:hover {
      scrollbar-color: var(--bs-scrollbar-hover-color) transparent;
    }

    div:hover::-webkit-scrollbar-thumb,
    span:hover::-webkit-scrollbar-thumb {
      background-color: var(--bs-scrollbar-hover-color);
    }

    div:hover::-webkit-scrollbar-corner,
    span:hover::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  .bg-light-primary {
    background-color: var(--bs-primary-light) !important;
  }

  .border-dashed {
    border-style: dashed !important;
    border-color: var(--bs-border-dashed-color);
  }

  .flex-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-stack {
    justify-content: space-between;
    align-items: center;
  }

  .text-primary {
    color: var(--bs-text-primary) !important;
  }

  .text-gray-600 {
    color: var(--bs-text-gray-600) !important;
  }

  .text-gray-700 {
    color: var(--bs-text-gray-700) !important;
  }

  .text-gray-900 {
    color: var(--bs-text-gray-900) !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  a {
    transition: color .2s ease;
  }

  a:hover {
    transition: color .2s ease;
  }

  body a:active,
  body a:focus,
  body a:hover {
    text-decoration: none !important;
  }

  /*! CSS Used fontfaces */
  @font-face {
    font-family: keenicons-duotone;
    src: url(https://preview.keenthemes.com/metronic8/demo1/assets/plugins/global/fonts/keenicons/keenicons-duotone.eot?eut7fk);
    src: url(https://preview.keenthemes.com/metronic8/demo1/assets/plugins/global/fonts/keenicons/keenicons-duotone.eot?eut7fk#iefix) format("embedded-opentype"), url(https://preview.keenthemes.com/metronic8/demo1/assets/plugins/global/fonts/keenicons/keenicons-duotone.ttf?eut7fk) format("truetype"), url(https://preview.keenthemes.com/metronic8/demo1/assets/plugins/global/fonts/keenicons/keenicons-duotone.woff?eut7fk) format("woff"), url(https://preview.keenthemes.com/metronic8/demo1/assets/plugins/global/fonts/keenicons/keenicons-duotone.svg?eut7fk#keenicons-duotone) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
}

.security .border-dashed {
  border-style: dashed !important;
  border-color: #3F51B5 !important;
  border-width: 1px !important;
  background-color: #f5f6ff !important;
}

.security .btn.btn-primary {
  border: 1px solid #552278;
  text-transform: capitalize;
  color: #ffffff !important;
  background-color: #552278;
  border-radius: 7px !important;
}

.security .btn.btn-primary:hover {
  border: 1px solid #552278 !important;
  color: #552278 !important;
  background-color: #552278 !important;
  border-radius: 7px !important;
}

.security .btn.btn-outline {
  border: 1px solid #552278;
  text-transform: capitalize;
  color: #552278;
  border-radius: 7px !important;
}

.notice.d-flex.bg-light-warning {
  border: 1px dashed orange;
  padding: 10px 20px;
  background-color: #ffc10714;

  h4.text-gray-800 {
    color: #3f4254 !important;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 700 !important;
  }
}

.form-check {

  /*! CSS Used from: https://preview.keenthemes.com/metronic8/demo1/assets/css/style.bundle.css */
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .form-check .form-check-input {
    float: left;
    margin-left: -2.25rem;
  }

  .form-check-input {
    --bs-form-check-bg: transparent;
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -.125rem;
    vertical-align: top;
    appearance: none;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid var(--bs-gray-300);
    print-color-adjust: exact;
  }

  .form-check-input[type=checkbox] {
    background-color: #eff2f5;
    width: 25px;
    height: 25px;
    padding: 3px !important;
    margin-right: 25px;
    border-radius: .45em;
  }

  .form-check-input:checked {
    background-color: #552278;
    border-color: #552278;
  }

  .form-check-input:active {
    filter: brightness(90%);
  }

  .form-check-input:focus {
    border-color: var(--bs-gray-400);
    outline: 0;
    box-shadow: none;
  }

  .form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'%3e%3cpath d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='%23ffffff'/%3e%3c/svg%3e");
  }

  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5;
  }

  .form-check:not(.form-switch) .form-check-input[type=checkbox] {
    background-size: 60% 60%;
  }

  .form-check.form-check-solid .form-check-input {
    border: 0;
  }

  .form-check.form-check-solid .form-check-input:not(:checked) {
    background-color: var(--bs-gray-200);
  }

  @media (min-width:992px) {
    div ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    div ::-webkit-scrollbar-thumb {
      border-radius: var(--bs-scrollbar-size);
    }
  }

  .fv-help-block {
    margin-top: 13px;
  }

}

.ant-btn-primary {
  color: #fff;
  background-color: #552278;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.ant-btn-default {
  background-color: #ffffff;
  border-color: #552278;
  color: #552278;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}


.jWkLDY>span {
  font-size: 12px;
  color: rgb(102, 102, 102);
  text-transform: initial;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.guZdik {
  min-width: 280px !important;
  max-width: 508px !important;
  height: 72px !important;
  width: 100%;
}


.btn svg {
  width: 35px !important;
  height: 35px !important;
  stroke-width: 1px;
}
.submit-rfq-btn svg {
  width: 20px !important;
  height: 20px !important;
  stroke-width: 1px;
}
.ant-upload-wrapper.doc-card {
  width: 100%;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  margin: 0;
}

.ant-upload-wrapper.doc-card .ant-upload-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}

.ant-upload-wrapper.doc-card .ant-upload-list::before,
.ant-upload-wrapper.doc-card .ant-upload-list::after {
  display: none;
  content: "";
}

.ant-upload-wrapper.doc-card .ant-upload-list .ant-upload-list-item {
  margin-top: 0px;
}


.wide-uploader .ant-upload-list-item-container {
  width: 100% !important;
}

.ant-upload-list-item-image {
  object-fit: cover !important;
}

.upload-image-wrapper {
  position: relative;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  display: table;
  max-width: 102px !important;
  width: 100%;
  margin-bottom: 10px;
}

.upload-image-wrapper.banner {
  max-width: unset !important;
}


#kt_account_profile_details>form {
  padding: 0 30px !important;
  padding-top: 0 !important;
}

.card .card-body,
.card .card-footer {
  padding: 2rem 2.25rem;
}

form>.card .card-body,
form>.card .card-footer {
  padding: 2rem 0rem !important;
}


.card>.collapse.show {
  padding: 30px;
  padding-top: 0px !important;
}

tr>th {
  text-align: left !important;
}

td>input,
td>select {
  margin-bottom: 0 !important;
}

.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 132px;
  height: 132px;
}

.file-attachment-item {
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 8px;
  min-width: 250px;
  max-width: 250px;
}

.ant-upload-list.ant-upload-list-picture {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;


  .ant-upload-list-item-image {
    max-width: 48px;
  }

  .ant-upload-list-item-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 8px;
    line-height: 1.5714285714285714;
    flex: auto;
    transition: all 0.3s;
    width: 100%;
    display: block;
  }

  .ant-upload-list-item-actions {
    display: none;
  }

}

.business-type-radio {
  background-color: #fff;
  border: solid 2px #552278;
  border-radius: 50%;

  width: 1.75rem;
  height: 1.75rem;
  margin-top: -0.125rem;
  vertical-align: top;
  appearance: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  cursor: pointer;
}

.business-type-radio:checked {
  background-color: #552278;
  border: solid 2px #552278;
}

.ant-radio-inner {
  border-color: #552278 !important;
  width: 1.75rem !important;
  height: 1.75rem !important;
  border-width: 2px !important;
}
.ant-radio-checked .ant-radio-inner{
  background-color: #552278 !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after{
  background-color: #552278 !important;
}
