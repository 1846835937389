.imessage {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.5rem;
    gap: 16px
}

.imessage article {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 80%;
    padding: 0.5rem .875rem;
    position: relative;
    word-wrap: break-word;
}

.imessage article::before,
.imessage article::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
}

article.from-me {
    align-self: flex-end;
    background-color: #353387;
    color: #fff;
    box-shadow: 2px 2px 10px #00000057;
}

article.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #353387;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
}

article.from-me::after {
    background: rgb(195, 195, 195);
    background: linear-gradient(90deg, rgba(195, 195, 195, 1) 0%, rgba(222, 222, 222, 1) 47%, rgba(233, 233, 233, 1) 100%);
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform: translate(-30px, -2px);
    width: 10px;
}

article[class^="from-"] {
    margin: 0.5rem 0;
    width: fit-content;
    width: -moz-fit-content;
}

article.from-me~article.from-me {
    margin: 0.25rem 0 0;
}

article.from-me~article.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
}

article.from-me~article.from-me:last-child {
    margin-bottom: 0.5rem;
}

article.from-them {
    align-items: flex-start;
    background-color: #ffffff;
    color: #000;
    box-shadow: 3px 3px 10px #0000001f;
}

article.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #ffffff;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
}

article.from-them::after {
    background-color: #eeeeee;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
}

article[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
}

article[class^="from-"].emoji::before {
    content: none;
}

.no-tail::before {
    display: none;
}

.margin-b_none {
    margin-bottom: 0 !important;
}

.margin-b_one {
    margin-bottom: 1rem !important;
}

.margin-t_one {
    margin-top: 1rem !important;
}


.sai-bubble-wrapper {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;

    &.in {

        justify-content: flex-start;
    }

    &.out {

        justify-content: flex-end;
    }
}



article .table-container {
    width: 100%;
    overflow-x: auto;
    border-radius: 8px;
}

article .grid-table {
    margin-top:15px;
    width: 100%;
    border-collapse: collapse;
}

article .grid-table th,
article .grid-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

article .grid-table th {
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1;
}




.sai-chat-wrapper {
    height: "100vh";
    width: "100vw";
    min-width: 320px;
    border: 1px solid transparent
}

.sai-chat-wrapper .sai-chat-header {
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    background-color: #fff
}

.sai-chat-wrapper .sai-chat-header button:hover {
    border-color: transparent;
    outline: none
}

.sai-chat-wrapper .sai-chat-header button:focus {
    border-color: transparent;
    outline: none
}

.sai-chat-wrapper .sai-chat-header .sai-avatar {
    background: #292dcb;
    background: radial-gradient(circle, #00d4ff, #4848ec 35%, #020024);
    background-image: url(https://main.d2qfu33soz24kr.amplifyapp.com/aiwaves.gif);
    color: #fff;
    width: 43px;
    height: 43px;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    background-size: 120px;
    background-position: center
}

.sai-chat-wrapper .sai-chat-header .sai-logo-text {
    display: block;
    margin-top: 6px;
    margin-left: 10px;
    color: #333;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    background-color: transparent
}

.sai-chat-wrapper .sai-chat-body {
    width: 100%;
    height: 100vh;
    background-color: #eee;
    flex-basis: auto !important
}

.sai-chat-wrapper .sai-chat-body .sai-disclaimer {
    position: fixed;
    bottom: 12px;
    display: block;
    width: 730px;
    font-size: 10px;
    font-weight: 400;
    text-align: center
}

.sai-chat-wrapper .sai-chat-body .scroller {
    position: fixed;
    top: 64px;
    display: block;
    width: 735px;
    height: auto;
    max-height: calc(100vh - 164px);
    border: 1px solid transparent;
    overflow-x: hidden;
    overflow-y: scroll
}

.sai-chat-wrapper .sai-chat-body .scroller .sai-avatar-grey {
    background: #646464;
    color: #fff;
    min-width: 36px;
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: row
}

.sai-chat-wrapper .sai-chat-body .sai-prompt-wrapper {
    position: fixed;
    bottom: 32px;
    margin: 8px;
    width: 717px;
    height: 52px;
    border-radius: 25px
}

.sai-chat-wrapper .sai-chat-body .sai-prompt-wrapper .ant-card-body {
    padding: 3px 0
}

.sai-chat-wrapper .sai-chat-body .sai-prompt-wrapper .ant-card-body .sai-input {
    width: 100%;
    min-height: 40px;
    max-height: 150px;
    resize: none;
    padding: 12px 0;
    box-sizing: border-box;
    height: -webkit-fill-available;
    background-color: transparent;
    border: transparent;
    outline: transparent;
    color: #000;
    font-size: 15px;
    font-family: inherit
}

.sai-chat-wrapper .sai-chat-body .sai-prompt-wrapper .ant-card-body button:hover {
    border-color: transparent;
    outline: none
}

.sai-chat-wrapper .sai-chat-body .sai-prompt-wrapper .ant-card-body button:focus {
    border-color: transparent;
    outline: none
}

.mb-3 {
    margin-bottom: 12px
}

.imessage {
    display: flex;
    flex-direction: column;
    padding: .5rem 1.5rem;
    gap: 16px
}

.imessage article {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 80%;
    padding: .5rem .875rem;
    position: relative;
    word-wrap: break-word
}

.imessage article:before,
.imessage article:after {
    bottom: -.1rem;
    content: "";
    height: 1rem;
    position: absolute
}

article.from-me {
    align-self: flex-end;
    background-color: #353387;
    color: #fff;
    box-shadow: 3px 3px 10px #0000001f;
    
}

article.from-me h1,
article.from-me h2,
article.from-me h3,
article.from-me h4 {
    color: #fff !important;
}

article.from-me:before {
    border-bottom-left-radius: .8rem .7rem;
    border-right: 1rem solid #353387;
    right: -.35rem;
    transform: translateY(-.1rem)
}

article.from-me:after {
    background: #353387;
    background: linear-gradient(90deg, #c3c3c3, #dedede 47%, #e9e9e9);
    border-bottom-left-radius: .5rem;
    right: -40px;
    transform: translate(-30px, -2px);
    width: 10px
}

article[class^=from-] {
    margin: .5rem 0;
    width: fit-content;
    width: -moz-fit-content
}

article.from-me~article.from-me {
    margin: .25rem 0 0
}

article.from-me~article.from-me:not(:last-child) {
    margin: .25rem 0 0
}

article.from-me~article.from-me:last-child {
    margin-bottom: .5rem
}

article.from-them {
    align-items: flex-start;
    background-color: #fff;
    color: #000;
    box-shadow: 2px 2px 10px #00000057;
    
}

article.from-them:before {
    border-bottom-right-radius: .8rem .7rem;
    border-left: 1rem solid #ffffff ;
    left: -.35rem;
    transform: translateY(-.1rem)
}

article.from-them:after {
    background-color: #eee;
    border-bottom-right-radius: .5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px
}

article[class^=from-].emoji {
    background: none;
    font-size: 2.5rem
}

article[class^=from-].emoji:before {
    content: none
}

.no-tail:before {
    display: none
}

.margin-b_none {
    margin-bottom: 0 !important
}

.margin-b_one {
    margin-bottom: 1rem !important
}

.margin-t_one {
    margin-top: 1rem !important
}

.sai-bubble-wrapper {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap
}

.sai-bubble-wrapper.in {
    justify-content: flex-start
}

.sai-bubble-wrapper.out {
    justify-content: flex-end
}

.sai-greeting {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.5em;
}

.greeting-prompt:hover{
    border: solid 2px #353387;
}
.greeting-prompt{
    border: solid 2px transparent;
}
.greeting-prompt.selected{
    border: solid 2px #353387;
}
.opacity-1:hover{
    opacity: 1 !important;
}