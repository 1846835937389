.users{

}
.users-modal-background{
    /*! CSS Used from: Embedded */
body p{margin:0px;padding:0px;}
body input::placeholder{color:rgb(146, 162, 177);font-weight:normal;}
body button{font-family:-apple-system, ".SFNSText-Regular", "San Francisco", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;}
.css-ldpdeo{box-sizing:border-box;display:flex;direction:ltr;}
.css-aqlc50{box-sizing:border-box;direction:ltr;}
.css-1v1kfck{width:100%;}
.css-1yku2z4-PrimaryText{font-size:14px;line-height:21px;margin:0px;color:rgb(18, 52, 77);font-weight:500;display:block;}
.css-9g696q{box-sizing:border-box;display:flex;flex-direction:column;height:100%;direction:ltr;}
.css-5bn7x9{width:100%;display:block;margin:0px;padding:8px 16px;cursor:pointer;text-align:center;font-weight:600;letter-spacing:0px;border-width:1px;border-style:solid;border-radius:4px;background-color:rgb(18, 52, 77);border-color:rgb(18, 52, 77);box-shadow:rgba(24, 50, 71, 0.05) 0px 1px 0px 0px;color:rgb(255, 255, 255);height:34px;font-size:14px;background-image:linear-gradient(rgb(38, 73, 102) 0%, rgb(18, 52, 77) 100%);}
.css-5bn7x9:not([disabled]):hover{background-color:rgb(18, 52, 77);background-image:none;}
.css-5bn7x9:not([disabled]):active{outline:0px;box-shadow:rgba(18, 52, 77, 0.2) 0px 0px 4px 1px inset;background-color:rgb(38, 73, 102);border:1px solid rgb(162, 192, 222);}
.css-5bn7x9:focus:not(:active){outline:none;border:1px solid transparent;box-shadow:rgb(44, 92, 197) 0px 0px 0px 2px;}
.css-1rc5nmw{box-sizing:border-box;margin-right:4px;direction:ltr;}
.css-1sudz2s{display:inline-flex;width:10px;height:10px;}
.css-1sudz2s img{width:10px;height:10px;}
.css-1y31z91-H4{font-size:18px;line-height:23.4px;margin:0px;font-weight:600;color:rgb(18, 52, 77);}
.css-ix6cs0-PrimaryText{font-size:14px;line-height:21px;margin:0px;color:rgb(18, 52, 77);font-weight:400;display:block;}
.css-1sbuixh-SecondaryText{font-size:12px;color:rgb(18, 52, 77);font-weight:400;}
.css-1kpjyzc{box-sizing:border-box;display:flex;-webkit-box-pack:end;justify-content:flex-end;direction:ltr;}
.css-653y0y-AddUserBody{box-sizing:border-box;padding:32px;-webkit-box-flex:1;flex-grow:1;overflow:auto;direction:ltr;}
.css-raag6e{box-sizing:border-box;margin-bottom:20px;direction:ltr;}
.css-1azxogf{box-sizing:border-box;width:50%;direction:ltr;}
.css-1uub6w8-FormikFieldContainer{position:relative;}
.css-47ktjw{width:100%;font-size:12px;line-height:20px;font-weight:600;cursor:pointer;color:rgb(71, 88, 103);}
.css-47ktjw::after{padding:2px;content:"*";color:rgb(215, 45, 48);}
.css-bcefp7{box-sizing:border-box;margin:4px;direction:ltr;}
.css-1pixi5w{width:100%;height:32px;box-sizing:border-box;border-radius:4px;border:1px solid rgb(123, 142, 159);background:white;appearance:none;}
.css-1pixi5w input{display:inline-block;width:100%;height:100%;padding:6px 12px;box-sizing:border-box;color:rgb(18, 52, 77);font-size:14px;font-weight:600;line-height:20px;border:none;background:transparent;outline:none;}
.css-1pixi5w input:focus{border-bottom:none;}
.css-1pixi5w:hover{border-color:rgb(71, 88, 103);transition:border-color 0.3s ease-in-out 0s;}
.css-1pixi5w input::placeholder{color:rgb(146, 162, 177);font-weight:normal;}
.css-rwk3fz{box-sizing:border-box;height:100%;display:flex;direction:ltr;}
.css-16teaqt{box-sizing:border-box;flex:1 1 0%;direction:ltr;}
.css-1owgwz9{box-sizing:border-box;margin-left:16px;width:50%;direction:ltr;}
.css-1xbns9q{box-sizing:border-box;padding-bottom:8px;direction:ltr;}
.css-1ti47aj{box-sizing:border-box;margin-bottom:20px;margin-top:8px;display:flex;align-items:flex-start;direction:ltr;}
.css-mw3885{appearance:none;width:12px;height:12px;font-size:11px;line-height:22px;margin:0px;padding:0px;outline:none;box-sizing:border-box;}
.css-mw3885::before{width:12px;height:12px;display:inline-block;content:"";border:1px solid rgb(71, 88, 103);border-radius:100%;vertical-align:text-top;cursor:pointer;}
.css-mw3885::after{width:24px;height:24px;background-color:rgb(87, 108, 125);display:flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;left:-5px;top:0px;color:white;text-align:center;line-height:18px;font-size:46px;position:absolute;opacity:0.2;border-radius:50%;}
.css-mw3885:focus::before{box-shadow:white 0px 0px 0px 1px, rgb(44, 92, 197) 0px 0px 0px 2px;}
.css-mw3885:not([disabled]):hover::after{content:"•";}
.css-mw3885:not([disabled]):hover:checked::after{content:"";}
.css-mw3885:checked::before{content:"•";font-size:20px;border:1px solid rgb(44, 92, 197);color:rgb(255, 255, 255);text-align:center;line-height:0.6;background:rgb(44, 92, 197);}
.css-1f8lw3p{box-sizing:border-box;margin-left:16px;width:100%;direction:ltr;}
.css-1n81iq5-AddUserFooter{box-sizing:border-box;padding:16px 32px;background:rgb(235, 239, 243);direction:ltr;position: absolute;bottom: 0;width: 100%;left: 0;}
.css-1lzz26o{box-sizing:border-box;margin-right:16px;direction:ltr;}
.css-6x4naz{width:100%;display:block;margin:0px;padding:8px 16px;cursor:pointer;text-align:center;font-weight:600;letter-spacing:0px;border-width:1px;border-style:solid;border-radius:4px;background-color:rgb(255, 255, 255);border-color:rgb(207, 215, 223);box-shadow:rgba(24, 50, 71, 0.05) 0px 1px 0px 0px;color:rgb(18, 52, 77);height:34px;font-size:14px;background-image:linear-gradient(rgb(255, 255, 255) 0%, rgb(245, 247, 249) 100%);}
.css-6x4naz:not([disabled]):hover{background-color:rgb(245, 247, 249);background-image:none;}
.css-6x4naz:not([disabled]):active{outline:0px;box-shadow:rgba(18, 52, 77, 0.2) 0px 0px 4px 1px inset;background-color:rgb(255, 255, 255);border:1px solid rgb(207, 215, 223);}
.css-6x4naz:focus:not(:active){outline:none;border:1px solid transparent;box-shadow:rgb(44, 92, 197) 0px 0px 0px 2px;}
.css-14jv7p5{box-sizing:border-box;width:auto;min-width:90px;direction:ltr;}
.css-18yg2qm{box-sizing:border-box;margin-bottom:40px;direction:ltr;}
.css-16gtxd3{box-sizing:border-box;margin-top:46px;margin-bottom:46px;opacity:1;border:1px solid rgb(235, 239, 243);direction:ltr;}
.css-bf7jbj{box-sizing:border-box;margin-bottom:33px;direction:ltr;}
.css-cq4utj{box-sizing:border-box;padding-left:4px;direction:ltr;}
.css-158kuf9-AccountsContainer{box-sizing:border-box;margin-top:7px;direction:ltr;}
.css-v18fff{box-sizing:border-box;margin-top:12px;direction:ltr;}
.css-dfcde2{width:auto;display:inline-block;margin:0px 5px;padding:8px 16px;cursor:pointer;text-align:center;font-weight:600;letter-spacing:0px;border-width:1px;border-style:solid;border-radius:4px;background-color:rgb(255, 255, 255);border-color:rgb(207, 215, 223);box-shadow:rgba(24, 50, 71, 0.05) 0px 1px 0px 0px;color:rgb(18, 52, 77);height:34px;font-size:14px;background-image:linear-gradient(rgb(255, 255, 255) 0%, rgb(245, 247, 249) 100%);}
.css-dfcde2:not([disabled]):hover{background-color:rgb(245, 247, 249);background-image:none;}
.css-dfcde2:not([disabled]):active{outline:0px;box-shadow:rgba(18, 52, 77, 0.2) 0px 0px 4px 1px inset;background-color:rgb(255, 255, 255);border:1px solid rgb(207, 215, 223);}
.css-dfcde2:focus:not(:active){outline:none;border:1px solid transparent;box-shadow:rgb(44, 92, 197) 0px 0px 0px 2px;}
}