.tWfTvb .jBmls {
    z-index: 993;
}

.VfPpkd-Bz112c-LgbsSe {
    font-size: 24px;
    width: 48px;
    height: 48px;
    padding: 12px;
}

.VfPpkd-Bz112c-LgbsSe:disabled {
    color: rgba(0, 0, 0, .38);
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, .38));
}

.VfPpkd-Bz112c-LgbsSe svg {
    width: 24px;
    height: 24px;
}

.VfPpkd-Bz112c-LgbsSe {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    border: none;
    outline: none;
    background-color: transparent;
    fill: currentColor;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    z-index: 0;
    overflow: visible;
}

@media screen and (forced-colors:active) {
    .VfPpkd-Bz112c-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Bz112c-J1Ukfc-LhBDec {
        display: block;
    }
}

.VfPpkd-Bz112c-LgbsSe:disabled {
    cursor: default;
    pointer-events: none;
}

.VfPpkd-Bz112c-J1Ukfc-LhBDec {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    display: none;
}

@media screen and (forced-colors:active) {
    .VfPpkd-Bz112c-J1Ukfc-LhBDec {
        border-color: CanvasText;
    }
}

.VfPpkd-Bz112c-J1Ukfc-LhBDec::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
}

@media screen and (forced-colors:active) {
    .VfPpkd-Bz112c-J1Ukfc-LhBDec::after {
        border-color: CanvasText;
    }
}

.VfPpkd-Bz112c-LgbsSe {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: "";
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1;
    z-index: var(--mdc-ripple-z-index, 1);
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
    z-index: 0;
    z-index: var(--mdc-ripple-z-index, 0);
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
    background-color: #000;
    background-color: var(--mdc-ripple-color, #000);
}

.VfPpkd-Bz112c-LgbsSe:hover .VfPpkd-Bz112c-Jh9lGc::before {
    opacity: .04;
    opacity: var(--mdc-ripple-hover-opacity, .04);
}

.VfPpkd-Bz112c-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Bz112c-Jh9lGc::before {
    transition-duration: 75ms;
    opacity: .12;
    opacity: var(--mdc-ripple-focus-opacity, .12);
}

.VfPpkd-Bz112c-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Bz112c-Jh9lGc::after {
    transition: opacity .15s linear;
}

.VfPpkd-Bz112c-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Bz112c-Jh9lGc::after {
    transition-duration: 75ms;
    opacity: .12;
    opacity: var(--mdc-ripple-press-opacity, .12);
}

.VfPpkd-Bz112c-LgbsSe:disabled:hover .VfPpkd-Bz112c-Jh9lGc::before {
    opacity: 0;
    opacity: var(--mdc-ripple-hover-opacity, 0);
}

.VfPpkd-Bz112c-LgbsSe:disabled:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Bz112c-Jh9lGc::before {
    transition-duration: 75ms;
    opacity: 0;
    opacity: var(--mdc-ripple-focus-opacity, 0);
}

.VfPpkd-Bz112c-LgbsSe:disabled:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Bz112c-Jh9lGc::after {
    transition: opacity .15s linear;
}

.VfPpkd-Bz112c-LgbsSe:disabled:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Bz112c-Jh9lGc::after {
    transition-duration: 75ms;
    opacity: 0;
    opacity: var(--mdc-ripple-press-opacity, 0);
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.yHy1rc {
    z-index: 0;
}

.yHy1rc .VfPpkd-Bz112c-Jh9lGc::before,
.yHy1rc .VfPpkd-Bz112c-Jh9lGc::after {
    z-index: -1;
}

.yHy1rc:disabled {
    color: rgba(60, 64, 67, .38);
    color: var(--gm-iconbutton-disabled-ink-color, rgba(60, 64, 67, .38));
}

.mN1ivc .VfPpkd-Bz112c-J1Ukfc-LhBDec {
    pointer-events: none;
    border: 2px solid rgb(24, 90, 188);
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
}

@media screen and (forced-colors:active) {
    .mN1ivc .VfPpkd-Bz112c-J1Ukfc-LhBDec {
        border-color: CanvasText;
    }
}

.mN1ivc .VfPpkd-Bz112c-J1Ukfc-LhBDec::after {
    content: "";
    border: 2px solid rgb(232, 240, 254);
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
}

@media screen and (forced-colors:active) {
    .mN1ivc .VfPpkd-Bz112c-J1Ukfc-LhBDec::after {
        border-color: CanvasText;
    }
}

.NMm5M {
    fill: currentColor;
    flex-shrink: 0;
}

.d1dlne,
.Ax4B8 {
    display: flex;
    flex: 1;
}

.L6J0Pc {
    flex: 1;
}

.u3WVdc {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    outline: none;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.Ax4B8 {
    position: relative;
}

.yNVtPc {
    position: absolute;
    left: 0;
    width: 100%;
    opacity: .3;
}

.Ax4B8,
.yNVtPc {
    background-color: transparent;
    color: inherit;
    font: inherit;
    line-height: inherit;
}

.d1dlne,
.Ax4B8,
.yNVtPc {
    height: 100%;
}

.MkjOTb {
    cursor: default;
}

.NydbP {
    height: 32px;
    overflow: hidden;
    display: inline-block;
    font-family: Roboto, Arial, sans-serif;
}

.zWwE1 {
    transform: translateY(calc(-100% + 28px));
    transition: transform .2s linear;
    -webkit-transition: transform .2s linear, -webkit-transform .2s linear;
    transition: transform .2s linear, -webkit-transform .2s linear;
}

.JwB6zf {
    display: flex;
    -webkit-box-align: center;
    box-align: center;
    align-items: center;
    box-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    white-space: nowrap;
}

.V53LMb {
    display: flex;
}

.nZQ6l {
    letter-spacing: .00625em;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    padding: 0 8px;
    border-radius: 8px;
    width: min-content;
    min-width: 40px;
    color: rgb(19, 115, 51);
    background: rgb(230, 244, 234);
}

.SJyhnc {
    overflow: hidden;
    width: 100%;
}

.NBZP0e {
    position: relative;
    display: flex;
    height: 100%;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
}

.NBZP0e::-webkit-scrollbar {
    display: none;
}

.NBZP0e {
    overflow-x: overlay;
    padding-bottom: 17px;
    margin-bottom: -17px;
}

@media screen and (max-width:840px) {
    .NBZP0e {
        padding-bottom: 17px;
        margin-bottom: -17px;
    }
}

.M52nVb {
    width: 100%;
}

.ZB3Ebc {
    background: white;
    outline: none;
}

.ZB3Ebc .ZAGvjd {
    color: rgb(32, 33, 36);
    -webkit-text-fill-color: rgb(32, 33, 36);
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 1.55rem;
    line-height: 1.5rem;
    opacity: 1;
    padding: 0;
    padding-right: 48px;
    margin: 0;
    border: none;
    outline: none;
}

.ZB3Ebc .Ny5lGc {
    padding-right: 0;
}

.eEMh0d {
    color: rgb(95, 99, 104);
    position: absolute;
    top: 18px;
    left: 20px;
}

.ZB3Ebc .jBmls {
    max-height: unset !important;
}

@media screen and (max-width:768px) {
    .ZB3Ebc .ZAGvjd {
        padding-right: 0;
    }
}

.E6c19c {
    border-radius: 8px 8px 0 0;
}

.J3INNd {
    display: flex;
}

.GqNdIe {
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
}

.vdK5ke .GqNdIe {
    font-family: Roboto, Arial, sans-serif;
    line-height: 1.25rem;
    font-size: 1.25rem;
    letter-spacing: .0178571429em;
    font-weight: 500;
    border-radius: 24px;
    box-sizing: border-box;
    color: rgb(128, 134, 139);
    padding: 12px;
    margin-right: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.vdK5ke .GqNdIe:hover {
    background: rgb(241, 243, 244);
}

.vdK5ke .GqNdIe:focus {
    background: rgb(241, 243, 244);
}

.vdK5ke .GqNdIe-YySNWc {
    background: rgb(232, 240, 254);
    color: rgb(26, 115, 232);
}

.vdK5ke .GqNdIe-YySNWc:hover {
    background: rgb(210, 227, 252);
}

.vdK5ke .GqNdIe-YySNWc:focus {
    background: rgb(210, 227, 252);
}

@media screen and (max-width:768px) {
    .jsaWM {
        margin-right: -16px;
        margin-left: -16px;
    }

    .jsaWM .xbmkib {
        padding-left: 16px;
    }
}

.AyKEed {
    position: absolute;
    width: 900px;
    left: 50%;
    margin-top: 0px;
    margin-left: -424px;
    z-index: 3;
}

.nz7KN {
    border-radius: 30px;
    box-shadow: 0 1px 3px rgba(60, 63, 66, .32), 0 4px 12px rgba(60, 63, 66, .15);
    height: 60px;
    padding-left: 54px;
}

.E6c19c .nz7KN {
    border-radius: 30px;
}

.E6c19c .nz7KN.focused{
    border-radius: 30px 30px 0 0;
}

.nz7KN .jBmls {
    box-shadow: 0 1px 3px rgba(60, 63, 66, .32), 0 4px 12px rgba(60, 63, 66, .15);
    border-radius: 0 0 30px 30px;
    border-top: 1px solid rgb(218, 220, 224);
}

@media screen and (max-width:768px) {
    .AyKEed {
        width: 94%;
        left: 3%;
        margin-left: 0;
        margin-top: 16px;
    }
}

/*! CSS Used from: Embedded */
.mLoZRc {
    background: white;
}

.SAq8ff[aria-selected=true] {
    background: rgb(241, 243, 244);
}

.CrPloe {
    font-family: Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .00625em;
    white-space: pre-wrap;
    overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}

.q5O0Dd {
    padding: 6px 20px;
}

.onRPD {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid rgb(218, 220, 224);
    cursor: pointer;
}

.onRPD:hover {
    background: rgb(241, 243, 244);
}

.CrPloe {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.75rem;
    font-size: 1.375rem;
    letter-spacing: .0178571429em;
    font-weight: 300;
    color: rgb(60, 64, 67);
    font-weight: 600;
    max-width: 60vw;
}

.lzO0Ed {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.25rem;
    font-size: .1.25rem;
    letter-spacing: .0178571429em;
    font-weight: 500;
    margin-right: 24px;
    color: rgb(32, 33, 36);
}

.S4qG1c {
    font-family: Roboto, Arial, sans-serif;
    line-height: 1rem;
    font-size: 1rem;
    letter-spacing: .0727272727em;
    font-weight: 500;
    color: rgb(95, 99, 104);
    text-transform: uppercase;
}

.ZilHG {
    display: flex;
    align-items: center;
}

.Ps6EPc {
    padding-bottom: 16px;
    border-bottom: none;
    min-height: 41px;
}

.ims1Qe {
    display: flex;
    align-items: center;
    font-family: Roboto, Arial, sans-serif;
    line-height: 1rem;
    font-size: 1rem;
    letter-spacing: .025em;
    font-weight: 400;
    color: rgb(95, 99, 104);
    padding-left: 20px;
    height: 32px;
}

.ppUMU.ppUMU {
    width: 18px;
    height: 18px;
}

/*! CSS Used fontfaces */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCxc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2)format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2)format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2)format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2)format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2)format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2)format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2)format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2)format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCxc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2)format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2)format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2)format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPiIUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0308, U+0530-058F, U+2010, U+2024, U+25CC, U+FB13-FB17;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPiAUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0951-0952, U+0964-0965, U+0980-09FE, U+1CD0, U+1CD2, U+1CD5-1CD6, U+1CD8, U+1CE1, U+1CEA, U+1CED, U+1CF2, U+1CF5-1CF7, U+200C-200D, U+20B9, U+25CC, U+A8F1;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPj8UvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjYUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjMUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPiMUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+1200-1399, U+2D80-2DDE, U+AB01-AB2E, U+1E7E0-1E7E6, U+1E7E8-1E7EB, U+1E7ED-1E7EE, U+1E7F0-1E7FE;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPi0UvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0589, U+10A0-10FF, U+1C90-1CBA, U+1CBD-1CBF, U+2D00-2D2F;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjEUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPikUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0951-0952, U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPhEUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0951-0952, U+0964-0965, U+0A01-0A76, U+200C-200D, U+20B9, U+25CC, U+262C, U+A830-A839;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjAUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjkUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+1780-17FF, U+19E0-19FF, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjsUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0E81-0EDF, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPisUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0951-0952, U+0964-0965, U+0B01-0B77, U+1CDA, U+1CF2, U+200C-200D, U+20B9, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPi8UvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0964-0965, U+0D81-0DF4, U+1CF2, U+200C-200D, U+25CC, U+111E1-111F4;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPiQUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPi4UvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0951-0952, U+0964-0965, U+0C00-0C7F, U+1CDA, U+1CF2, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPiYUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPj0UvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjwUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjIUvbQoi-E.woff2)format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPiIUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0308, U+0530-058F, U+2010, U+2024, U+25CC, U+FB13-FB17;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPiAUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0951-0952, U+0964-0965, U+0980-09FE, U+1CD0, U+1CD2, U+1CD5-1CD6, U+1CD8, U+1CE1, U+1CEA, U+1CED, U+1CF2, U+1CF5-1CF7, U+200C-200D, U+20B9, U+25CC, U+A8F1;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPj8UvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjYUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjMUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPiMUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+1200-1399, U+2D80-2DDE, U+AB01-AB2E, U+1E7E0-1E7E6, U+1E7E8-1E7EB, U+1E7ED-1E7EE, U+1E7F0-1E7FE;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPi0UvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0589, U+10A0-10FF, U+1C90-1CBA, U+1CBD-1CBF, U+2D00-2D2F;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjEUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPikUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0951-0952, U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPhEUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0951-0952, U+0964-0965, U+0A01-0A76, U+200C-200D, U+20B9, U+25CC, U+262C, U+A830-A839;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjAUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjkUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+1780-17FF, U+19E0-19FF, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjsUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0E81-0EDF, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPisUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0951-0952, U+0964-0965, U+0B01-0B77, U+1CDA, U+1CF2, U+200C-200D, U+20B9, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPi8UvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0964-0965, U+0D81-0DF4, U+1CF2, U+200C-200D, U+25CC, U+111E1-111F4;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPiQUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPi4UvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0951-0952, U+0964-0965, U+0C00-0C7F, U+1CDA, U+1CF2, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPiYUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPj0UvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjwUvbQoi-Entw.woff2)format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UasrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RPjIUvbQoi-E.woff2)format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.search-card-wrapper{

    display: inline-block;
    width: 100%;

    .ant-input:focus{
        box-shadow: none !important;
    }

    .new-price{

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-end;
        align-items: flex-end;
        justify-content: center;

        .per-unit {
            color: #cccccc;
            font-size: 1rem;
            margin-left: 5px;
            display: flex;
            justify-content: flex-end;
            align-content: center;
            justify-items: end;
            align-items: center;
            flex-wrap: wrap;
        }

    }
    
}

.onRPD.p20{
    padding: 15px 20px;
}

.no-matches{
    color: #5d5d5d;
    display: block;
    padding: 20px;
    font-size: 17px;
}