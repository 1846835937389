/*! CSS Used from: https://accounts.commercial.supplyzone.ai/static/css/main.2a791702.chunk.css */

.kommand {

    .container.pt-2 {
        padding: 0;
    }

    #scrollableDiv {
        height: calc(100vh - 310px) !important;
        border: 1px solid transparent !important;
        padding: 0px !important;
    }

    #scrollableDivInner {
        height: calc(100vh - 57px) !important;
        border: 1px solid transparent !important;
    }


}

body:has(.kommand) {

    overflow: hidden;

    footer.footer {
        position: relative;
        bottom: 0;
        display: block;
        width: 100%;
        padding-top: 0 !important;
        margin-top: 0 !important;
    }


    .footer {
        margin: 0 !important;
        padding: 0 !important;
    }

}

.page-wrapper:has(.kommand) {
    background-color: #ffffff !important;
}

.ant-scroll-number.ant-badge-count.ant-badge-multiple-words {
    background-color: #542278 !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #542278 !important;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #542278 !important;
    pointer-events: none;
}

.text-grey {
    color: #999999 !important;
}

@media only screen and (max-width: 960px) {

    .row.scroll-chats {
        height: calc(100vh - 222px);
        max-height: unset;
        border-top: 1px solid #eee;
        background-color: rgba(128, 128, 128, 0.035);
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0;
        overflow: auto;
    }

    .messenger-text-wrapper {
        position: absolute;
        bottom: 16px;
        left: 16px;
        right: 24px;
        display: block;
    }

    .messenger-text-shadow {
        transform: rotate(180deg);
        width: calc(100vw + 20px);
        bottom: 0px !important;
        display: block;
        position: relative;
        right: 0;
        left: -16px;
        max-width: unset;
    }

    .anticon.anticon-paper-clip {
        width: unset !important;
        height: unset !important;
    }

    .speech-bubble {
        background-color: #efefef;
        border-radius: 10px;
        padding: 10px 10px;
        max-width: calc(100vw - 96px) !important;
        display: inline-block;
    }

    .document-details-wrapper {
        height: calc(100vh - 96px) !important;
        width: calc(100% + 16px);
        padding-right: 0;
        border: 1px solid transparent;
    }

    .list-view-title {
        margin: 0 0 4px 0;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 98%;
    }

}

.horizontal-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
}