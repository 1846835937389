html, body, h1, h2, h3, h4, h5, h6, p, span{
  margin: 0;
  font-family: "Google Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif !important ;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}
html, body {
  /* font-size: 0.75rem !important ; */
}

p {
  /*font-size: 1.25rem !important ;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important ;
}

