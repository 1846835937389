.tag-input {
    .input {
        width: auto;
        padding-left: 0;
    }

    .clear {
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        right: 10px;
    }
}

.tag-input.ant-input{
        background-color: #ffffff;
            border-radius: 4px;
            border: 1px solid #cccccc;
            padding: 3px 6px;
}