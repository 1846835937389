.D9D8DD-bg{
    background-color: #D9D8DD;
}
.C9C8CD-bg{
    background-color: #C9C8CD;
}
#swiper-wrapper-c54e273975d4ea71{
    transform: translate3d(-840px, 0px, 0px); transition-duration: 0ms;
}
.testimonial-swiper{
    width: 400px; margin-right: 20px;
}
.width-100{
    width: 100%;
}
.become-a-vendor-container{
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}