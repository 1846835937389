
/*----------------------
    Vendor
----------------------*/
.vendor-widget {
    margin-bottom: 2rem;
  }
  .vendor-widget .vendor-name {
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1.5rem;
    text-transform: capitalize;
    letter-spacing: -0.025em;
    line-height: 1.4;
  }
  .vendor-widget .vendor-name a {
    color: inherit;
  }
  .vendor-widget .vendor-name:hover {
    color: #552278;
  }
  
  .vendor-products.grid-type {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(100% / 3));
    margin: -2.5px;
    width: calc(100% + 5px);
    grid-auto-rows: 1fr;
  }
  .vendor-products.grid-type > * {
    padding: 2.5px;
  }
  .vendor-products .lg-item {
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
  }
  .vendor-products .sm-item {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  .vendor-products .product-media {
    height: 100%;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    overflow: hidden;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
  }
  .vendor-products .product-media img {
    height: 100%;
  }
  
  .vendor-logo {
    max-width: 7rem;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 7rem;
            flex: 0 0 7rem;
    margin-right: 1rem;
  }
  
  .vendor-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1.8rem 0;
  }
  
  .vendor-product-count {
    font-size: 1.2rem;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0.5rem;
  }
  
  .vendor-banner {
    min-height: 18rem;
  }
  .vendor-banner a, .vendor-banner img {
    min-height: inherit;
  }
  .vendor-banner a {
    display: block;
  }
  
  .visit-vendor-btn {
    display: block;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #ff9933;
  }
  .visit-vendor-btn:hover {
    color: #552278;
  }
  
  .vendor-widget-1 .product-media:hover {
    border-color: #552278;
  }
  .vendor-widget-1 .vendor-personal {
    margin-top: 0.2rem;
  }
  .vendor-widget-1 .ratings-container {
    margin-bottom: 0;
  }
  
  .vendor-widget-2 {
    padding: 2rem;
    border: 1px solid #eee;
    border-radius: 0.5rem;
    overflow: hidden;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
  }
  .vendor-widget-2:hover {
    border-color: #552278;
  }
  .vendor-widget-2 .vendor-details {
    padding-top: 0;
    padding-bottom: 2rem;
  }
  .vendor-widget-2 .product-media {
    border: none;
  }
  .vendor-widget-2 .ratings-container {
    margin-bottom: 0;
  }
  
  .vendor-widget-3 .vendor-logo {
    border-radius: 0.5rem;
    overflow: hidden;
  }
  .vendor-widget-3 .vendor-details {
    padding: 2rem 0;
  }
  .vendor-widget-3 .vendor-personal {
    padding-top: 0.3rem;
  }
  .vendor-widget-3 .ratings-container {
    margin-bottom: 0;
  }
  .vendor-widget-3 .ratings-container:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  
  .vendor-widget-banner {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    overflow: hidden;
    -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.3s;
    transition: border-color 0.3s, -webkit-box-shadow 0.3s;
    transition: border-color 0.3s, box-shadow 0.3s;
    transition: border-color 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .vendor-widget-banner .product-media {
    border: none;
  }
  .vendor-widget-banner .vendor-details {
    display: block;
    text-align: center;
    padding-top: 0;
    margin-top: -4.5rem;
    padding-bottom: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .vendor-widget-banner .vendor-logo {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    max-width: 9rem;
    margin: 0 auto 1.3rem auto;
    border-radius: 50%;
    background-color: #552278;
  }
  .vendor-widget-banner .vendor-logo img {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    border-radius: 50%;
  }
  .vendor-widget-banner .vendor-name {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 0;
  }
  .vendor-widget-banner .ratings-container {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 1rem;
  }
  .vendor-widget-banner .ratings-container .ratings-full {
    margin-right: 0;
  }
  .vendor-widget-banner .vendor-product-count {
    display: block;
    margin-bottom: 0.6rem;
    font-size: 1.4rem;
  }
  .vendor-widget-banner:hover {
    -webkit-box-shadow: 5px 0 20px rgba(0, 0, 0, 0.08);
            box-shadow: 5px 0 20px rgba(0, 0, 0, 0.08);
    border-color: transparent;
  }
  .vendor-widget-banner:hover .vendor-logo img {
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem);
  }
  
  .store {
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  .store .ratings-container {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }
  .store .store-banner img {
    height: 100%;
    width: 100%;
  }
  
  .store-content {
    position: absolute;
  }
  
  .store-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 1.9rem;
    color: #fff;
  }
  .store-title a {
    display: inline-block;
    text-transform: capitalize;
    font-size: 2rem;
    line-height: 1;
    color: inherit;
    text-shadow: rgba(0, 0, 0, 0.8) 0 1px 0;
    font-weight: 600;
    letter-spacing: -0.025em;
  }
  
  .featured-label {
    background: #2d54a3;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.2rem 1rem;
    -webkit-box-shadow: 0px 0px 25px -5px #afafaf;
            box-shadow: 0px 0px 25px -5px #afafaf;
    border-radius: 0.3rem;
    margin-left: 1.4rem;
    display: inline-block;
  }
  
  .seller-info-list {
    margin: 0;
    padding-left: 1.6em;
  }
  .seller-info-list .store-address, .seller-info-list .store-phone {
    font-size: 1.4rem;
  }
  .seller-info-list > * {
    line-height: 1.6;
    letter-spacing: -0.025em;
    margin-bottom: 0.8rem;
    color: #fff;
    opacity: 0.8;
  }
  .seller-info-list > * a {
    color: inherit;
  }
  .seller-info-list > * i {
    margin-left: -2.2rem;
    margin-right: 0.3rem;
    vertical-align: middle;
    font-size: 1.5rem;
    line-height: 0;
  }
  .seller-info-list .store-phone:hover, .seller-info-list .store-email:hover {
    color: #f93;
  }
  
  .store-address {
    font-size: 1.3rem;
    color: #fff;
    opacity: 0.8;
    line-height: 1.8;
  }
  
  .store-rating, .store-open {
    color: #fff;
    opacity: 0.8;
  }
  
  .store-footer {
    position: relative;
    padding: 2.3rem 2rem;
  }
  
  .btn.btn-icon-right.btn-visit i {
    font-size: 2rem;
    margin-left: 0.4rem;
    margin-bottom: 0.4rem;
  }
  
  .seller-brand {
    border-radius: 50%;
    background-color: #ff9742;
    width: 8rem;
    height: 8rem;
  }
  .seller-brand img {
    border-radius: 50%;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  
  .vendor-brand {
    max-width: 15rem;
    margin: 0 auto;
    text-align: center;
  }
  .vendor-brand .brand {
    border: 1px solid #eee;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 2.2rem;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .vendor-brand .vendor-name {
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .vendor-brand .vendor-name:hover {
    color: #552278;
  }
  .vendor-brand:hover .brand {
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12);
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12);
  }
  
  .store-grid {
    -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
  }
  .store-grid .store-banner img {
    min-height: 19.4rem;
  }
  .store-grid .store-content {
    top: 2.5rem;
    left: 3rem;
    max-width: 19.2rem;
  }
  .store-grid .seller-brand {
    right: 1.9rem;
    top: -58%;
  }
  .store-grid:hover .seller-brand img {
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem);
  }
  .store-grid .store-title:hover {
    color: #552278;
  }
  
  .store-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #eee;
  }
  .store-list .store-header {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 40rem;
            flex: 0 0 40rem;
    max-width: 40rem;
  }
  .store-list .store-header a {
    display: block;
    height: 100%;
  }
  .store-list .store-banner {
    height: 100%;
  }
  .store-list .store-content {
    position: relative;
  }
  .store-list .featured-label {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    margin-left: 0;
  }
  .store-list .store-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 2rem 2rem 2rem 6rem;
  }
  .store-list .store-title {
    color: #333;
  }
  .store-list .store-title a {
    text-shadow: none;
  }
  .store-list .ratings-container {
    margin-bottom: 0.3rem;
  }
  .store-list .store-address {
    color: #666;
    opacity: 1;
    margin-bottom: 0.9rem;
  }
  .store-list .seller-brand {
    top: 50%;
    left: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .store-list:hover .seller-brand img {
    -webkit-transform: translateX(-1rem);
            transform: translateX(-1rem);
  }
  
  .store-grid .seller-brand,
  .store-list .seller-brand {
    position: absolute;
  }
  .store-grid .seller-brand img,
  .store-list .seller-brand img {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    max-width: calc(100% + 2px);
    margin: -1px;
    -webkit-box-shadow: 0px 0px 0px 1px #eee;
            box-shadow: 0px 0px 0px 1px #eee;
  }
  
  .store.store-banner .store-media img {
    min-height: 44rem;
    width: 100%;
  }
  .store.store-banner .store-content {
    top: 0;
    left: 0;
    max-width: 34.5rem;
    padding: 5rem 4rem 5rem 5rem;
    background-color: rgba(37, 38, 42, 0.9);
    height: 100%;
  }
  .store.store-banner .seller-brand {
    margin-bottom: 2.3rem;
  }
  .store.store-banner .store-title {
    margin-bottom: 1.6rem;
    font-size: 2rem;
    letter-spacing: -0.025em;
  }
  .store.store-banner .social-icon {
    margin-right: 0.5rem;
    color: #fff;
    border-color: #ccc;
    opacity: 0.8;
  }
  
  .store-wcmp .store-content {
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background-color: rgba(34, 34, 40, 0.85);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 2rem;
  }
  .store-wcmp .seller-brand {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8rem;
            flex: 0 0 8rem;
    max-width: 8rem;
    margin-right: 2.2rem;
  }
  .store-wcmp .seller-date {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(100% - 10rem);
            flex: 0 0 calc(100% - 10rem);
    max-width: calc(100% - 10rem);
  }
  .store-wcmp .store-title {
    margin: 0.4rem 0 1.1rem;
  }
  .store-wcmp .store-title a {
    font-size: 1.8rem;
  }
  .store-wcmp .store-title a:hover {
    text-decoration: underline;
  }
  .store-wcmp .seller-info-list {
    margin: 0;
  }
  .store-wcmp .seller-info-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
    letter-spacing: 0;
    opacity: 0.7;
  }
  .store-wcmp .seller-info-list li p {
    margin-bottom: 0;
    line-height: 2;
  }
  .store-wcmp .seller-info-list .store-address p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .store-wcmp .seller-info-list .store-rating p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .store-wcmp .seller-info-list i {
    margin-top: 1.3rem;
    margin-right: 0.8rem;
    font-size: 1.4rem;
  }
  .store-wcmp .ratings-container {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
  
  .store-wcmp-banner .store-media img {
    width: 100%;
  }
  .store-wcmp-banner .store-content {
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 40%;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.6);
  }
  .store-wcmp-banner .seller-brand {
    margin: -7rem auto 2rem auto;
  }
  .store-wcmp-banner .social-icons {
    position: absolute;
    right: 2rem;
    top: -5rem;
  }
  .store-wcmp-banner .store-title {
    margin-bottom: 1rem;
    font-size: 2rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .store-wcmp-banner .seller-info-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding-left: 0;
  }
  .store-wcmp-banner .seller-info-list > * {
    margin: 0 0.6rem;
    opacity: 0.6;
    text-align: center;
  }
  .store-wcmp-banner .seller-info-list > * i {
    margin-right: 0.4rem;
    margin-left: 0;
  }
  
  .store-wcmp-banner .seller-brand,
  .store-wcfm-banner .seller-brand {
    width: 10rem;
    height: 10rem;
  }
  .store-wcmp-banner .seller-brand img,
  .store-wcfm-banner .seller-brand img {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    max-width: calc(100% + 2px);
    margin: -1px;
  }
  .store-wcmp-banner .social-icon:not(:last-child),
  .store-wcfm-banner .social-icon:not(:last-child) {
    margin-right: 0.5rem;
  }
  .store-wcmp-banner .social-icon,
  .store-wcfm-banner .social-icon {
    border-radius: 0.3rem;
  }
  
  .store-wcfm-banner .store-content {
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(34, 34, 34, 0.8);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 16rem;
    padding: 2.3rem 3rem 2.6rem;
  }
  .store-wcfm-banner .store-content-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .store-wcfm-banner .personal-info {
    margin-right: 4rem;
  }
  .store-wcfm-banner .seller-brand {
    margin: -7.2rem 0 2rem;
  }
  .store-wcfm-banner .ratings-container {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 1.2rem;
  }
  .store-wcfm-banner .ratings-full {
    margin: 0;
  }
  .store-wcfm-banner .store-title {
    margin-bottom: 0.9rem;
    font-size: 2rem;
    font-weight: 600;
  }
  .store-wcfm-banner .seller-info-list {
    padding-left: 2.4rem;
  }
  .store-wcfm-banner .seller-info-list li {
    margin-bottom: 0.4rem;
    letter-spacing: 0;
  }
  .store-wcfm-banner .seller-info-list li i {
    margin: 0 0.4rem 0 -2.4rem;
    font-size: 1.4rem;
  }
  .store-wcfm-banner .store-content-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .store-wcfm-banner .btn-inquiry {
    margin-bottom: 3rem;
  }
  
  .btn-inquiry,
  .btn-visit:not(.btn-link) {
    padding: 0.8em 1.6em;
    font-size: 1.3rem;
    background-color: #eee;
    border-color: #eee;
    color: #333;
    margin-right: 0.6rem;
  }
  .btn-inquiry:hover, .btn-inquiry:active, .btn-inquiry:focus,
  .btn-visit:not(.btn-link):hover,
  .btn-visit:not(.btn-link):active,
  .btn-visit:not(.btn-link):focus {
    background-color: #333;
    border-color: #333;
    color: #fff;
  }
  
  .vendor-toolbox .vendor-search-toggle {
    margin-right: 1.5rem;
    padding: 0.73em 0.9em 0.86em;
    font-weight: 700;
  }
  .vendor-toolbox .vendor-search-toggle i {
    margin: 0 0.8rem 0 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .vendor-toolbox .toolbox-left label {
    font-weight: 400;
    color: #333;
  }
  .vendor-toolbox .toolbox-layout {
    margin-left: 1.5rem;
  }
  .vendor-toolbox .btn-layout {
    color: #ccc;
  }
  .vendor-toolbox .btn-layout.icon-mode-list i {
    font-size: 2rem;
  }
  
  .vendor-search-wrapper {
    display: none;
    padding: 2rem;
    border-radius: 5px;
    -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
    margin-top: 3.2rem;
  }
  .vendor-search-wrapper .vendor-search-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .vendor-search-wrapper input {
    min-height: 3.8rem;
    height: 3.8rem;
    color: #777;
    border-radius: 0.3rem;
  }
  .vendor-search-wrapper input:focus {
    border-color: #8080809e;
  }
  .vendor-search-wrapper .btn {
    padding: 0.7em 1.65em;
  }
  
  .vendor-product-title {
    font-size: 1.4rem;
    font-weight: 600;
    padding: 1.9rem 1.5rem;
    border: 1px solid #eee;
    border-radius: 0.3rem;
  }
  .vendor-product-title a {
    color: #333;
  }
  .vendor-product-title a:hover {
    color: #552278;
  }
  
  .vendor-map {
    border: 1px solid #eee;
  }
  
  .google-map {
    height: 35rem;
    background-color: #98c3e5;
  }
  
  @media (max-width: 991px) {
    .store-wcmp {
      height: 100%;
      -webkit-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
              box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
    }
    .store-wcmp .store-content {
      display: block;
      position: relative;
      background: #fff;
    }
    .store-wcmp .seller-brand {
      margin-bottom: 2rem;
      -webkit-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
              box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
    }
    .store-wcmp .seller-brand img {
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      max-width: calc(100% + 2px);
      margin: -1px;
      border: 1px solid #eee;
    }
    .store-wcmp .seller-date {
      -webkit-box-flex: 1;
          -ms-flex: auto;
              flex: auto;
      max-width: 100%;
    }
    .store-wcmp .store-title a {
      color: #552278;
      text-shadow: none;
    }
    .store-wcmp .store-address, .store-wcmp .store-rating {
      color: #333;
    }
  
    .store-wcfm-banner .store-content {
      display: block;
    }
    .store-wcfm-banner .store-content-right {
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      margin: 1rem 0 0 14rem;
    }
    .store-wcfm-banner .store-content-right .btn {
      margin-bottom: 1.5rem;
    }
  }
  @media (max-width: 767px) {
    .toolbox.vendor-toolbox {
      display: block;
    }
  
    .store-list .store-header {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 30rem;
              flex: 0 0 30rem;
      max-width: 30rem;
    }
  
    .store.store-banner .store-content {
      background: none;
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: 27rem;
    }
  
    .store-wcmp-banner .social-icons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      position: relative;
      top: auto;
      right: auto;
      margin-top: 1rem;
    }
  }
  @media (max-width: 575px) {
    .store-list {
      display: block;
    }
    .store-list .store-header {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%;
    }
    .store-list .store-content {
      padding-left: 2rem;
    }
    .store-list .seller-brand {
      left: auto;
      -webkit-transform: none;
              transform: none;
      right: 2rem;
      top: -27%;
    }
  
    .store-wcfm-banner .store-content-left {
      display: block;
      text-align: center;
    }
    .store-wcfm-banner .personal-info {
      margin-right: 0;
    }
    .store-wcfm-banner .seller-brand {
      margin-right: auto;
      margin-left: auto;
    }
    .store-wcfm-banner .store-title {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    .store-wcfm-banner .store-content-right {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-left: 0;
    }
  }
  @media (max-width: 479px) {
    .store-list .seller-brand {
      top: -22%;
      width: 7rem;
      height: 7rem;
    }
  }