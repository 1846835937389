

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.header-top, .header-middle{
  /*background: #0a3168 !important;*/
}

.banner-content.content-top {
  padding: 12.6% 13%;
}

.bg-primary {
  background-color: #552278!important;
}

.category-classic .category-content {
  display: block;
  position: initial;
  padding: 20px 10px;
}

.category-classic .category-content h4{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category-classic:hover .category-name {
  transform: translateY(-75%);
}

.category-classic:hover .btn {
  top: 50%;
  transform: translate(-50%,300%);
}

.category a img{
  width: 100%;
  height: 120px;
  border: 1px solid transparent;
  object-fit: contain;
  vertical-align: middle;
}

 
.flex-box-center-children {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
}

.m-t-40{
  margin-top: 40px !important;
}

.login-popup{
  margin: 60px auto;
  border: 1px solid #d9f0ff;
  box-shadow: 1px 1px 24px rgb(0 151 233 / 15%);
}


.header-top {
  padding: 10px 0;
}

.header-middle {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.header-right > a {
  font-size: 12px;
  font-weight: 400;
}

.tabbedSearch{
  position: absolute;
  margin-top: -22px;
  width: 100%;
  display: block;
  text-align: center;
}

.tabbedSearch span {
  margin: 0 10px;
}
.tabbedSearch span.active a {
  color:rgb(69, 165, 255);
  text-decoration: underline;
}

.category-dropdown>a i {
  margin-bottom: 0.1rem;
  font-size: 1.7rem;
  line-height: 0;
  opacity: 0;
}

.category-classic {
  transition: box-shadow 0.3s;
  border: 1px solid #e5e6e7;
}

.header-bottom {
  border-bottom: 1px solid #eeeeee;
}

.tab-nav-underline .nav-item .nav-link.active:after{
  border-color: #00245e !important;
}

.wishlist span a:hover {
  text-decoration: underline !important;
}

.w-icon-user:before {
  font-size: 40px;
  color: #552278;
}

.header-search.hs-expanded .select-box, .header-search input.form-control, .header-search .btn-search{

  border-color:  #ffffff !important;
}

.header-top  {
  background: #f3f3f3;
}
.header-middle {
  background: #ffffff;
}

.btn-primary {
  color: #fff;
  border-color: #552278;
  background-color: #552278;
}

.login-popup .nav.nav-tabs .nav-item {
  width: calc(25% - .5rem);
  margin: 0;
}
.filter-items a {
  position: relative;
  display: block;
  color: inherit;
  padding: 0rem 0 1rem 0.2rem;
  font-size: 1.1rem;
}
.breadcrumb li:not(:last-child)::after {
  content: "|";
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  font-family: "wolmart";
}
@import url('Front.scss');
@import url('assets/css/vendors.css');
@import url('assets/css/seller.css');
@import url('assets/css/rfq.css');
@import url('assets/css/become-a-vendor.css');

.image-container {
  display: grid;
  place-items: center;
  width: 100%; /* adjust the width */
  height: 200px; /* adjust the height */
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain !important;
  width: 100% !important;
  height: 200px !important; /* adjust the height */
}


.image-container.smaller {
  display: grid;
  place-items: center;
  width: 100%; /* adjust the width */
  height: 92px; /* adjust the height */
}

.image-container.smaller img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain !important;
  width: 100% !important;
  height: 92px !important; /* adjust the height */
}

.image-container .redeem-skt{
    display: block !important;
    width: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
    min-width: 195px !important;
} 

  .image-container .ant-skeleton-image-svg {
    width: 48px;
    height: 48px;
    line-height: 48px;
    max-width: 192px;
    max-height: 192px;
    position: absolute;
    margin-top: 75px;
    margin-left: 98px;
}

.image-container.smaller .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
  position: absolute;
  left: -24px;
  top: -32px;
}

.one-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* or use 'clip' for clipping or a custom string */
}