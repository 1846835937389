body {
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.2;
  color: #000;
  letter-spacing: 0;
}

.category-classic .category-name {
  font-size: 1.25rem;
  line-height: 1.45;
  transition: transform 0.3s;
}

.category-classic .btn {
  font-size: 1.1rem;
}

.category-classic:hover .btn {
  top: 50%;
  transform: translate(-50%, 365%);
}

.breadcrumb-nav {
  display: flex;
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
  color: #333;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 1.5rem 0.2rem 1.6rem;
}

.bb-no {
  border-bottom: none !important;
}

.product-nav {
  margin: 0 0 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
}

.product-nav .product-nav-next,
.product-nav .product-nav-prev {
  position: relative;
}

.product-nav .product-nav-next a,
.product-nav .product-nav-prev a {
  display: inline-block;
  padding: 1.1rem 0.4rem 1.3rem;
}

.product-nav .product-nav-next .product-nav-popup {
  right: 0;
}

.m-30-0 {
  margin: 30px 0;
}

.m-20-0 {
  margin: 15px 0;
}

.product-single .product-form.call-to-action {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  justify-content: space-around;
}

div.ReactModal__Overlay.ReactModal__Overlay--after-open {
  position: fixed;
  inset: 0px;
  background-color: rgb(3 3 4 / 75%) !important;
  z-index: 2000;
}

.form-control.error {
  border-color: red;
}

.company-verification {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: space-around;
}

.company-verification div div {
  padding: 1px 10px;
  margin: 0 2px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 10px;
}

.pill-financials {
  background-color: #d1a546;
  color: #ffffff;
}

.pill-years {
  background-color: #cccccc;
  color: #777777;
}

.product-widget .product-media {
  max-width: 30rem;
}

.product-single .product-qty-form {
  margin: 0;
}

.product-single .product-qty-form input {
  border: 2px solid #552278;
  border-radius: 0;
}

.product-qty-form .input-group {
  width: 95%;
}

.ReactModal__Content .product-qty-form .input-group {
  width: 95%;
}

.shaded-bg {
  background-color: #fafafa;
}

.product-media img:first-child {
  position: relative;
  opacity: 1;
  border: 1px solid #eeeeee;
  /*width: 185px !important;
  height: 140px !important;*/
  object-fit: contain;
}

div.product-details .sold-by {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-product {
  padding: 0.5em 0;
  background-color: #552278;
}

.sold-by a {
  color: #777777;
}

.bg-grey {
  background-color: #e2e2e2;
  padding: 10px;
  border-radius: 5px;
}

.bg-white {
  background-color: #ffffff;
  padding: 10px;
}

.new-price.small {
  font-size: 16px;
  color: #aaaaaa;
  font-weight: 500;
  letter-spacing: 0px;
}

.new-price.xsmall {
  font-size: 14px;
  color: #777777;
  font-weight: 600;
  letter-spacing: 0px;
}

.new-price.xsmall.red {
  font-size: 24px;
  color: #cc0000;
}

.new-price.xsmall.dark {
  font-size: 24px;
  color: #333333;
}

.swiper-slide-thumb-active:before {
  border-color: transparent;
}

.swiper-slide-thumb-active img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  vertical-align: middle;
  width: 105px !important;
  height: 105px !important;
}

.product-gallery:not(.product-gallery-vertical) .swiper-slide img {
  width: 100%;
  max-height: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product-thumbs-wrap img {
  min-height: 90px !important;
  min-width: 90px !important;
  max-height: 90px !important;
  max-width: 90px !important;
}

.container {
  max-width: 1425px !important;
}

.page-title-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 180px;
}

.page-title-bar .breadcrumb {
  margin-top: 1em;
  padding: 0;
}

.page-title-bar .breadcrumb:first-child {
  margin-top: 0;
}

.page-title-bar.type-left {
  align-items: flex-start;
}

.page-title-bar.type-center {
  text-align: center;
}

.breadcrumb-container {
  width: 100%;
  margin: 0 auto;
}

.breadcrumb-container .breadcrumb {
  border-bottom: transparent;
  /* 1px solid #eee */
}

.breadcrumb-container:empty+main {
  margin-top: 5rem;
}

.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.3rem 0.2rem;
  font-family: var(--wolmart-ptb-breadcrumb-font-family), "Poppins", sans-serif;
  font-weight: var(--wolmart-ptb-breadcrumb-font-weight, 400);
  font-size: 13px;
  line-height: var(--wolmart-ptb-breadcrumb-line-height, 1.6);
  letter-spacing: var(--wolmart-ptb-breadcrumb-letter-spacing,
      var(--wolmart-body-letter-spacing));
  text-transform: var(--wolmart-ptb-breadcrumb-text-transform,
      var(--wolmart-body-text-transform));
  color: #333;
}

.breadcrumb .delimiter,
.breadcrumb .separator {
  opacity: 0.4;
  margin: 0 0.35em;
  font-size: 1.2em;
}

.breadcrumb a {
  opacity: 0.7;
  transition: color 0.3s, opacity 0.3s;
}

.breadcrumb a:hover {
  opacity: 1;
  color: inherit;
}

.breadcrumb.home-icon>li:first-of-type>a:before {
  font-family: "wolmart";
  content: "î¥§";
}

.breadcrumb_wrapper .breadcrumb {
  color: inherit;
}

.product-navigation {
  display: flex;
  justify-content: space-between;
  padding-left: 2px;
}

.main-content>.product-navigation,
.main>.product-navigation,
.main>div>.product-navigation {
  margin-top: -2rem;
}

.product-navigation+.woocommerce-notices-wrapper>.alert:first-child {
  margin-top: 0;
}

.product-nav {
  position: relative;
  display: flex;
  flex-direction: row;
}

.product-nav .product-nav-prev .product-nav-popup:before {
  right: 4.3rem;
}

.product-nav .product-nav-prev:last-child .product-nav-popup:before {
  right: 1rem;
}

.product-nav i {
  display: inline-block;
  border-radius: 50%;
  width: 2em;
  color: #666;
  border: 1px solid #eee;
  line-height: calc(2em - 2px);
  text-align: center;
  font-size: 1.3rem;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.product-nav a {
  display: inline-block;
  padding: 1.2rem 0.4rem;
}

.product-nav span span {
  position: relative;
  color: #666;
  font-size: 1.2rem;
  line-height: 1.2;
  padding: 1rem 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  background: inherit;
  z-index: 1;
}

.product-nav li:hover i {
  background-color: #0088dd;
  border-color: #0088dd;
  color: #fff;
}

.product-nav li:hover .product-nav-popup {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.product-nav-popup {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 30;
  width: 120px;
  padding: 0 5px 10px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transform: scale(0.9);
  transform-origin: top;
  transition: opacity 0.3s, transform 0.3s;
}

.product-nav-popup:before {
  content: "";
  position: absolute;
  top: -0.8rem;
  right: 1rem;
  width: 1.6rem;
  height: 1.6rem;
  transform: rotate(45deg);
  background-color: #fff;
  box-shadow: inherit;
}

.product-nav-popup img {
  position: relative;
  width: 100%;
  padding-top: 5px;
  background-color: #fff;
}

.btn.btn-sm.xs {
  font-size: 1rem;
  padding: 0.5em 1em;
  border-width: 1px;
}

.row .profile {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.profile .badge {
  display: inline-block;
  margin-right: 3px;
  padding: 3px 6px;
  background-color: #eeeeee;
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
}

.profile .badge.level {
  background: linear-gradient(276.58deg, #ffc578 1.2%, #ffa52e 95.6%);
  color: #fff;
}

.text-green {
  color: #00c57b;
  font-weight: 700;
}

.gradient-top {
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(-4.03%, #f6f6f6),
      color-stop(50.34%, #fff));
  background: linear-gradient(180deg, #f6f6f6 -4.03%, #fff 50.34%);
}

.divTable {
  display: table;
  width: 100%;
}

.divTableRow {
  display: table-row;
}

.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}

.divTableCell,
.divTableHead {
  border: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px;
}

.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}

.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}

.divTableBody {
  display: table-row-group;
}

.divTableRow :first-child {
  background-color: rgb(240, 240, 240);
}

.divTableRow :nth-child(3) {
  background-color: rgb(240, 240, 240);
}

.toolbox,
.toolbox-right,
.toolbox-left,
.toolbox-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.toolbox label,
.toolbox-right label,
.toolbox-left label,
.toolbox-item label {
  font-weight: 600;
  margin-right: 1rem;
  line-height: 1.5;
}

.toolbox-right {
  margin-left: auto;
}

.toolbox {
  padding-bottom: 1rem;
}

.toolbox.toolbox-pagination {
  padding: 2.5rem 0;
  border-top: 1px solid #f4f4f4;
}

.toolbox> :not(.toolbox-pagination)*:not(:last-child),
.toolbox-left> :not(.toolbox-pagination)*:not(:last-child),
.toolbox-right> :not(.toolbox-pagination)*:not(:last-child) {
  margin-right: 1rem;
}

.toolbox .showing-info {
  font-size: 1.3rem;
  color: #aaa;
}

.toolbox .showing-info span {
  margin: 0 0.9rem;
  color: #333;
}

.toolbox .select-box {
  color: #333;
}

.toolbox .select-box::before {
  font-size: 1.1rem;
  right: 1rem;
  margin-top: 0.1rem;
}

.toolbox select {
  max-width: none;
  width: 15.4rem;
  padding: 0.75em 2.7rem 0.75em 1.4rem;
  border-radius: 0.3rem;
  cursor: pointer;
  border: 1px solid #d7d7d7;
}

.toolbox .toolbox-show select {
  padding-left: 1.3rem;
  padding-right: 2.8rem;
  width: 9.4rem;
}

.toolbox .item-found {
  color: #a9a9a9;
  font-size: 1.4rem;
  letter-spacing: 0;
}

.toolbox .item-found span {
  color: #333;
  margin-right: 0.4rem;
}

.toolbox .left-sidebar-toggle,
.toolbox .right-sidebar-toggle {
  padding: 0.78em 1.05em;
  font-size: 1.3rem;
}

.toolbox .left-sidebar-toggle i,
.toolbox .right-sidebar-toggle i {
  margin-right: 0.7rem;
  margin-left: 0.3rem;
  font-size: 1.4rem;
}

.toolbox .left-sidebar-toggle {
  margin: 0 2rem 1rem 0;
}

.toolbox .right-sidebar-toggle {
  margin-left: 2rem;
}

.toolbox-item {
  margin-bottom: 1rem;
}

.sticky-toolbox.fixed {
  background: #fff;
  padding: 1rem 2rem 0 !important;
}

.selected-items {
  display: none;
}

.toolbox+.selected-items {
  font-size: 1.2rem;
}

.selected-items>* {
  display: inline-block;
  margin: 0 0.8rem 0.5rem 0;
}

.selected-items .filter-clean {
  margin-left: 1.2rem;
}

.selected-item {
  line-height: 1;
  letter-spacing: 0;
  background-color: #f5f5f5;
  color: #666;
  padding: 8px 1rem;
}

.selected-item i {
  margin-left: 1.1rem;
  vertical-align: middle;
}

.toolbox-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 1.9rem;
}

.btn-layout {
  line-height: 1;
  color: #dadada;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}

.btn-layout+.btn-layout {
  margin-left: 1rem;
}

.btn-layout i {
  font-size: 1.9rem;
  color: inherit;
}

.btn-layout.active,
.btn-layout:hover {
  color: #333;
}

.vendor-toolbox .vendor-search-toggle {
  margin-right: 1.5rem;
  padding: 0.73em 0.9em 0.86em;
  font-weight: 700;
}

.vendor-toolbox .vendor-search-toggle i {
  margin: 0 0.8rem 0 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.vendor-toolbox .toolbox-left label {
  font-weight: 400;
  color: #333;
}

.vendor-toolbox .toolbox-layout {
  margin-left: 1.5rem;
}

.vendor-toolbox .btn-layout {
  color: #ccc;
}

.vendor-toolbox .btn-layout.icon-mode-list i {
  font-size: 2rem;
}

.vendor-search-wrapper {
  display: none;
  padding: 2rem;
  border-radius: 5px;
  -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
  margin-top: 3.2rem;
}

.vendor-search-wrapper .vendor-search-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.vendor-search-wrapper input {
  min-height: 3.8rem;
  height: 3.8rem;
  color: #777;
  border-radius: 0.3rem;
}

.vendor-search-wrapper input:focus {
  border-color: #8080809e;
}

.vendor-search-wrapper .btn {
  padding: 0.7em 1.65em;
}

.vendor-product-title {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.9rem 1.5rem;
  border: 1px solid #eee;
  border-radius: 0.3rem;
}

.vendor-product-title a {
  color: #333;
}

.vendor-product-title a:hover {
  color: #552278;
}

.vendor-map {
  border: 1px solid #eee;
}

.google-map {
  height: 35rem;
  background-color: #98c3e5;
}

@media only screen and (min-width: 960px) {

  .intro-wrapper {
    margin-left: 26rem;
  }

  .header-search.hs-expanded {
    flex: 1;
    margin: 0 auto;
    min-width: auto;
    max-width: 55.6rem;
    margin-left: 163px;
  }

  .CrPloe {
    font-family: "Google Sans", Roboto, Arial, sans-serif !important;
    line-height: 1.75rem !important;
    font-size: 1.675rem !important;
    letter-spacing: 0.0178571429em !important;
    color: rgb(60, 64, 67) !important;
    font-weight: 300 !important;
    max-width: 60vw !important;
  }

  .S4qG1c {
    padding-top: 5px;
    font-family: Roboto, Arial, sans-serif;
    line-height: 1.3rem;
    font-size: 1.53rem !important;
    letter-spacing: 0.0227272727em;
    font-weight: 500;
    color: rgb(95, 99, 104);
    text-transform: unset !important;
    margin: 7px 0 0 0 !important;
    display: block;
  }

  .lzO0Ed {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.25rem;
    font-size: 1.6rem;
    letter-spacing: 0.0178571429em;
    font-weight: 500;
    margin-right: 24px;
    color: rgb(32, 33, 36);
  }

  .vdK5ke .GqNdIe {
    font-size: 1.75rem !important;
  }

  .ZB3Ebc .ZAGvjd {
    font-size: 1.875rem !important;
  }

}

.product-media img:first-child {
  position: relative;
  opacity: 1;
  border: 1px solid #ffffff;
  object-fit: contain;
}

.header-middle {
  background: #3a3a3a !important;
  color: #ffffff !important;
  min-height: 82px;
}

.header-right>a,
.cart-dropdown .cart-toggle,
.wishlist span a,
.wishlist span,
.compare span.compare-label,
.header-middle .cart-label,
.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item:hover .nav-link,
.menu>li.active>a:not(.menu-title),
.menu>li:hover>a:not(.menu-title) {
  /*color: #ffffff !important;*/
}

.header-right>a,
.cart-dropdown .cart-toggle,
.wishlist span a,
.wishlist span,
.compare span.compare-label,
.header-middle .cart-label,
.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item:hover .nav-link {
  color: #ffffff !important;
}

.w-icon-user:before {
  font-size: 40px !important;
  color: #ffffff !important;
}

.header-top {
  background: #000000 !important;
}

.header-right a {
  font-size: 16px !important;
}

.logo-icon-2.png {
  color: #ffffff !important;
}

.sign-in-pill {
  padding: 2px 10px !important;
  background-color: #552278;
  color: #ffffff;
  border-radius: 20px;
}

.category-classic .category-content {
  display: block;
  position: initial;
  padding: 7px 10px !important;
}

.category-classic:hover .btn {
  top: 50%;
  transform: translate(-50%, 230%) !important;
}

.category-classic:hover .category-name {
  transform: translateY(-180%) !important;
}

.nav-tabs .nav-item .nav-link.active {
  color: #552278 !important;
}

.icon-box-side .icon-box-content p {
  line-height: 1.25;
}

.nav-tabs .nav-item .nav-link.blue.active {
  color: #552278 !important;
}

.product-widget .product-details {
  flex: unset;
  min-width: min-content;
  border: 1px solid transparent;
  max-width: max-content;
}

.product-media {
  border: 2px solid whitesmoke;
}

.max-height-218 {
  transform: scale(0.8);
  max-height: 198px !important;
  min-height: 198px !important;
  border: 1px solid #fdfdfd !important;
}

.max-height-100 {
  height: 100px;
  transform: scale(0.8);
  max-height: 198px !important;
  min-height: 100px !important;
  border: 1px solid #fdfdfd !important;
}

/*

.category-classic .category-name {
  font-size: 1.1rem;
}

.category-menu>li>a {
  font-size: 1.1rem !important;
}

*/

.product-single .product-title {
  margin-bottom: 0.9rem;
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}

.product-single .product-qty-form {
  flex: 0 0 10rem;
  max-width: unset;
}

.product-single .product-qty-form input,
.product-form.container.call-to-action button.btn.btn-primary.pl-3.pr-3 {
  min-width: 150px !important;
  border-radius: 10px;
  text-transform: unset;
}

.btn-primary:hover {
  color: #552278;
  border-color: #552278;
  background-color: #ffffff;
}

.product-tabs .nav-link,
.title-link-wrapper .title {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}

.product-single .product-price {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}

aside .product-widget .product-name {
  font-size: 1.15rem !important;
  font-weight: 600 !important;
}

aside .max-height-100 {
  max-height: 100px !important;
  min-height: 100px !important;
}

aside .product-widget .product-price {
  font-size: 1.25rem !important;
}

.vendor-product-section .product-name {
  font-size: 1.15rem !important;
  font-weight: 600 !important;
}

.vendor-product-section .max-height-100 {
  max-height: 100px !important;
  min-height: 100px !important;
}

.vendor-product-section .product-price {
  font-size: 1.25rem !important;
}

#product-tab-vendor li>span,
#product-tab-vendor li>a {
  font-size: 1.25rem;
  color: #666;
  opacity: 1;
}

#scroll-top svg {
  position: absolute;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  top: 8px;
  left: 8px;
  z-index: 1;
}

.new-price.amount {
  font-size: 2.25rem !important;
  color: #333333;
  letter-spacing: 0px;
}

.new-price.min-order {
  font-size: 1.2rem !important;
  color: #777777;
  letter-spacing: 0px;
}

.new-price.si-unit {
  font-size: 1.2rem !important;
  color: #777777;
  letter-spacing: 0px;
}

.new-price.xsmall.red,
.new-price.xsmall.dark {
  font-size: 1.5rem !important;
}

.product .sold-by {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-size: 0.9rem !important; */
  margin-bottom: 10px;
}

.product .product-name {
  margin-bottom: 0.25rem;
  font-size: 1.3rem;
}

.logo img {
  display: block;
  max-width: 200px;
}

.header-middle {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.wide-div.product-details {
  flex: unset;
  min-width: -webkit-fill-available !important;
  border: 1px solid transparent;
  max-width: max-content !important;
}

.new-price.price-range {
  font-size: 1.2rem;
  font-weight: 600;
  color: #552278;
  display: flex;
  margin: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.new-price.per-unit {
  color: #333;
  font-size: 1rem;
  margin-left: 5px;
  letter-spacing: 1px;
  font-weight: 600;
}

.btn-smaller {
  color: #ad1e23 !important;
  font-size: 16px !important;
}

.product-name a:hover {
  color: #552278;
}

.product-media {
  border: 2px solid whitesmoke !important;
  background-color: white !important;
}

.product-wrapper {
  margin-bottom: 0;
}

.header-right>a {
  color: #ffffff !important;
}

.menu>li:hover>a:not(.menu-title) {
  /*color: #000000 !important;*/
}

.header-search .btn-search:active,
.header-search .btn-search:focus,
.header-search .btn-search:hover {
  color: #ffffff;
  background-color: #ad1e23;
}

.logo img.bigger {
  display: block !important;
  max-width: 200px;
}

.logo img.smaller {
  display: none !important;
}

@media only screen and (max-width: 960px) {


  .search-card-wrapper {
    padding-left: 80px;
  }

  .logo {
    min-width: 75px;
    margin-right: 1rem;
  }

  .logo img.bigger {
    display: none !important;
  }

  .logo img.smaller {
    max-width: 65px;
    display: block !important;
  }

  .w-icon-category:not(.sticky-link .w-icon-category) {
    display: none !important;
  }

  .header-top .header-right a {
    display: flex;
    align-items: center;
    min-width: 75px;
    margin-left: 1rem;
  }

  .header-top .header-left {
    margin-right: 0rem;
  }

  .header-right a {
    font-size: 16px !important;
    margin-left: 1.5rem !important;
  }

  .sign-in-pill {
    background-color: #369;
    border-radius: 20px;
    color: #fff;
    padding: 7px !important;
    margin-left: 5px !important;
    min-width: 100px !important;
    justify-content: center;
  }
}

.icon-box p {
  font-size: 1.1rem;
  word-break: break-word;
  margin: 0;
  line-height: 1.2;
  color: #666;
}

.row .profile {
  margin-top: 20px;
}

.row .profile img {
  width: 100%;
  max-width: 60px;
  margin: 10px 10px 10px 0px !important;
}

.row .profile img.m0 {
  width: 100%;
  max-width: 60px;
  margin: 0px !important;
}

.profile .badge {
  margin-right: 6px;
}

.product-gallery:not(.product-gallery-vertical) .swiper-slide img {
  object-fit: contain !important;
}

.login-popup .form-control {
  min-height: 0.75rem !important;
  padding: 0.5rem !important;
}

.login-popup .col-md-6.form-group,
.login-popup .col-md-6.form-group.mb-5 {
  margin-bottom: 10px !important;
}

.login-popup p {
  font-size: 1rem !important;
  margin-bottom: 10px !important;
}

.login-popup .form-checkbox {
  margin-top: 1.2rem !important;
  margin-bottom: 1.2rem !important;
}

.login-popup .form-checkbox {
  margin-bottom: 30px auto !important;
}

.login-popup h3 {
  font-size: 1.75rem !important;
}

.login-popup {
  margin: 30px auto !important;
  padding: 30px !important;
}

.login-popup .btn {
  display: block;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.login-popup .form-group {
  margin-bottom: 0;
}

@media only screen and (max-width: 405px) {

  .header-right>a:nth-child(1),
  .header-right>a:nth-child(2) {
    visibility: visible !important;
  }

  .header-right a {
    font-size: 16px !important;
    margin-left: 1rem !important;
  }

  .header-left {
    width: 100%;
    min-width: 65px;
  }
}

@media only screen and (min-width: 720px) and (max-width: 991px) {

  div.intro-wrapper .cols-4>*,
  div.product-scroller.col-4>* {
    max-width: 25% !important;
    flex: 0 0 25% !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 719px) {

  div.intro-wrapper .cols-4>*,
  div.product-scroller.col-4>* {
    max-width: 33% !important;
    flex: 0 0 33% !important;
  }
}

@media only screen and (max-width: 480px) {

  div.intro-wrapper .cols-4>*,
  div.product-scroller.col-4>* {
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }
}

@media only screen and (max-width: 370px) {
  .header-right a {
    font-size: 14px !important;
    margin-left: 0.4rem !important;
  }
}

@media only screen and (min-width: 998px) {
  .cart-dropdown .cart-count {
    right: 24px !important;
  }
}

@media only screen and (max-width: 997px) {
  .cart-dropdown .cart-count {
    right: -10px !important;
  }
}

@media only screen and (max-width: 640px) {
  div.product-form.container.call-to-action.pr-0.pl-0>button.btn {
    width: 48%;
    font-size: 14px;
    padding: 20px 10px !important;
  }

  .product-thumbs-wrap img {
    min-height: 70px !important;
    min-width: 70px !important;
    max-height: 70px !important;
    max-width: 70px !important;
  }

  .mod-rfq.data-v-8070b792 {
    display: flex !important;
    padding: 0px !important;
    height: fit-content !important;
    flex-direction: column !important;
  }

  .mod-rfq .rfq-info.data-v-8070b792 {
    font-size: 14px !important;
    flex: 1 1 !important;
    text-shadow: 1px 1px 6px #2c567e !important;
  }

  .mod-rfq .rfq-form.data-v-8070b792 {
    width: fit-content !important;
    padding: 40px !important;
  }
}

#root {
  background-color: #f5f5f5;
}

.category-classic {
  transition: box-shadow 0.3s;
  border: 1px solid #f5f5f5;
  background-color: white;
}

div.container.pt-2>div.row>div.col-xl-6.col-lg-6.col-md-6.col-sm-6>div {
  background-color: #ffffff;
}

.category-dropdown.show-dropdown .dropdown-box .category-menu {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 10px #eeeeee;
}

.category-classic {
  background-color: #ffffff;
  box-shadow: 1px 1px 10px #eeeeee;
}

.category-dropdown.show-dropdown .dropdown-box:before {
  border-bottom: 10px solid #ffffff;
}

.swiper-slide.product.product-simple.text-center {
  margin-bottom: 20px;
}

.category-classic {
  transition: box-shadow 0.3s;
  border: 1px solid transparent !important;
}

.banner-content.content-top .banner-title {
  margin-bottom: 2.3rem;
  font-size: 2.35em;
  line-height: 1.15;
}

.btn.cat-buttons {
  border: 2px solid #333 !important;
  background-color: transparent !important;
  color: #333 !important;
}

.transparent-border-color {
  border-color: transparent !important;
}

.header-search input.form-control {
  font-size: 16px !important;
  font-family: unset;
}

@media only screen and (max-width: 640px) {
  .mod-rfq .rfq-form.data-v-8070b792 {
    width: -moz-fit-content !important;
    width: fit-content !important;
    padding: 20px !important;
    border-radius: 0 !important;
  }
}

@media only screen and (min-width: 640px) {
  .mod-rfq.snipcss-f18Yg.data-v-8070b792 {
    background-color: rgba(0, 0, 66, 0.14);
  }
}

h4>a {
  color: #552278 !important;
}

.intercom-lightweight-app-launcher {
  background: #552278 !important;
}

.xbrand-logos {
  border: none !important;
  height: 55px !important;
}

.xbrand-logos img {
  height: "55px !important";
  opacity: 0 !important;
}

.popover__title {
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  color: rgb(228, 68, 68);
  text-align: center;
  padding: 0;
}

.popover__title>img {
  width: 90px !important;
  cursor: pointer;
}

.popover__wrapper {
  position: relative;
  margin-top: 0;
  display: inline-block;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -150px;
  transform: translate(0, 10px);
  background-color: #ffffff;
  padding: 1.5rem;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.36);
  width: fit-content;
}

.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% + 25px);
  top: -14px;
  border-style: solid;
  border-width: 0 14px 14px 14px;
  border-color: transparent transparent #552278 transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.blue-header {
  background-color: #552278;
  position: absolute;
  margin: -20px;
  padding: 10px 20px;
  width: calc(100% + 1px);
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 5px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  right: 0px;
  width: 420px;
}

.popover__message {
  text-align: center;
}

img.cellImage {
  width: 18px !important;
  margin-left: 10px !important;
}

.o-flex-grid {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin: 0 auto;
}

.o-flex-grid--item {
  margin: 0px;
  padding: 0px;
  width: 220px !important;
}

.o-flex-grid--item:first-child:nth-last-child(1),
.o-flex-grid--item:first-child:nth-last-child(1)~div {
  width: 100%;
}

.o-flex-grid--item:first-child:nth-last-child(2),
.o-flex-grid--item:first-child:nth-last-child(2)~div {
  width: 50%;
}

.o-flex-grid--item:first-child:nth-last-child(3),
.o-flex-grid--item:first-child:nth-last-child(3)~div {
  width: 33%;
}

.o-flex-grid--item:first-child:nth-last-child(4),
.o-flex-grid--item:first-child:nth-last-child(4)~div {
  width: 25%;
}

.o-flex-grid--item:first-child:nth-last-child(5),
.o-flex-grid--item:first-child:nth-last-child(5)~div {
  width: 20%;
}

.o-flex-grid--item:first-child:nth-last-child(6),
.o-flex-grid--item:first-child:nth-last-child(6)~div {
  width: 16%;
}

.w-100 {
  width: 100%;
}

@media (max-width: 1366px) {
  .w-100 {
    max-width: 1200px;
    width: 100%;
  }
}

@media (max-width: 800px) {
  .o-flex-grid {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .o-flex-grid--item {
    width: 100% !important;
  }

  .sidebar-content {
    transform: unset !important;
    left: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    overflow: unset !important;
    background-color: transparent !important;
    opacity: 1 !important;
    line-height: 1.3 !important;
    position: relative !important;
    display: block !important;
    z-index: 2;
  }

  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, 5px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    right: 0px;
    width: 240px;
    left: 0;
  }
}

.btn-primary:activex {
  color: #fff;
  border-color: #552278;
  background-color: #002785;
}

.ReactModal__Content {
  /* overflow-y: auto !important; */
}

.ReactModal__Content h5 {
  font-size: 12px !important;
}

.ReactModal__Content .product-price {
  font-size: 14px !important;
}

.ReactModal__Content .product-price {
  border-radius: 0px;
}

.ReactModal__Content .input-group {
  height: 39px;
}

.ReactModal__Content .input-group .form-control {
  height: 50px;
}

.d-flex.x1 {
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.xbadge {
  background-color: #eeeeee;
  border-radius: 10px;
  font-size: 16px;
  padding: 3px 12px;
  margin: 0 20px !important;
}

.ReactModal__Content .tableCell {
  font-size: 14px;
  margin: 0;
}

.ReactModal__Content img.cellImage {
  width: 18px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.ReactModal__Content .pointCreators {
  width: 515px !important;
}

.ReactModal__Content .o-flex-grid--item {
  width: 225px !important;
  margin-bottom: 10px;
}

.ReactModal__Content .product-widget .product-media {
  max-width: 80px;
}

.ReactModal__Content .input-group .form-control {
  border: 1px solid #8e8e8e;
}

button.btn.btn-primary {
  min-width: 150px !important;
  border-radius: 10px;
  text-transform: unset;
}

.ReactModal__Content .btn-container {
  display: flex !important;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row;
  align-items: center;
}

.ReactModal__Content .auth-btn {
  color: rgb(5, 39, 120);
  background: transparent;
  padding: 17px 20px;
  text-transform: capitalize;
  border-radius: 12px;
  margin-top: 35px;
  margin-left: 25px;
}

.btn-primary:hover {
  color: #fff;
  border-color: #552278;
  background-color: #552278;
}

.btn-primary:focus {
  color: #fff;
  border-color: #552278;
  background-color: #552278;
}

.btn-primary:active {
  color: #fff;
  border-color: #552278;
  background-color: #552278;
}

.ReactModal__Content .login-popup {
  margin: 0 !important;
  border: 1px solid #ffffff;
  box-shadow: none;
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.ReactModal__Content .login-popup .login-form {
  width: 70%;
}

.ReactModal__Content .popover__title>img {
  width: 76px !important;
  cursor: pointer;
  margin-bottom: 7px !important;
}

.ReactModal__Content .d-flex.x1>h4 {
  font-size: 1.3rem !important;
}

.ReactModal__Content .nav-tabs .nav-item .nav-link.active {
  color: #552278 !important;
}

.ReactModal__Content .login-popup .btn {
  display: block !important;
  padding-top: 1.3rem !important;
  padding-bottom: 1.3rem !important;
  width: 100% !important;
}

.ReactModal__Content .login-popup .form-control {
  /* min-height: 2rem !important; */
  padding: 1rem !important;
  margin-bottom: 30px;
}

.ReactModal__Content .inquiry-scrollable-div {
  height: calc(100vh - 305px) !important;

  overflow-x: hidden;

  overflow-y: auto;
}

.ReactModal__Content .login-scrollable-div {
  height: calc(100vh - 225px) !important;

  overflow-x: hidden;

  overflow-y: auto;
}

.ReactModal__Content .popover__content {
  left: -97px;
}

.ReactModal__Content .popover__content:before {
  right: calc(50% + 70px);
}

.sign-in-pill {
  padding: 5px 10px !important;
  background-color: #552278;
  color: #ffffff !important;
  border-radius: 20px;
}

input::placeholder {
  color: #aaa !important;
}

textarea::placeholder {
  color: #aaa !important;
}

.verified-seller {
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: -16px;
  width: 100%;
  border: 1px solid white;
}

.popover__wrapper:hover .popover__content {
  width: 260px !important;
}

.popover__content:before {
  right: calc(50% - 80px) !important;
}

.ReactModal__Content .popover__content:before {
  right: calc(50% - 40px) !important;
}

.breadcrumb li:not(:last-child):after {
  margin: 0 0.5rem;
}

.breadcrumb .delimiter,
.breadcrumb .separator {
  display: none;
}

.form-control {
  display: block;
  width: 100%;
  min-height: 2.5rem;
}

.form-control {
  display: block;
  width: 100%;
  min-height: 2rem;
}

button.swal2-confirm.swal2-styled {
  background-color: #0f387a !important;
  font-size: 1.3em !important;
}

button.swal2-deny.swal2-styled {
  background-color: #ad1e23 !important;
  font-size: 1.3em !important;
}

button.swal2-cancel.swal2-styled {
  background-color: #6e7881 !important;
  font-size: 1.3em !important;
}

div.swal2-popup {
  width: 38em !important;
}

/*-----*/

.ReactModal__Content .login-popup .form-control {
  margin-bottom: 0px;
  padding: 0.75rem 0.5rem !important;
}

.ReactModal__Content .login-popup .form-group label {
  display: block;
  margin-bottom: 0;
}

.ReactModal__Content .login-scrollable-div {
  height: calc(100vh - 250px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.ReactModal__Content .login-scrollable-div.auth {
  height: calc(100vh - 250px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.ReactModal__Content .login-scrollable-div.auth.login {
  height: calc(100vh - 310px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.ReactModal__Content .title.tab-pane-title a {
  color: #676767 !important;
}

.ReactModal__Content .title.tab-pane-title a.active {
  color: #552278 !important;
}

.ReactModal__Content .title.tab-pane-title a:hover {
  opacity: 0.7 !important;
}

.ReactModal__Content .login-form {
  width: 50% !important;
}

.ReactModal__Content h3 {
  font-size: 1.5rem !important;
  margin-bottom: 1rem !important;
}

.ReactModal__Content .title.tab-pane-title a.active {
  color: #552278 !important;
  border-bottom: 3px solid #552278;
  padding-bottom: 4px;
}

.vertical-menu>li.active .megamenu,
.vertical-menu>li.active>ul,
.vertical-menu>li:hover .megamenu,
.vertical-menu>li:hover>ul {
  top: -405px;
}

.category-menu .megamenu .divider {
  margin: 0.5rem 0 1rem;
}

.category-menu .megamenu .divider {
  border: 1px solid #d3cdcd;
}

.get-otp-btn {
  padding: 0.73em 1.98em;
  width: 100%;
  text-transform: capitalize;
  border-radius: 10px;
  color: rgb(0, 39, 120);
  background-color: rgb(255, 255, 255);
}

.input-group button.quantity-plus {
  background-color: #552278 !important;
  color: #fff !important;
}

.ReactModal__Content input[type="text"].form-control,
.ReactModal__Content input[type="email"].form-control,
.ReactModal__Content input[type="number"].form-control,
.ReactModal__Content input[type="password"].form-control,
.ReactModal__Content select.form-control {
  display: block;
  font-size: 1.2rem;
  max-height: 40px !important;
  border-radius: 5px;
}

.instant-messenger {
  width: 30%;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 9999999999;
  padding: 0rem 0rem;
  border-radius: 0.5rem;
  border: 1px solid #eee;
  background-color: rgba(255, 255, 255, 1);
}

.instant-messenger .chat-compose {
  position: absolute;
  bottom: 23px;
  min-height: 50px;
  border-top: solid 1px #e6e6e6;
  width: 78%;
  padding-top: 10px;
  padding-right: 10px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 5px 5px 8px #dce2e8;
  max-width: 392px;
  /*max-height: 64px;*/
  left: 4%;
}

.instant-messenger .chat-send-btn {
  bottom: 23px;
  width: 18%;
  position: absolute;
  right: 0%;
  text-align: center;
}

.instant-messenger .chat-compose a {
  color: #8f8f8f;
}

.instant-messenger .chat-compose textarea {
  resize: none;
  border: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  width: 100%;
  font-family: intercom-font, "Helvetica Neue", "Apple Color Emoji", Helvetica,
    Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.instant-messenger #chat-message {
  background-color: transparent;
  margin-bottom: 5px;
  min-height: 40px;
  padding-top: 3px;
  padding-left: 12px;
  font-size: 17px;
}

.chat-mention {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #2595f0;
  box-shadow: 2px 3px #ddd;
  display: block;
}

.chat-mention img {
  margin-top: 12px;
}

.p-0 {
  padding: 0px;
}

.instant-messenger .mr-2 {
  margin-right: 0.5rem !important;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.instant-messenger .p-3 {
  padding: 1rem !important;
}

.instant-messenger .other-messages {
  max-width: 70%;
  border-radius: 8px;
  box-shadow: 5px 5px 8px #dce2e8;
  background-color: #fefefe;
}

.instant-messenger .other-messages .text-author {
  font-weight: bold;
}

.instant-messenger .other-messages .text-muted {
  color: #888888 !important;
}

.instant-messenger .other-messages .text-muted {
  color: #888888 !important;
}

.instant-messenger .my-chat {
  background-color: #fffae4;
  color: #5e5e5e;
  box-shadow: 5px 5px 8px #dce2e8;
}

.ReactModal__Content textarea.form-control {
  display: block;
  font-size: 1.2rem;
  max-height: 120px !important;
  border-radius: 5px;
}

.ReactModal__Content .login-popup .btn {
  display: block !important;
  width: 100% !important;
  padding: 11px !important;
  border-width: 1px;
  font-size: 1.2rem;
  border-radius: 5px;
}

label.error-label {
  color: red;
}

.red-text {
  color: red;
}

.side-by-side {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.side-by-side span {
  display: block;
  max-width: 80px;
  font-size: 14px;
  line-height: 16px !important;
}

.side-by-side .iconfont-gsol {
  font-family: "iconfont-gsol" !important;
  font-size: 40px !important;
  line-height: 40px;
  margin-right: 5px;
}

.validation-error {
  border: 1px solid red !important;
}

.grey-bg {
  background-color: #eeeeee;
}

.login-popup .btn.std {
  display: block !important;
  width: 260px !important;
  padding: 16px !important;
  border-width: 1px;
  font-size: 1.4rem;
  border-radius: 10px;
}

.ReactModal__Content

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ReactModal__Content

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ReactModal__Content .login-form.sm {
  width: 100% !important;
}

.ReactModal__Content .login-scrollable-div.standalone-login {
  height: unset !important;
  min-height: calc(100vh - 110px) !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.standalone-login .login-popup .btn.std {
  width: 100% !important;
}

button.swal2-confirm.swal2-styled {
  background-color: #0f387a !important;
  border: 2px solid #0f387a;
  font-size: 1.3em !important;
}

button.swal2-deny.swal2-styled,
button.swal2-cancel.swal2-styled {
  background-color: #ffffff !important;
  border: 2px solid #10387a !important;
  font-size: 1.3em !important;
  color: #10387a !important;
}

.drawer-category:hover {
  color: #ad1e23 !important;
}

.all-categories-li:hover {}

.all-categories-li a:hover {
  color: #ad1e23 !important;
  background-color: #eeeeee;
}

.tooltipx {
  position: relative;
  text-align: center;
  cursor: not-allowed;
}

.tooltipx::after {
  background-color: #552278;
  border-radius: 10px;
  color: #fff;
  display: none;
  padding: 10px 10px;
  position: absolute;
  text-align: center;
  z-index: 1500;
}

.tooltipx::before {
  background-color: #552278;
  content: " ";
  display: none;
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 1900;
}

.tooltipx:hover::after {
  display: block;
  width: 200px;
}

.verified.tooltipx:hover::after {
  display: block;
  width: 250px;
}

.tooltipx:hover::before {
  display: block;
}

.tooltipx.top::after {
  content: "top";
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltipx.top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltipx.bottom::after {
  content: "For invited Buyers only.";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.tooltipx.supplier.bottom::after {
  content: "For invited Suppliers only.";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.tooltipx.upgrade.bottom::after {
  content: "Contact our sales team.";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.tooltipx.messenger.bottom::after {
  content: "Login to view this page.";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.tooltipx.bottom::before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.tooltipx.right::after {
  content: "right";
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 10px));
}

.tooltipx.right::before {
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 5px), -50%) rotate(45deg);
}

.tooltipx.left::after {
  content: "Available in Pro Edition";
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 10px));
}

.self-registered.tooltipx.bottom::after {
  content: "Verified Supplier: This supplier has undergone a vetting process, which includes physical site inspection, verification of trade licenses, and verification of at least five commercial trade references.";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.inspected.tooltipx.bottom::after {
  content: "Inspected Supplier: This supplier has undergone a vetting process, which includes physical site inspection, verification of trade licenses, and verification of at least five commercial trade references.";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.top20.tooltipx.bottom::after {
  content: "Top 20%: This supplier has undergone a vetting process, which includes physical site inspection, verification of trade licenses, and verification of at least five commercial trade references.";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.top10.tooltipx.bottom::after {
  content: "Top 10%: This supplier has undergone a vetting process, which includes physical site inspection, verification of trade licenses, and verification of at least five commercial trade references.";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.top5.tooltipx.bottom::after {
  content: "Top 5%: This supplier has undergone a vetting process, which includes physical site inspection, verification of trade licenses, and verification of at least five commercial trade references.";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.tooltipx.left::before {
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
}

a {
  text-decoration: none;
  color: #552278;
  transition: color 0.3s;
}

.sign-in-pill {
  padding: 5px 10px !important;
  color: #ffffff !important;
  border-radius: 20px;
}

.sign-in-pill.buyer {
  background-color: #552278 !important;
}

.sign-in-pill.seller {
  background-color: #ad1e23 !important
}

.easp-btn {
  padding: 20px 30px;
  font-size: 24px;
  font-weight: 600;
  background-color: #552278;
  color: #ffffff;
  border-radius: 40px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
}

.easp-btn a {
  color: #ffffff;
}

.easp-btn:hover,
.sign-in-pill:hover {
  background-color: #2467d3;
  color: #ffffff;
  box-shadow: 2px 2px 18px rgba(0, 0, 0, 0.75);
}

.header-right.menu>a:hover {
  color: #bbbbbb !important;
}

@media only screen and (max-width: 640px) {
  .header-top .header-right>.has-submenu {
    margin-left: -1rem !important;
  }

  div.ReactModal__Content.ReactModal__Content--after-open {
    height: 96vh !important;
    width: 96vw !important;
  }

  div.ReactModal__Content.ReactModal__Content--after-open .popover__title>img {
    padding: 0 !important;
    margin: 0 !important;
    width: 70px !important;
    /* margin-right: 3px !important; */
    right: 4px !important;
    position: relative;
    margin-bottom: 5px !important;
  }

  .xbadge {
    background-color: #eeeeee;
    border-radius: 10px;
    font-size: 12px;
    padding: 3px 10px;
    margin: 0 0 0 10px !important;
  }

  .ReactModal__Content .d-flex.x1>h4 {
    font-size: 1.125rem !important;
  }

  .ReactModal__Content .title {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    text-align: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .ReactModal__Content h3>span {
    display: none !important;
  }

  .ReactModal__Content .title.tab-pane-title a {
    margin: 10px;
  }

  .ReactModal__Content .login-form {
    width: 100% !important;
  }

  .login-popup .btn.std {
    width: 100% !important;
  }

  .ReactModal__Content .login-popup {
    width: 100% !important;
    max-width: unset !important;
  }

  .ReactModal__Content .btn-container button.btn.btn-primary {
    min-width: 100% !important;
  }

  .gradient-top {
    background: #ffffff !important;
    border: 2px solid #e9e9e9;
  }

  .widget-icon-box .icon-box-content {
    text-align: left;
  }

  .sidebar .product-widget {
    padding: 1rem 0.75rem;
    background-color: white;
  }

  .product-single .product-qty-form input,
  .ReactModal__Content input[type="number"].form-control {
    font-size: 1.75rem !important;
  }

  .easp-col-1 {
    line-height: 1.2 !important;
    font-size: 52px !important;
    padding-top: 0px !important;
    padding-left: 40px !important;
    padding-bottom: 40px !important;
  }

  .easp-col-2 {
    line-height: 1.2 !important;
    font-size: 52px !important;
    padding-top: 0px !important;
    padding-left: 40px !important;
    padding-bottom: 40px !important;
    margin-left: 0 !important;
    color: #ffffff !important;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  }

  .easp-col-2 div {
    background-color: #ffffff !important;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  }

  .page-wrapper>.login-popup {
    margin: 16px auto !important;
    padding: 16px !important;
    width: 100% !important;
    max-width: 90vw !important;
  }

  .footer-bottom {
    padding: 1rem 0;
    color: #ccc;
  }

  h2 {
    font-size: 1.75rem;
  }

  .product-navigation {
    display: flex;
    justify-content: center;
    padding-left: 2px;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

}

footer.footer {
  position: relative;
  bottom: 0;
  display: block;
  width: 100%;
}

main {
  min-height: calc(100vh - 410px);
}

.btn-primary:hover {
  color: #fff;
  border-color: #552277;
  background-color: #552277;
}

.ReactModal__Content .login-scrollable-div.auth {
  height: calc(100vh - 115px) !important;
}

.ReactModal__Content .login-scrollable-div.auth.login {
  height: calc(100vh - 115px) !important;
}

.ReactModal__Content .login-scrollable-div.auth.login .btn.std {
  width: 100% !important;
}

.legal-doc {
  color: #333;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  top: 100%;
}

p {
  /* font-size: unset !important; */
}

.header ul.menu.nav-menu>li>a:not(.btn) {
  color: #fff !important;
}

.header ul.menu.nav-menu>li>a:not(.btn):hover {
  color: #999999 !important;
}

.header ul.menu.nav-menu .submenu li>a:not(.btn):hover {
  color: #552277 !important;
}

.header ul.menu.nav-menu .submenu li:hover {
  background-color: #5522770f !important;
}

.xcards {
  border: 1px solid rgb(245, 245, 245);
  margin: 10px 0px;
  padding: 25px;
  background-color: #ffffff;
}

.xaction-btn {
  border-radius: 10px;
  text-transform: unset;
  color: #ffffff !important;
}

.estimate-to {
  margin-top: 9px;
}

.hr-filter {
  margin-right: -25px;
  margin-left: -25px;
  opacity: 1;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  border: 1px solid #eeeeee;
  border-bottom: none;
}

.no-border {
  border: none !important;
}

.search-field {
  min-width: 56% !important;
}

.page-item.active .page-link {
  background-color: #2178ff;
}

.rfq-data-description a {
  color: #2278ff;
  text-decoration: none;
  font-weight: 600;
  margin-left: 10px;
}

.rfq-data-description a:hover {
  text-decoration: underline !important;
  text-decoration-color: #2278ff !important;
  text-decoration-thickness: 2px !important;
}

.row.rfq-card .rfq-title a {
  color: #2278ff !important;
  font-size: 24px;
  font-weight: 600;
}

.rfq-card:first-of-type {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.rfq-card:first-of-type:hover {
  background-color: transparent !important;
}

.rfq-card {
  border-bottom: 1px solid #dedede;
  margin: 0 -25px;
  padding: 20px;
}

.rfq-card:last-of-type {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.rfq-card:last-of-type:hover {
  background-color: transparent !important;
}

.rfq-card:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.rfq-card:hover .rfq-title {
  text-decoration: underline !important;
  text-decoration-color: #2278ff !important;
  text-decoration-thickness: 2px !important;
}

.rfq-card .view-more-button {
  display: none;
  background-color: #0078ff;
  color: #ffffff;
  padding: 3px 5px;
  width: 70px;
  margin: auto;
  font-weight: 500;
  border-radius: 3px;

}

.rfq-card:hover .view-more-button {
  display: inline-block !important;
}

.ant-pagination {
  display: inline-block;
  float: right;
}

.xfilter .widget .widget-title {
  position: relative;
  margin: -0.1rem 0;
  padding: 0.5rem 2.5rem 0.5rem 0;
  font-weight: 600;
  font-size: 1.6rem;
  color: #333;
  letter-spacing: 0;
  border-bottom: 1px solid #eee0;
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.xfilter .toggle-btn:after {
  content: "";
  position: absolute;
  border-top: 2px solid #666;
  width: 12px;
  transition: transform 0.3s;
  opacity: 1;
}

@media (min-width: 641px) {
  .rfq-border-left {
    border-left: 1px solid #dedede;
    margin-bottom: 0px;
  }

  .rfq-action-pane {
    display: flex;
    justify-content: space-between;
  }

  .rfq-data-subtitle {
    display: flex;
  }

  .rfq-status-pill {
    background-color: white;
    border: 1px solid #0078ff;
    border-radius: 30px;
    padding: 0px 10px;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    color: #0078ff;
    margin-left: 10px;
    margin-top: -17px;
    top: -4px;
    position: relative;
  }

  .rfq-card-wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  div.col-md-12.rfq-data-subtitle ul {
    display: flex !important;
    gap: 30px;
    padding-left: 0 !important;
  }

  div.col-md-12.rfq-data-subtitle ul li {
    list-style-type: disc !important;
  }

  div.col-md-12.rfq-data-subtitle ul li:first-of-type {
    list-style-type: none !important;
  }

  p.category-pills {
    display: flex;
  }

  p.category-pills span {
    background-color: white;
    border: 1px solid #999;
    color: #5f5f5f !important;
    border-radius: 30px;
    padding: 0px 12px;
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 500;
  }

  .rfq-action-pane {
    padding: 4px 0 7px 10px;
  }

  .budget-wrapper {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  .budget-input {
    border: 1px solid #cccccc;
    border-radius: 0;
    width: 100%;
    min-width: 50px;
    padding: 7px;
  }

  .budget-input {
    width: 100%;
    height: 40px;
    padding: 0 11px;
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    display: flex;
    border-radius: 8px;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

}

.mobile-login,
.mobile-rfq {
  display: none;
}

.hide-in-mobile {
  display: inline-block;
}

.hide-in-desktop {
  display: none;
}

div.more-options .ant-drawer-content-wrapper {
  min-height: 60vh;
}

.ant-drawer-header {
  display: flex;
  flex: 0;
  align-items: center;
  padding: 16px 0px;
  font-size: 16px;
  line-height: 1.5;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}

.ant-drawer-header-title {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
}

.mobile-menu-toggle {
  display: inline-block;
  font-size: 1.4rem;
  margin-top: -5px;
}

@media (max-width: 991.98px) {

  div.product-navigation .ant-space {
    gap: 16px;
    display: inline-flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
  }

  .d-flex-end {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: 19px !important;
  }

  .hide-in-mobile {
    display: none;
  }

  .hide-in-desktop {
    display: inline-block;
  }

  .row.rfq-card {
    margin-bottom: 20px;
    padding-bottom: 22px !important;
    border-bottom: 1px solid #b3b3b3 !important;
  }

  .row.rfq-card .rfq-title {
    color: #2278ff !important;
    font-size: 24px;
    font-weight: 800;
  }

  .rfq-action-pane {
    display: flex;
    justify-content: space-between;
  }

  .rfq-status-pill {
    background-color: white;
    border: 1px solid #0078ff;
    border-radius: 30px;
    padding: 0px 10px;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    color: #0078ff;
    margin-left: 10px;
    margin-top: -17px;
    top: -4px;
    position: relative;
  }

  .header-right.menu {
    border: 1px solid transparent;
    width: calc(100vw - 85px);
    height: 65px;
  }

  .mobile-login {
    display: inline-flex;
    float: right;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    line-height: initial;
  }

  .mobile-rfq {
    display: inline-flex;
    float: right;
    width: 45%;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    line-height: initial;

    i {
      position: absolute;
      margin-top: -8px;
      font-size: 30px !important;
    }

    span {
      margin-top: 0px;
      position: relative;
      width: 100%;
      display: block;
      margin-left: 40px;
    }
  }

  .hamburger-menu {
    svg {
      width: 26px;
      height: 26px;
      color: #ffffff;
      margin-left: -20px;
    }
  }

  .w-icon-grid:before {
    content: "";
    font-size: 20px;
    margin-top: -13px;
    position: absolute;
  }

  .mobile-menu-toggle {

    line-height: unset !important;
    height: unset !important;

    span {
      margin-left: 36px !important;
      font-size: 16px !important;
      margin-top: -25px !important;
      display: block !important;
      position: initial !important;
    }

  }

}

.menu>li>ul.submenu {
  left: -8.5rem;
  margin-top: 0px;
  border-radius: 5px;
}

.menu>li>ul.submenu.home {
  margin-top: -15px;
}

.menu>li>ul.submenu>li>a {
  font-size: 14px !important;
}

.menu>li>ul.submenu>li>a>i {
  font-size: 14px !important;
  margin-right: 20px !important;
}


div.container.pt-2>div.row>div.col-xl-6.col-lg-6.col-md-6.col-sm-6>div {
  background-color: #ffffff !important;
  border-radius: 12px !important;
}

.title-link-wrapper.mb-3 h2.title.mb-0.pt-2.pb-2 {
  padding-top: 0 !important;
}

.product.product-single.row.bg-white.pr-3.pl-3.pt-3.pb-3,
.product-tabs .tab-content,
.sidebar-content,
.xcards {
  border-radius: 10px !important;
}

.product-tabs .tab-content {
  border-radius: 10px !important;
  border-top-left-radius: 0 !important;
}

.product-media.transparent-border-color {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.product-details.pb-4.pr-2.pl-2 {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.product-wrapper-1.appear-animatex.mb-1 {
  background-color: #ffffff;
  margin: -15px -20px 0;
  border: none;
  padding: 0;
  padding-left: 20px;
  box-shadow: 1px 1px 10px #00000033;
}

.product-wrapper-1.appear-animatex.mb-1 a {
  padding: 11px;
  margin-top: 5px;
  margin-right: 20px;
  font-size: 16px !important;
}

.xcards {
  box-shadow: 1px 1px 10px #00000033;
}

.xfilter .widget .widget-title {
  font-size: 1.3rem;
}

.card-with-title {
  border: 1px solid rgb(245, 245, 245);
  margin: 10px 0px;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #00000033;
}

input.inquiry-search {
  border: 1px solid #bbb !important;
  color: rgb(65, 63, 63);
  border-radius: 5px !important;
}

.list-view-document-item {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  padding: 10px !important;
  margin-bottom: 12px;
}

.list-view-document-item h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-view-document-item:hover {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  box-shadow: 1px 1px 10px #00000033;
  cursor: pointer;
}

.list-view-document-item.active {
  border: 1px solid #552278 !important;
  background-color: #552277;
}

.list-view-document-item.seller.active {
  border: 1px solid #ad1e23 !important;
  background-color: #ad1e23;
}

.list-view-document-item.active h2 {
  color: #ffffff;
}

.list-view-document-item.active span {
  color: #ffffff;
  padding-right: 0px;
}

.rfq-list-container.mt-3 {
  height: 543px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: calc(100% + 50px) !important;
  padding-right: 10px;
  margin-left: -25px;
  padding-left: 25px;
  width: 100%;
}

.rfq-list-container.mt-4 {
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}

// .rfq-list-container::-webkit-scrollbar {
//   margin-right: -10px;
// }

// .rfq-list-container::-webkit-scrollbar-thumb {
//   background: rgba(90, 90, 90, 0.2);
//   border-radius: 10px;
//   width: 4px;
//   margin-left: 10px;
// }

// .rfq-list-container::-webkit-scrollbar-track {
//   background: rgba(90, 90, 90, 0.01);
//   display: none;
// }

.no-responses-yet {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.chat-list-user-item .display-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.chat-list-user-item .message-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-list-user-item .time-ago {
  color: #cccccc;
  font-weight: 600;
}

.chat-list-user-item.active .time-ago {
  color: #000000;
  ;
}

.center-everything {
  text-align: center
}


.center-everything img {
  margin: auto;
}

.badge-counter {
  background-color: #3bc16f;
  color: #ffffff;
  height: 30px;
  width: 30px;
  float: right;
  margin-right: 10px;
  text-align: center;
  padding-top: 5px;
  border-radius: 100px;
}


.list-view-wrapper.chats {
  height: 598px;
}

.document-details-wrapper {
  height: auto;
}

.document-details-wrapper {
  height: 180px !important;
  overflow-x: hidden;
  overflow-y: scroll;
  width: calc(100% + 25px);
  padding-right: 10px;
}

.attachment-list {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.attachment-list-item {
  border: 1px solid #ccc;
  color: #333;
  border-radius: 5px;
  padding: 0px 5px;
  width: 94%;
}

.scroll-y {
  overflow-x: hidden;
  overflow-y: scroll;
}

.doc-wrapper {
  display: flex;
  width: 100%;
  height: auto;
  border: none;
  background-position: center center;
  background-size: 120%;
  background-repeat: no-repeat;
  min-height: 52px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.doc-wrapper span {
  margin-top: 4px;
}

.rfq-search-container input.inquiry-search {
  border: 1px solid #bbb !important;
  color: rgb(65, 63, 63);
  padding: 5px 10px;
  border-radius: 5px !important;
  font-size: 16px;
}

hr.mt-3.mb-3 {
  margin-top: 1.0rem !important;
  margin-top: 1.0rem !important;
}

.chat-list-user-item {
  cursor: pointer;
  background-color: transparent;
  margin-right: -25px;
  margin-left: -25px;
  padding: 12px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chat-list-user-item:hover,
.chat-list-user-item.active {
  background-color: rgba(0, 0, 0, 0.05);
}


.list-view .card-with-title,
.card-with-shadow {
  background-image: url('/assets/images/shadow.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 119px;
}

.message-text.muted-text {
  opacity: 0.5 !important;
}

.float-right {
  float: right !important;
}

.row.scroll-chats {
  height: 477px;
  max-height: 477px;
  border: none;
  background-color: rgba(128, 128, 128, 0.035);
  margin-right: -25px;
  margin-left: -25px;
  margin-bottom: 65px;
  overflow: auto;
}

.row.scroll-chats>.col {
  padding-left: 28px;
  padding-right: 0;
}

.attach-modal {
  text-align: center !important;
}

.ant-col.ant-col-8.pt-2.pb-2:hover {
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.09);
  border-radius: 10px;
}

.text-input {
  /* border: 1px solid green; */
}

.btn-input {
  /* border: 1px solid blue; */
}

.send-btn {
  width: 100%;
  max-width: 45px;
}

.messenger-text-input {
  border: 2px solid #dfdfdf;
  background-color: rgba(148, 148, 148, 0.15);
  border-radius: 10px;
  padding: 10px 20px;
}

.messenger-text-shadow {
  transform: rotate(180deg);
  width: 100%;
  /* position: absolute;
  border: none;
  bottom: 105px;
  margin-left: -20px;
  margin-right: -25px; */
  margin-bottom: 20px !important
}

.messenger-text-wrapper {
  position: absolute;
  bottom: 35px;
  left: 30px;
  right: 30px;
  display: block;
}

.btns-wrapper {
  padding: 0 20px;
}

.speech-bubble {
  background-color: #efefef;
  border-radius: 10px;
  padding: 10px 10px;
  max-width: 100% !important;
  display: inline-block;
}

.chat-bubble-item {
  margin: 10px auto;
}

.chat-bubble-item.out-bound .speech-bubble {
  float: right;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #d0d0d0;
}

.chat-bubble-item.in-bound .speech-bubble {
  float: left;
  margin-left: 15px;
  margin-right: 10px;
}

.time-ago {
  display: block;
  opacity: 0.59;
}

.chat-bubble-item {
  margin: 30px auto;
}

.chat-bubble-item.out-bound .time-ago {
  text-align: right;
  padding-right: 60px;
}

.chat-bubble-item.in-bound .time-ago {
  text-align: left;
  padding-left: 60px;
}

.chat-attachment {
  width: 100%;
  border: 2px solid transparent;
  cursor: pointer;
}

.chat-attachment:hover {
  border: 2px solid #e7e7e7;
}

div.card.shadow-sm.mb-5 {
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px 20px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.25);
}


a.sz-btn-primary {
  display: block !important;
  width: 80% !important;
  padding: 3px 10px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  border: 2px solid #552278 !important;
  text-align: center !important;
}

a.sz-btn-primary:hover {
  opacity: 0.9;
}

a.sz-btn-primary.solid {
  background-color: #552278 !important;
  color: #ffffff !important;
}

a.sz-btn-primary.outline {
  background-color: transparent !important;
  color: #552278 !important;
}

a.sz-btn-primary.w-150 {
  width: 150px !important;
}

.rfq-search-container i.w-icon-search {
  font-size: 15px;
  position: relative;
  top: -25px;
  left: -10px;
  float: right;
  color: #7e7e7e;
}

.composer-btn-wrapper {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
}

.compact-col .col-md-2 {
  padding: 0 3px !important;
}

.compact-col .col-md-3 {
  padding: 0 3px !important;
}

.compact-col .col-md-4 {
  padding: 0 3px !important;
}

.compact-col .col-md-12 {
  padding: 0 3px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rfq-details-wrapper hr {
  margin: 0.5rem 0 !important;
}

.rfq-details-wrapper .row.mb-1.compact-col {
  margin: -10px -3px 0px -3px !important;
}

.anticon.anticon-paper-clip {
  width: 28px !important;
  height: 28px !important;
}

.attachment-wrapper {
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: scroll;
  /* border: 1px solid red; */
  padding-right: 10px;
}

.one-liner-text {
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  line-height: normal;
}

.one-liner-text strong {
  display: block !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rfq-description-wrapper {
  overflow: scroll;
  overflow-x: hidden;
  height: 175px !important;
  min-height: 175px !important;
  max-height: 175px !important;
}

.header-middle {
  display: block !important;
}

.white-bg-rounded {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 16px;
}

.nav-link.text-active-primary.me-6.false,
.nav-link.text-active-primary.me-6.active {
  font-size: 14px !important;
}

.card.mb-1.mb-xl-10.white-bg-rounded {
  padding-bottom: 0;
}

.error-text-validation {
  color: red;
  font-size: 12px;
  display: block;
  margin-top: -20px;
}

.form-control.form-control-solid.error {
  border: 2px solid #F44336 !important;
  background-color: #fff9f9 !important;
}

.StepperContainer-0-2-1 {
  padding-top: 20px !important;
}

#kt_account_profile_details .form-control {
  font-family: inherit !important;
}

.profile-wizard div#RFS-StepMain button {
  background-color: #fff !important;
  border: 3px solid #552278 !important;
  font-size: 2rem !important;
  border-radius: 100%;
}

element.style {
  padding: 0.73em 1.98em;
  width: 100%;
  text-transform: capitalize;
  border-radius: 10px;
  color: rgb(0, 39, 120);
  background-color: rgb(255, 255, 255);
  border: 2px solid #194c9e;
}


.wolmart {

  .btn.btn-sm.btn-primary.me-4.w-100,
  .btn-outline-primary {
    margin-bottom: 10px !important;
    text-transform: capitalize !important;
    padding: 10px !important;
    border-radius: 10px !important;
    font-size: 1.2rem;
  }

}

.header-search.hs-round .btn-search {
  border-radius: 0 0.3rem 0.3rem 0;
  background-color: #ad1e23;
  border: 2px solid #ad1e23 !important;
}

.header-search.hs-round .btn-search i {
  color: #ffffff !important;
}

.header-search.hs-round .btn-search:hover {
  border-radius: 0 0.3rem 0.3rem 0;
  background-color: #f52128;
  border: 2px solid #f52128;
}

.border-bottom {
  border-bottom: 1px solid #eff2f5;
}

.fv-plugins-message-container {
  margin-top: 25px;
}

.profile-wizard .align-items-start {
  align-items: flex-start;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 12px;
}

.product-wrapper-1.appear-animatex.mb-1 {
  background-color: #ffffff;
  margin: -15px -30px;
  border: none;
  padding: 0;
  padding-left: 20px;
  box-shadow: 1px 1px 10px #00000033;
}

.jWkLDY {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}

.doc-card {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 20px;
}

/*
.ant-col-8 {
  margin-top: 10px;
  margin-bottom: 10px;
}
*/


#webpack-dev-server-client-overlay-div {
  display: none !important;
}

.download-specs {
  text-align: left;
  width: 80%;
  margin: auto;
  border-top: 1px dashed;
  margin-top: 20px;
  padding-top: 20px;
}


#kt_account_profile_details>form {
  padding: 30px !important;
  padding-top: 0 !important;
}

.card .card-body,
.card .card-footer {
  padding: 2rem 2.25rem;
}

form>.card .card-body,
form>.card .card-footer {
  padding: 2rem 0rem !important;
}

.zoom-animation {
  transition: transform .3s;
  /* Animation */
}

.zoom-animation:hover {
  transform: scale(1.055);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.product-tabs .nav-item:hover .nav-link.blue {
  color: #ababab !important;
}

#product-tab-vendor .ant-row {
  margin-bottom: 10px !important;
}

.register-modal {
  width: 520px !important;

  .ant-col {
    padding-left: 5px;
    padding-right: 5px;
  }


  label.error-label {
    color: red;
    margin-top: 0;
    margin-left: 10px;
    display: block;
    position: absolute;
  }

}

.mt-30px {
  margin-top: 30px !important;
}

.ant-modal-root {
  z-index: 10000 !important;
  display: block;
  position: absolute;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 10001 !important;
}

.antx-select {
  width: 100%;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  // margin-top: 30px;
}

.register-modal .ant-modal-body {
  max-height: calc(100vh - 200px) !important;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 1px solid #fcfcfc;
}

.register-modal .i-agree {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.antdx-login-modal {
  width: 420px !important;
}

.antdx-register-modal {
  [class^="ant-col ant-col-"] {
    padding: 0 10px;
  }
}


.header-top {
  background-image: url('/assets/bg/header.png') !important;
  background-position-x: right !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.footer-dark a {
  color: #000 !important;
}

.thread-title-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
}

.chat-btn-close {
  position: absolute;
  float: right;
  right: -16px;
}

.social-media-btns {
  position: absolute;
  border: 1px solid transparent;
  top: 16px;
  right: 13px;
  display: inline-block;
}

.sticky-link p {
  margin-top: 0.9rem !important;
  margin-bottom: 0 !important;
  font-size: 1rem !important;
  line-height: 1;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-weight: 600;
  color: #000000 !important;
}

.cart-dropdown .sticky-link i,
.sticky-link i {
  font-size: 1.25rem;
  color: #666;
}

.both-ends {
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;
  width: 100% !important;
}

.card-action-btns {
  display: inline-block;
}

.ant-drawer-body {
  padding: 0 !important;
}

.xcards .xcards {
  box-shadow: none !important;
  padding: 5px !important;
  border: none !important;
  /* 1px solid #e5e5e5; */
}

.rounded {
  border-radius: 10px !important;
}

.product-single-pb-0 .product-details {
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 640px) {

  .xcards {
    border: 1px solid whitesmoke !important;
    margin: 10px 0px !important;
    padding: 25px !important;
    background-color: #ffffff !important;
    border-radius: 10px !important;
    box-shadow: 1px 1px 10px #00000033 !important;
  }

  .search-card-wrapper {
    padding-left: 0px;
  }


  .hide-in-mobile {
    display: none;
  }

}

@media only screen and (max-width: 1367px) {

  .xcards {
    border: 1px solid whitesmoke !important;
    margin: 10px 0px !important;
    /* padding: 25px !important; */
    background-color: #ffffff !important;
    border-radius: 10px !important;
    box-shadow: 1px 1px 10px #00000033 !important;
  }

  .product.product-widget.widget.widget-icon-box.mb-3.gradient-top.px-3 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-bottom: 10px !important;
  }

  .row.gutter-md.m-20-0.mt-0 {
    margin-bottom: 5px;
  }

  .ReactModal__Content textarea.form-control {
    display: block;
    font-size: 1.2rem;
    height: 70px !important;
    min-height: unset !important;
    max-height: unset !important;
    border-radius: 5px;
  }

  .ReactModal__Content .inquiry-scrollable-div {
    height: calc(100vh - 280px) !important;
    overflow-x: hidden;
    overflow-y: auto;
  }

}

.submenu.home {

  .ant-badge {
    width: 100%;

    sup {
      right: 10px !important;
      top: 5px !important;
    }
  }

}

.xcards {

  .ant-card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .ant-divider-horizontal {
    margin: 5px 0;
  }

}


@media (min-width: 992px) {

  div.container {

    .product-wrapper-1.w-100 {
      margin: -16px 0 !important;
      position: absolute !important;
      left: 0 !important;
      right: 0 !important;
      width: 100vw !important;
    }

    .title-link-wrapper.pb-1.mb-1.d-flex-end {
      border-bottom: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    .row {
      //margin-top: 42px !important;
    }

  }

  .xcards {

    .ant-card-body {
      padding: 10px 16px !important;
    }

    .ant-image-img {
      object-fit: cover !important;
      width: 100% !important;
      height: 320px !important;
    }

  }

}

.load-more-button {
  margin: 30px auto !important;
  width: 200px !important;
  display: block !important;
}

.rfq-details-modal.desktop {
  width: 1200px !important;
}

.rfq.xfilter .widget .widget-title {
  position: relative;
  margin: -0.1rem -26px;
  padding: 0.5rem 2.5rem 0.5rem 23px;
  font-weight: 600;
  font-size: 1.2rem;
  color: #333;
  letter-spacing: 0;
  border-bottom: 1px solid rgba(238, 238, 238, 0);
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #e7e7e7;



}

.rfq {
  .hr-filter {
    opacity: 0;
    margin: 0 !important;
    margin-bottom: 15px !important;
  }
}

.inquiry-composer {
  width: 700px !important;
}

.header-search.hs-expanded .select-box,
.header-search input.form-control,
.header-search .btn-search {
  border-color: #ffffff !important;
}

.header-search.hs-round .btn-search {
  border-radius: 0 0.3rem 0.3rem 0;
  background-color: #ac1f23;
  border: 2px solid #ac1f23 !important;
}

.follow-seller-btn {
  display: inline-block;
  position: absolute;
  right: 20px;
}

.like-btn {
  display: inline-block;
  position: absolute;
  right: -10px;
  top: 5px;
}

.ant-select-dropdown {
  z-index: 12345 !important;
}

.logo-holder {
  background: #f0f0f0;
  border-radius: 10px;
  padding: 16px;
}

.no-btn-effect:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.rfq.title-link-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 1.3rem;
  justify-content: space-between;
  padding: 0px 30px 0 10px;
}

.buyer.ant-radio-group-solid .ant-radio-button-wrapper-checked {
  color: #fff;
  background: #552278 !important;
  border-color: #552278 !important;
}

buyer.ant-radio-group-solid .ant-radio-button-wrapper:hover {
  position: relative;
  color: #552278 !important;
}

.seller.ant-radio-group-solid .ant-radio-button-wrapper-checked {
  color: #fff;
  background: #ad1f23 !important;
  border-color: #ad1f23 !important;
}

.seller.ant-radio-group-solid .ant-radio-button-wrapper:hover {
  position: relative;
  color: #ad1f23 !important;
}

.ant-radio-group-solid :where(.css-dev-only-do-not-override-qgg3xn).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #552278;
  border-color: #552278;
}

.rfq-modal-container {
  display: flex !important;
  flex-flow: row wrap !important;
  min-width: 0 !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-content: center !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;

  overflow-y: scroll;
  max-height: 72vh;
  border: 1px solid transparent;
  padding: 0px;


}

.rfq-details-modal.desktop {

  max-height: 95vh !important;

  .ant-modal-body .rfq-modal-container {
    padding-top: 20px !important;

    &:first-child {
      padding-right: 20px !important;
    }

    .ant-card {

      padding: 10px 0;

      .ant-card-head {
        min-height: 32px;
        padding: 5px 20px !important;
      }


    }

  }

  .ant-modal-footer {
    margin-top: 0px !important;
  }

}

.ant-dropdown-open {

  div {
    z-index: 94721 !important;
  }

  &:first-child {
    z-index: 94721 !important;
  }

}

.ant-btn-primary,
.btn-primary-sz {
  color: #fff !important;
  border-color: #552278 !important;
  background-color: #552278 !important;
  border-width: 2px !important;
  font-weight: 500;
}

.ant-btn-default,
.btn-default-sz {
  color: #552278 !important;
  border-color: #552278 !important;
  background-color: #fff !important;
  border-width: 2px !important;
  font-weight: 500;
}

@media only screen and (max-width: 1367px) {
  .ant-card-body.xcards {
    padding: 0px !important;
  }
}

.ant-dropdown {
  z-index: 90120 !important;
}


.heart {
  width: 100px;
  height: 100px;
  background: url(https://cssanimation.rocks/images/posts/steps/heart.png) no-repeat;
  background-position: 0 0;
  cursor: pointer;
  transition: background-position 1s steps(28);
  transition-duration: 0s;
  display: inline-flex;
  position: absolute;
  border: 1px solid transparent;
  margin: -40px 0 0 -44px;
  transform: scale(0.7);

  &.is-active {
    transition-duration: 1s;
    background-position: -2800px 0;
  }
}

html {
  background-color: #f4f4f4;

  background-position: center bottom;
  background-repeat: repeat-x;
}

#root,
body,
footer,
#main {
  background-color: transparent !important;
}


footer {
  padding: 9rem 0;
  background-position: center bottom !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-color: transparent !important;
  background-image: url("http://dev.supplyzone.ai/assets/bg/footer-elements.png") !important;

  .footer-dark a {
    color: #000 !important;
  }

}

.ant-select-selection-search-input.form-control.bg-white {
  background-color: transparent !important;
  border-color: transparent !important;
  height: unset !important;
  max-height: 48px !important;
}

.header-search {
  margin-left: 135px !important;
  background-color: white !important;
  border-radius: 10px !important;
}


.follow-seller-btn {
  display: inline-flex !important;
  position: absolute !important;
  top: 63px !important;
  right: 10px !important;

  .ant-btn-icon {
    margin-top: 3px;
  }


}

.ant-btn-sm {
  border-width: 1px !important;
}

html {
  min-width: 100vw !important;
  min-height: 100vh !important;
}



.spinner-container {
  display: block;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 12345;
}

.spinner-overlay {
  display: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffed;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.spinner::after {
  content: '';
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  border-radius: 50%;
}

.spinner.smooth::after {
  border-top: 4px solid rgb(18 40 158);
  border-left: 4px solid rgb(18 40 158);
  border-right: 4px solid rgb(255, 255, 255);
  animation: spinner 0.6s linear infinite;
  z-index: 4;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.submenu.home .ant-badge sup {
  margin-top: 2px;
  width: 40px;
  font-size: 15px;
  height: 17px;
  background: #552278;
  padding-top: 1.9px;
}

a:hover :where(.css-dev-only-do-not-override-1adbn6x).ant-badge .ant-badge-count {
  background: #552278 !important;
}

footer {
  padding: 3rem 0 9rem 0 !important;
}

.ant-progress .ant-progress-inner {
  background-color: rgb(255, 255, 255);
  border-radius: 32px !important;
}

.ant-progress .ant-progress-bg {
  background-color: #1d5c06;
  border-radius: 0px;
}

.points-container {

  border-radius: 50%;
  width: 450px;
  height: 450px;
  border: 20px solid rgb(29, 92, 6, 0.08);
  margin: 40px auto;
  text-align: center;
  color: #1d5c06;
  padding-bottom: 50px;

  .ant-statistic-content {
    font-size: 125px;
    color: #005508;
    font-weight: 600;
  }

  .ant-statistic-title {
    margin-top: 80px;
    font-size: 36px;
    color: #1d5c06;
    font-weight: 600;
  }

}

.level-container {

  margin-top: 40px;
  margin-bottom: 40px;

  text-align: center;

  .points-left {
    display: block;
    position: relative;
    margin-top: -145px !important;
    top: -30px !important;
    font-size: 28px;
    text-align: center;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  }

  .level-disc {
    display: block;
    position: relative;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    border: 10px solid #ffffff;
    background: rgb(158, 208, 69);
    background: radial-gradient(circle, rgba(158, 208, 69, 1) 0%, rgba(89, 191, 25, 1) 100%);
    margin: 20px auto;
    text-align: center;
    top: -120px;
    left: -280px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  }

  .ant-progress-line {
    &>div {
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
      border-radius: 32px !important;
      margin-bottom: 24px !important;
    }
  }

  span {
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    display: block;
    clear: both;
    margin-top: 10px;
  }

  h5 {
    text-align: center;
    color: #ffffff;
    font-size: 48px;
    font-weight: 400;
    margin-top: -5px;
  }


}

h6.text-muted {
  color: #999999;
}

.txn-icon {
  min-width: 40px !important;
  width: 72px !important;
  height: 72px !important;
  margin-right: 10px !important;

  .anticon {
    font-size: 48px !important;
  }

}


@media (max-width: 750px) {

  .xcards {
    padding: 25px 18px !important;

    h6.mb-2 {
      margin-bottom: 0.5rem !important;
    }

  }

  .points-container {

    border-radius: 50%;
    width: 300px;
    height: 300px;
    border: 15px solid #1d5c06;
    margin: 40px auto;
    text-align: center;
    color: #1d5c06;
    padding-bottom: 50px;

    .ant-statistic-content {
      font-size: 79px;
      color: #005508;
      font-weight: 700;
    }

    .ant-statistic-title {
      margin-top: 30px;
      font-size: 28px;
      color: #1d5c06;
      font-weight: 600;
    }

  }

  .level-container {

    margin-top: 40px;
    margin-bottom: 20px;

    text-align: center;

    .ant-progress-outer {
      width: 335px !important;
      height: 24px;
    }

    .points-left {
      display: block;
      position: relative;
      margin-top: -145px !important;
      top: -8px !important;
      font-size: 20px;
      text-align: center;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
      margin-left: 45px;
    }

    .level-disc {
      display: block;
      position: relative;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      border: 10px solid #ffffff;
      background: rgb(158, 208, 69);
      background: radial-gradient(circle, rgba(158, 208, 69, 1) 0%, rgba(89, 191, 25, 1) 100%);
      margin: 20px auto;
      text-align: center;
      top: -100px;
      left: -119px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    }

    .ant-progress-line {

      margin-bottom: 22px !important;

      div {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
      }
    }

    span {
      text-align: center;
      color: #ffffff;
      font-size: 15px;
      display: block;
      clear: both;
      margin-top: 5px;
    }

    h5 {
      text-align: center;
      color: #ffffff;
      font-size: 48px;
      font-weight: 400;
      margin-top: -5px;
    }


  }

  .txn-icon {
    min-width: 40px !important;
    width: 48px !important;
    height: 48px !important;
    margin-right: 5px !important;

    .anticon {
      font-size: 32px !important;
    }

    /* Example: Set the font size to 14px for screens smaller than 750px */
  }

}

.drama-modal {

  .ant-modal-body {
    height: 250px;
  }

  .ant-modal-footer {
    margin-top: -36px;
    font-weight: 600;
  }

}

.status-text {
  font-size: 24px;
  font-weight: 600;
  margin: 20px auto;
  display: block;
  text-align: center;
}

.drama-svg {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
}

div.h2-vendors {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: -webkit-fill-available;
  height: 250px;
  width: -webkit-fill-available;

  h2 {
    font-size: 32px;
    font-weight: 600;
  }
}

#supplier-counter {
  transition: all 0.5s ease;
}

@keyframes cursorBlink {
  0% {
    border-right-color: initial;
  }

  to {
    border-right-color: transparent;
  }
}

.bot-response {
  animation: cursorBlink 0.6s ease-in-out infinite alternate;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-btn-primary:disabled {
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.dot {
  position: relative;
  width: fit-content;
  background: linear-gradient(135deg, #7932f6, #805ad5 48%, #342662);
  border-radius: 40px;
  width: 1.75rem;
  height: 1.75rem;
}

.heartbeat {
  position: absolute;
  width: fit-content;
  background-color: #7932f6;
  border-radius: 40px;
  width: 1.75rem;
  height: 1.75rem;
  opacity: 0.75;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {

  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.intro-bar {
  background-image: url('./assets/images/bas/intro-bar2.svg');
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ffffff;
  padding: 5px;
}

.card-icon-ellipsis {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content>a {
  color: inherit;
  transition: all 0.2s;
  font-size: 16px !important;
  padding: 16px 10px !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon {
  min-width: 14px;
  margin-inline-end: 8px;
  font-size: 20px;
}

.folder-badge {
  display: inline-block;
  position: absolute;
  right: 10px;
}

.ant-dropdown {
  min-width: 270px !important;
}

li:has(> span.ant-dropdown-menu-title-content .pd-none) {
  display: none !important;
}

.btn-fl-r--10 {
  display: inline-block;
  position: absolute;
  right: 0;
  top: -14px;
}

span.copyright>div {
  text-align: center;
  display: block;
  width: 100%;
  padding-bottom: 250px;
}

.solid-tags {
  border: none;
  background-color: #dedede;
}

.solid-tags:hover {
  border: none;
  background-color: #e8c8ff;
  cursor: pointer;
}

.product-single .product-qty-form input {
  min-width: 177px !important;
  border-radius: 10px;
  text-transform: unset;
}

.btn-product {
  padding: 0.5em 0;
  background-color: #552278;
  border-radius: 10px;
  text-transform: capitalize;
}

.btn-smaller {
  color: #542278 !important;
  font-size: 16px !important;
}

.thumb-image-container {
  width: 150px;
  height: 120px;
  border: 1px solid #eeeeee;
  padding: 10px;
  margin-right: 16px;
  margin-left: 6px;
}

.thumb-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-32 {
  font-size: 32px !important;
}

button.swal2-confirm.swal2-styled {
  background-color: #5c2d63 !important;
  border: 2px solid #5c2d63;
  font-size: 1.3em !important;
}

button.swal2-deny.swal2-styled,
button.swal2-cancel.swal2-styled {
  background-color: #ffffff !important;
  border: 2px solid #5c2d63 !important;
  font-size: 1.3em !important;
  color: #5c2d63 !important;
}

.swal2-styled:hover {
  background-image: linear-gradient(rgb(252 238 255), rgb(251 228 255));
}

.listview-meta {
  display: flex;
  position: absolute;
  bottom: 30px;
  right: 0px;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.listview-attachment {
  display: flex;
}

.listview-new {
  border-radius: 5px;
  padding: 1px 3px;
  color: #ffffff;
  background-color: #5c2d63;
  font-size: 10px;
  font-weight: 500;
  margin-right: 7px;
}

.time-ago {
  display: block;
  opacity: 0.59;
  min-width: 100px;
  text-align: right !important;
}

.text-right.time-ago {
  display: block;
  opacity: 0.59;
  min-width: 100px;
  text-align: right !important;
  font-size: 13px;
  padding-right: 16px;
}

.time-ago.bubble {
  display: block;
  opacity: 0.59;
  min-width: 100px;
  text-align: right !important;
  padding-right: 60px;
  padding-left: 60px;
  margin-top: -30px;
}

.doc-listview-aging {
  position: absolute;
  bottom: 11px;
  right: 7px;
  font-weight: 400;
  font-size: 12px;
}

.badge-counter {
  background-color: #542277;
}

.ant-select-dropdown .ant-select-item-option {
  display: flex;
  border-top: 1px solid #d6d6d6;
  border-radius: 0;
  padding: 16px;
}

.ant-select-dropdown {
  z-index: 12345 !important;
  margin-top: -40px !important;
  border-radius: 0 0 26px 26px;
}

.wolmart .ant-select-selector,
.ant-select-selector-focused .ant-select-selector {
  border-radius: 26px 26px 0 0 !important;

  box-sizing: border-box;
  margin: 0;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  outline: white !important;
  border: white;
  height: 60px !important;
}

.ZB3Ebc input {
  border-color: #1677ff;
  box-shadow: none;
}

.rfq {
  margin: 0;
  font-family: "Google Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif sans-serif !important;
  /*-webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  font-weight: 400;
  // font-size: 1.1rem;
}

.ant-badge {
  color: #666666;
}

.text-white .ant-badge {
  color: #ffffff;
}

.product-wrapper-1.appear-animatex.mb-1 {
  background-color: #ffffff;
  margin: -12px 0 0 0;
  border: none;
  padding: 0;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  width: 100vw;
  left: 0;
  display: block;
  position: absolute;
}

.points-container .ant-statistic {
  position: absolute;
  display: inline-block;
  margin: auto;
  left: calc(50% - 145px);
  width: 290px;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #ffffff;
  border-color: #542278;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-pagination .ant-pagination-item-active a {
  color: #542278;
}

@media only screen and (min-width: 750px) {

  .m-ml-5 {
    margin-left: 20px !important;
  }

  .swiper-slide.product.product-simple.text-center.xcards {
    flex-shrink: 0;
    width: 85%;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
    margin-bottom: -4px !important;
  }

  .rfq-design-icon {
    width: 56px !important;
    margin-top: -3px;
  }

}

.ZB3Ebc .ZAGvjd {
  background: transparent;
}

@media only screen and (max-width: 750px) {

  .Npx9Xe {
    max-width: 65vw !important;
  }

  .CrPloe {
    max-width: 45vw !important;
  }

  .lzO0Ed {
    margin-right: 0px !important;
    ;
  }

  .rfq .xaction-btn {
    margin-top: 195px;
    z-index: 12;
    display: block !important;
    position: relative;
    margin-left: 36px;
    margin-right: 36px;
  }

  .rfq div.row.xform {
    margin-top: 76px;
  }

  .sticky-content-wrapper {
    height: 58px;
    position: absolute;
    width: 100vw;
    z-index: 25;
    border: 1px solid transparent;
    display: block;
    margin-top: calc(100vh - 220px);
    box-shadow: 0px 0px 16px #00000033;
    background-color: #ffffff;
  }

  .mod-rfq .rfq-info.data-v-8070b792 {
    display: none !important;
  }

  .title-link-wrapper.pb-2.mb-1 {
    display: block;
    position: absolute;
    top: -53px;
    text-align: center;
    width: 100%;
    border: 1px solid transparent;
    right: 0px;
    color: #ffffff !important;
    height: 52px;
  }

  .title-link-wrapper.pb-2.mb-1:after {
    display: none;
  }

  .title-link-wrapper .title.ls-normal.mb-0 {
    font-size: 19px !important;
    font-weight: 600 !important;
    color: #ffffff;
    margin: 0;
    line-height: 52px;
    padding: 0;
    margin-top: 5px;
  }

  .home-cats {
    margin-top: 4px !important;
  }

  div.product-wrapper-1.mb-1 {
    margin-top: 4px;
  }

  .rfq-design-icon {
    right: 16px;
    position: absolute;
    width: 32px !important;
    margin-top: -16px;
  }

  a.text-white.text-capitalize>span {
    font-size: 24px !important;
    position: relative !important;
    top: -6px !important;
  }

  span.anticon.anticon-appstore>svg {
    height: 28px !important;
    width: 28px !important;
    border: 1px solid transparent;
    margin-top: 20px;
    position: absolute;
    left: -32px;
    display: none !important;
  }

  .main.home :is(body) .search-card-wrapper {
    display: inline-block !important;
    width: calc(100vw - 15px) !important;
    position: absolute !important;
    right: 20px !important;
    margin-bottom: -112px !important;
  }

  .main.home :is(body) .header-middle {
    background: #3a3a3a !important;
    color: #ffffff !important;
    min-height: 85px;
  }

  .search-card-wrapper {
    display: inline-block !important;
    width: calc(100vw - 15px) !important;
    position: absolute !important;
    right: 20px !important;
    top: 80px !important;
  }

  .banner.h-100.br-sm.xcards {
    background-size: cover !important;
  }

  .swiper-slide.product.product-simple.text-center {
    margin-bottom: 20px !important;
    max-width: 37vw !important;
    width: 100% !important;
    margin: 10px 10px 10px 3px !important;
  }

  .points-container {
    border: 15px solid #1d5c0617 !important;
  }

  .level-container .level-disc {
    top: -130px !important;
  }

  .level-container .points-left {
    display: block;
    position: relative;
    margin-top: -145px !important;
    top: 8px !important;
    font-size: 19px;
    text-align: center;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    margin-left: 0;
    border: 1px solid transparent;
  }

  .rfq .gray-rounded>div {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .rfq .gray-rounded>div .row .col-4>div {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .rfq .card.card-custom .search-card-wrapper {
    display: inline-block !important;
    width: 96% !important;
    position: absolute !important;
    left: 7px !important;
    /* margin-bottom: 0 !important; */
    top: -15px;
    border: 1px solid transparent;
    /* margin-right: 23px !important; */


    .AyKEed {
      left: 0 !important;
      top: -98px;
    }

    .card>.collapse.show {
      padding: 12px;
      padding-top: 0px !important;
    }

  }

  .progress-stepper-container {
    margin-top: 20px !important;
  }

  .ant-progress-inner {
    width: 100% !important;
    height: auto !important;
    font-size: 42px !important;
  }

  .search .product.product-single {
    width: 50% !important;
  }

  .search .swiper-slide.product.product-simple.text-center {
    max-width: unset !important;
  }

}

.redeem-skt {
  width: 100%;
  display: grid;
  position: absolute;
  top: 0;
  height: 160px;
  margin-right: 10px;
  left: 0;
}

.tabbed-bar .sticky-link.active:after {
  border: none;
  height: 3px;
  background-color: red;
  display: block;
  width: 100%;
  max-width: 25vw;
}

@media only screen and (min-width: 960px) {

  #tabbed-bar {
    display: none !important;
  }

  .intro-wrapper>.swiper-wrapper {
    width: 105%;
    box-sizing: border-box !important;
    position: relative;
    width: 102%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
    justify-content: flex-start;
    /* Changed from space-between to flex-start */
    align-items: flex-start;
    gap: 0px;
    /* Reduced gap to 0px */
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .intro-wrapper>.swiper-wrapper>* {
    margin: 0px !important;
  }

}

@media only screen and (max-width: 960px) {

  .intro-wrapper>.swiper-wrapper {
    box-sizing: border-box !important;
    position: relative;
    width: 105%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
    justify-content: center;
    align-items: flex-start;
    gap: 0px;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
  }

  .intro-wrapper>.swiper-wrapper>* {
    margin: 0px !important;
  }

}

.ant-popover {
  max-width: 300px !important;
}

.kommand-tabs {

  width: 100%;

  /*! CSS Used from: Embedded */
  .anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon>* {
    line-height: 1;
  }

  .anticon svg {
    display: inline-block;
  }

  .anticon::before {
    display: none;
  }

  /*! CSS Used from: Embedded */
  .ant-tabs-css-var {
    font-family: var(--ant-font-family);
    font-size: var(--ant-font-size);
    box-sizing: border-box;
  }

  .ant-tabs-css-var::before,
  .ant-tabs-css-var::after {
    box-sizing: border-box;
  }

  .ant-tabs-css-var [class^="ant-tabs"],
  .ant-tabs-css-var [class*=" ant-tabs"] {
    box-sizing: border-box;
  }

  .ant-tabs-css-var [class^="ant-tabs"]::before,
  .ant-tabs-css-var [class*=" ant-tabs"]::before,
  .ant-tabs-css-var [class^="ant-tabs"]::after,
  .ant-tabs-css-var [class*=" ant-tabs"]::after {
    box-sizing: border-box;
  }

  .ant-tabs-top {
    flex-direction: column;
  }

  .ant-tabs-top>.ant-tabs-nav {
    margin: var(--ant-tabs-horizontal-margin);
  }

  .ant-tabs-top>.ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border-secondary);
    content: '';
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    height: var(--ant-line-width-bold);
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar-animated {
    transition: width var(--ant-motion-duration-slow), left var(--ant-motion-duration-slow), right var(--ant-motion-duration-slow);
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::after {
    top: 0;
    bottom: 0;
    width: var(--ant-control-height);
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::before {
    left: 0;
    box-shadow: var(--ant-box-shadow-tabs-overflow-left);
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::after {
    right: 0;
    box-shadow: var(--ant-box-shadow-tabs-overflow-right);
  }

  .ant-tabs-top>.ant-tabs-nav::before {
    bottom: 0;
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    bottom: 0;
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: var(--ant-tabs-card-padding);
    background: var(--ant-tabs-card-bg);
    border: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border-secondary);
    transition: all var(--ant-motion-duration-slow) var(--ant-motion-ease-in-out);
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active {
    color: var(--ant-tabs-item-selected-color);
    background: var(--ant-color-bg-container);
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-ink-bar {
    visibility: hidden;
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
    margin-left: var(--ant-tabs-card-gutter);
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
    border-radius: var(--ant-border-radius-lg) var(--ant-border-radius-lg) 0 0;
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: var(--ant-color-bg-container);
  }

  .ant-tabs {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--ant-color-text);
    font-size: var(--ant-font-size);
    line-height: var(--ant-line-height);
    list-style: none;
    font-family: var(--ant-font-family);
    display: flex;
  }

  .ant-tabs>.ant-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
    position: relative;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap::after {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity var(--ant-motion-duration-slow);
    content: '';
    pointer-events: none;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: opacity var(--ant-motion-duration-slow);
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations {
    display: flex;
    align-self: stretch;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations-hidden {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-more {
    position: relative;
    padding: var(--ant-tabs-card-padding);
    background: transparent;
    border: 0;
    color: var(--ant-color-text);
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-more::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: calc(var(--ant-control-height-lg) / 8);
    transform: translateY(100%);
    content: '';
  }

  .ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: var(--ant-tabs-ink-bar-color);
    pointer-events: none;
  }

  .ant-tabs .ant-tabs-tab {
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-flex;
    align-items: center;
    padding: var(--ant-tabs-horizontal-item-padding);
    font-size: var(--ant-tabs-title-font-size);
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: var(--ant-tabs-item-color);
  }

  .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
  .ant-tabs .ant-tabs-tab-btn:active {
    color: var(--ant-tabs-item-active-color);
  }

  .ant-tabs .ant-tabs-tab-btn {
    outline: none;
    transition: all var(--ant-motion-duration-slow);
  }

  .ant-tabs .ant-tabs-tab:hover {
    color: var(--ant-tabs-item-hover-color);
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--ant-tabs-item-selected-color);
    text-shadow: 0 0 0.25px currentcolor;
  }

  .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 var(--ant-tabs-horizontal-item-gutter);
  }

  .anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon>* {
    line-height: 1;
  }

  .anticon svg {
    display: inline-block;
  }

  .anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon>* {
    line-height: 1;
  }

  .anticon svg {
    display: inline-block;
  }

  .anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon>* {
    line-height: 1;
  }

  .anticon svg {
    display: inline-block;
  }

  .anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon>* {
    line-height: 1;
  }

  .anticon svg {
    display: inline-block;
  }

  /*! CSS Used from: Embedded */
  .css-var-rgt {
    --ant-blue: #1677ff;
    --ant-purple: #722ED1;
    --ant-cyan: #13C2C2;
    --ant-green: #52C41A;
    --ant-magenta: #EB2F96;
    --ant-pink: #eb2f96;
    --ant-red: #F5222D;
    --ant-orange: #FA8C16;
    --ant-yellow: #FADB14;
    --ant-volcano: #FA541C;
    --ant-geekblue: #2F54EB;
    --ant-gold: #FAAD14;
    --ant-lime: #A0D911;
    --ant-color-primary: #1677ff;
    --ant-color-success: #52c41a;
    --ant-color-warning: #faad14;
    --ant-color-error: #ff4d4f;
    --ant-color-info: #1677ff;
    --ant-color-link: #1677ff;
    --ant-color-text-base: #000;
    --ant-color-bg-base: #fff;
    --ant-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --ant-font-family-code: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    --ant-font-size: 14px;
    --ant-line-width: 1px;
    --ant-line-type: solid;
    --ant-motion-ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
    --ant-motion-ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    --ant-motion-ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
    --ant-motion-ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
    --ant-motion-ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
    --ant-motion-ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
    --ant-motion-ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    --ant-motion-ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
    --ant-border-radius: 6px;
    --ant-size-popup-arrow: 16px;
    --ant-control-height: 32px;
    --ant-z-index-base: 0;
    --ant-z-index-popup-base: 1000;
    --ant-opacity-image: 1px;
    --ant-blue-1: #e6f4ff;
    --ant-blue-2: #bae0ff;
    --ant-blue-3: #91caff;
    --ant-blue-4: #69b1ff;
    --ant-blue-5: #4096ff;
    --ant-blue-6: #1677ff;
    --ant-blue-7: #0958d9;
    --ant-blue-8: #003eb3;
    --ant-blue-9: #002c8c;
    --ant-blue-10: #001d66;
    --ant-purple-1: #f9f0ff;
    --ant-purple-2: #efdbff;
    --ant-purple-3: #d3adf7;
    --ant-purple-4: #b37feb;
    --ant-purple-5: #9254de;
    --ant-purple-6: #722ed1;
    --ant-purple-7: #531dab;
    --ant-purple-8: #391085;
    --ant-purple-9: #22075e;
    --ant-purple-10: #120338;
    --ant-cyan-1: #e6fffb;
    --ant-cyan-2: #b5f5ec;
    --ant-cyan-3: #87e8de;
    --ant-cyan-4: #5cdbd3;
    --ant-cyan-5: #36cfc9;
    --ant-cyan-6: #13c2c2;
    --ant-cyan-7: #08979c;
    --ant-cyan-8: #006d75;
    --ant-cyan-9: #00474f;
    --ant-cyan-10: #002329;
    --ant-green-1: #f6ffed;
    --ant-green-2: #d9f7be;
    --ant-green-3: #b7eb8f;
    --ant-green-4: #95de64;
    --ant-green-5: #73d13d;
    --ant-green-6: #52c41a;
    --ant-green-7: #389e0d;
    --ant-green-8: #237804;
    --ant-green-9: #135200;
    --ant-green-10: #092b00;
    --ant-magenta-1: #fff0f6;
    --ant-magenta-2: #ffd6e7;
    --ant-magenta-3: #ffadd2;
    --ant-magenta-4: #ff85c0;
    --ant-magenta-5: #f759ab;
    --ant-magenta-6: #eb2f96;
    --ant-magenta-7: #c41d7f;
    --ant-magenta-8: #9e1068;
    --ant-magenta-9: #780650;
    --ant-magenta-10: #520339;
    --ant-pink-1: #fff0f6;
    --ant-pink-2: #ffd6e7;
    --ant-pink-3: #ffadd2;
    --ant-pink-4: #ff85c0;
    --ant-pink-5: #f759ab;
    --ant-pink-6: #eb2f96;
    --ant-pink-7: #c41d7f;
    --ant-pink-8: #9e1068;
    --ant-pink-9: #780650;
    --ant-pink-10: #520339;
    --ant-red-1: #fff1f0;
    --ant-red-2: #ffccc7;
    --ant-red-3: #ffa39e;
    --ant-red-4: #ff7875;
    --ant-red-5: #ff4d4f;
    --ant-red-6: #f5222d;
    --ant-red-7: #cf1322;
    --ant-red-8: #a8071a;
    --ant-red-9: #820014;
    --ant-red-10: #5c0011;
    --ant-orange-1: #fff7e6;
    --ant-orange-2: #ffe7ba;
    --ant-orange-3: #ffd591;
    --ant-orange-4: #ffc069;
    --ant-orange-5: #ffa940;
    --ant-orange-6: #fa8c16;
    --ant-orange-7: #d46b08;
    --ant-orange-8: #ad4e00;
    --ant-orange-9: #873800;
    --ant-orange-10: #612500;
    --ant-yellow-1: #feffe6;
    --ant-yellow-2: #ffffb8;
    --ant-yellow-3: #fffb8f;
    --ant-yellow-4: #fff566;
    --ant-yellow-5: #ffec3d;
    --ant-yellow-6: #fadb14;
    --ant-yellow-7: #d4b106;
    --ant-yellow-8: #ad8b00;
    --ant-yellow-9: #876800;
    --ant-yellow-10: #614700;
    --ant-volcano-1: #fff2e8;
    --ant-volcano-2: #ffd8bf;
    --ant-volcano-3: #ffbb96;
    --ant-volcano-4: #ff9c6e;
    --ant-volcano-5: #ff7a45;
    --ant-volcano-6: #fa541c;
    --ant-volcano-7: #d4380d;
    --ant-volcano-8: #ad2102;
    --ant-volcano-9: #871400;
    --ant-volcano-10: #610b00;
    --ant-geekblue-1: #f0f5ff;
    --ant-geekblue-2: #d6e4ff;
    --ant-geekblue-3: #adc6ff;
    --ant-geekblue-4: #85a5ff;
    --ant-geekblue-5: #597ef7;
    --ant-geekblue-6: #2f54eb;
    --ant-geekblue-7: #1d39c4;
    --ant-geekblue-8: #10239e;
    --ant-geekblue-9: #061178;
    --ant-geekblue-10: #030852;
    --ant-gold-1: #fffbe6;
    --ant-gold-2: #fff1b8;
    --ant-gold-3: #ffe58f;
    --ant-gold-4: #ffd666;
    --ant-gold-5: #ffc53d;
    --ant-gold-6: #faad14;
    --ant-gold-7: #d48806;
    --ant-gold-8: #ad6800;
    --ant-gold-9: #874d00;
    --ant-gold-10: #613400;
    --ant-lime-1: #fcffe6;
    --ant-lime-2: #f4ffb8;
    --ant-lime-3: #eaff8f;
    --ant-lime-4: #d3f261;
    --ant-lime-5: #bae637;
    --ant-lime-6: #a0d911;
    --ant-lime-7: #7cb305;
    --ant-lime-8: #5b8c00;
    --ant-lime-9: #3f6600;
    --ant-lime-10: #254000;
    --ant-color-text: rgba(0, 0, 0, 0.88);
    --ant-color-text-secondary: rgba(0, 0, 0, 0.65);
    --ant-color-text-tertiary: rgba(0, 0, 0, 0.45);
    --ant-color-text-quaternary: rgba(0, 0, 0, 0.25);
    --ant-color-fill: rgba(0, 0, 0, 0.15);
    --ant-color-fill-secondary: rgba(0, 0, 0, 0.06);
    --ant-color-fill-tertiary: rgba(0, 0, 0, 0.04);
    --ant-color-fill-quaternary: rgba(0, 0, 0, 0.02);
    --ant-color-bg-layout: #f5f5f5;
    --ant-color-bg-container: #ffffff;
    --ant-color-bg-elevated: #ffffff;
    --ant-color-bg-spotlight: rgba(0, 0, 0, 0.85);
    --ant-color-bg-blur: transparent;
    --ant-color-border: #d9d9d9;
    --ant-color-border-secondary: #f0f0f0;
    --ant-color-primary-bg: #e6f4ff;
    --ant-color-primary-bg-hover: #bae0ff;
    --ant-color-primary-border: #91caff;
    --ant-color-primary-border-hover: #69b1ff;
    --ant-color-primary-hover: #4096ff;
    --ant-color-primary-active: #0958d9;
    --ant-color-primary-text-hover: #4096ff;
    --ant-color-primary-text: #1677ff;
    --ant-color-primary-text-active: #0958d9;
    --ant-color-success-bg: #f6ffed;
    --ant-color-success-bg-hover: #d9f7be;
    --ant-color-success-border: #b7eb8f;
    --ant-color-success-border-hover: #95de64;
    --ant-color-success-hover: #95de64;
    --ant-color-success-active: #389e0d;
    --ant-color-success-text-hover: #73d13d;
    --ant-color-success-text: #52c41a;
    --ant-color-success-text-active: #389e0d;
    --ant-color-error-bg: #fff2f0;
    --ant-color-error-bg-hover: #fff1f0;
    --ant-color-error-bg-active: #ffccc7;
    --ant-color-error-border: #ffccc7;
    --ant-color-error-border-hover: #ffa39e;
    --ant-color-error-hover: #ff7875;
    --ant-color-error-active: #d9363e;
    --ant-color-error-text-hover: #ff7875;
    --ant-color-error-text: #ff4d4f;
    --ant-color-error-text-active: #d9363e;
    --ant-color-warning-bg: #fffbe6;
    --ant-color-warning-bg-hover: #fff1b8;
    --ant-color-warning-border: #ffe58f;
    --ant-color-warning-border-hover: #ffd666;
    --ant-color-warning-hover: #ffd666;
    --ant-color-warning-active: #d48806;
    --ant-color-warning-text-hover: #ffc53d;
    --ant-color-warning-text: #faad14;
    --ant-color-warning-text-active: #d48806;
    --ant-color-info-bg: #e6f4ff;
    --ant-color-info-bg-hover: #bae0ff;
    --ant-color-info-border: #91caff;
    --ant-color-info-border-hover: #69b1ff;
    --ant-color-info-hover: #69b1ff;
    --ant-color-info-active: #0958d9;
    --ant-color-info-text-hover: #4096ff;
    --ant-color-info-text: #1677ff;
    --ant-color-info-text-active: #0958d9;
    --ant-color-link-hover: #69b1ff;
    --ant-color-link-active: #0958d9;
    --ant-color-bg-mask: rgba(0, 0, 0, 0.45);
    --ant-color-white: #fff;
    --ant-font-size-sm: 12px;
    --ant-font-size-lg: 16px;
    --ant-font-size-xl: 20px;
    --ant-font-size-heading-1: 38px;
    --ant-font-size-heading-2: 30px;
    --ant-font-size-heading-3: 24px;
    --ant-font-size-heading-4: 20px;
    --ant-font-size-heading-5: 16px;
    --ant-line-height: 1.5714285714285714;
    --ant-line-height-lg: 1.5;
    --ant-line-height-sm: 1.6666666666666667;
    --ant-font-height: 22px;
    --ant-font-height-lg: 24px;
    --ant-font-height-sm: 20px;
    --ant-line-height-heading-1: 1.2105263157894737;
    --ant-line-height-heading-2: 1.2666666666666666;
    --ant-line-height-heading-3: 1.3333333333333333;
    --ant-line-height-heading-4: 1.4;
    --ant-line-height-heading-5: 1.5;
    --ant-control-height-sm: 24px;
    --ant-control-height-xs: 16px;
    --ant-control-height-lg: 40px;
    --ant-motion-duration-fast: 0.1s;
    --ant-motion-duration-mid: 0.2s;
    --ant-motion-duration-slow: 0.3s;
    --ant-line-width-bold: 2px;
    --ant-border-radius-xs: 2px;
    --ant-border-radius-sm: 4px;
    --ant-border-radius-lg: 8px;
    --ant-border-radius-outer: 4px;
    --ant-color-fill-content: rgba(0, 0, 0, 0.06);
    --ant-color-fill-content-hover: rgba(0, 0, 0, 0.15);
    --ant-color-fill-alter: rgba(0, 0, 0, 0.02);
    --ant-color-bg-container-disabled: rgba(0, 0, 0, 0.04);
    --ant-color-border-bg: #ffffff;
    --ant-color-split: rgba(5, 5, 5, 0.06);
    --ant-color-text-placeholder: rgba(0, 0, 0, 0.25);
    --ant-color-text-disabled: rgba(0, 0, 0, 0.25);
    --ant-color-text-heading: rgba(0, 0, 0, 0.88);
    --ant-color-text-label: rgba(0, 0, 0, 0.65);
    --ant-color-text-description: rgba(0, 0, 0, 0.45);
    --ant-color-text-light-solid: #fff;
    --ant-color-highlight: #ff4d4f;
    --ant-color-bg-text-hover: rgba(0, 0, 0, 0.06);
    --ant-color-bg-text-active: rgba(0, 0, 0, 0.15);
    --ant-color-icon: rgba(0, 0, 0, 0.45);
    --ant-color-icon-hover: rgba(0, 0, 0, 0.88);
    --ant-color-error-outline: rgba(255, 38, 5, 0.06);
    --ant-color-warning-outline: rgba(255, 215, 5, 0.1);
    --ant-font-size-icon: 12px;
    --ant-line-width-focus: 4px;
    --ant-control-outline-width: 2px;
    --ant-control-interactive-size: 16px;
    --ant-control-item-bg-hover: rgba(0, 0, 0, 0.04);
    --ant-control-item-bg-active: #e6f4ff;
    --ant-control-item-bg-active-hover: #bae0ff;
    --ant-control-item-bg-active-disabled: rgba(0, 0, 0, 0.15);
    --ant-control-tmp-outline: rgba(0, 0, 0, 0.02);
    --ant-control-outline: rgba(5, 145, 255, 0.1);
    --ant-font-weight-strong: 600;
    --ant-opacity-loading: 0.65;
    --ant-link-decoration: none;
    --ant-link-hover-decoration: none;
    --ant-link-focus-decoration: none;
    --ant-control-padding-horizontal: 12px;
    --ant-control-padding-horizontal-sm: 8px;
    --ant-padding-xxs: 4px;
    --ant-padding-xs: 8px;
    --ant-padding-sm: 12px;
    --ant-padding: 16px;
    --ant-padding-md: 20px;
    --ant-padding-lg: 24px;
    --ant-padding-xl: 32px;
    --ant-padding-content-horizontal-lg: 24px;
    --ant-padding-content-vertical-lg: 16px;
    --ant-padding-content-horizontal: 16px;
    --ant-padding-content-vertical: 12px;
    --ant-padding-content-horizontal-sm: 16px;
    --ant-padding-content-vertical-sm: 8px;
    --ant-margin-xxs: 4px;
    --ant-margin-xs: 8px;
    --ant-margin-sm: 12px;
    --ant-margin: 16px;
    --ant-margin-md: 20px;
    --ant-margin-lg: 24px;
    --ant-margin-xl: 32px;
    --ant-margin-xxl: 48px;
    --ant-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    --ant-box-shadow-secondary: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    --ant-box-shadow-tertiary: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
    --ant-box-shadow-popover-arrow: 2px 2px 5px rgba(0, 0, 0, 0.05);
    --ant-box-shadow-card: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    --ant-box-shadow-drawer-right: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
    --ant-box-shadow-drawer-left: 6px 0 16px 0 rgba(0, 0, 0, 0.08), 3px 0 6px -4px rgba(0, 0, 0, 0.12), 9px 0 28px 8px rgba(0, 0, 0, 0.05);
    --ant-box-shadow-drawer-up: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    --ant-box-shadow-drawer-down: 0 -6px 16px 0 rgba(0, 0, 0, 0.08), 0 -3px 6px -4px rgba(0, 0, 0, 0.12), 0 -9px 28px 8px rgba(0, 0, 0, 0.05);
    --ant-box-shadow-tabs-overflow-left: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
    --ant-box-shadow-tabs-overflow-right: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
    --ant-box-shadow-tabs-overflow-top: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
    --ant-box-shadow-tabs-overflow-bottom: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
  }

  /*! CSS Used from: Embedded */
  .css-var-rgt.ant-tabs-css-var {
    --ant-tabs-z-index-popup: 1050;
    --ant-tabs-card-bg: rgba(0, 0, 0, 0.02);
    --ant-tabs-card-height: 40px;
    --ant-tabs-card-padding: 8px 16px;
    --ant-tabs-card-padding-sm: 6px 16px;
    --ant-tabs-card-padding-lg: 8px 16px 6px;
    --ant-tabs-title-font-size: 14px;
    --ant-tabs-title-font-size-lg: 16px;
    --ant-tabs-title-font-size-sm: 14px;
    --ant-tabs-ink-bar-color: #1677ff;
    --ant-tabs-horizontal-margin: 0 0 16px 0;
    --ant-tabs-horizontal-item-gutter: 32px;
    --ant-tabs-horizontal-item-padding: 12px 0;
    --ant-tabs-horizontal-item-padding-sm: 8px 0;
    --ant-tabs-horizontal-item-padding-lg: 16px 0;
    --ant-tabs-vertical-item-padding: 8px 24px;
    --ant-tabs-vertical-item-margin: 16px 0 0 0;
    --ant-tabs-item-color: rgba(0, 0, 0, 0.88);
    --ant-tabs-item-selected-color: #1677ff;
    --ant-tabs-item-hover-color: #4096ff;
    --ant-tabs-item-active-color: #0958d9;
    --ant-tabs-card-gutter: 2px;
  }

  /*! CSS Used from: Embedded */
  .ant-tabs-css-var {
    font-family: var(--ant-font-family);
    font-size: var(--ant-font-size);
    box-sizing: border-box;
  }

  .ant-tabs-css-var::before,
  .ant-tabs-css-var::after {
    box-sizing: border-box;
  }

  .ant-tabs-css-var [class^="ant-tabs"],
  .ant-tabs-css-var [class*=" ant-tabs"] {
    box-sizing: border-box;
  }

  .ant-tabs-css-var [class^="ant-tabs"]::before,
  .ant-tabs-css-var [class*=" ant-tabs"]::before,
  .ant-tabs-css-var [class^="ant-tabs"]::after,
  .ant-tabs-css-var [class*=" ant-tabs"]::after {
    box-sizing: border-box;
  }

  .ant-tabs-top {
    flex-direction: column;
  }

  .ant-tabs-top>.ant-tabs-nav {
    margin: var(--ant-tabs-horizontal-margin);
  }

  .ant-tabs-top>.ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border-secondary);
    content: '';
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    height: var(--ant-line-width-bold);
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar-animated {
    transition: width var(--ant-motion-duration-slow), left var(--ant-motion-duration-slow), right var(--ant-motion-duration-slow);
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::after {
    top: 0;
    bottom: 0;
    width: var(--ant-control-height);
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::before {
    left: 0;
    box-shadow: var(--ant-box-shadow-tabs-overflow-left);
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::after {
    right: 0;
    box-shadow: var(--ant-box-shadow-tabs-overflow-right);
  }

  .ant-tabs-top>.ant-tabs-nav::before {
    bottom: 0;
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    bottom: 0;
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
    padding: 5px 50px 0 25px !important;
    background: var(--ant-tabs-card-bg);
    border: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border-secondary);
    transition: all var(--ant-motion-duration-slow) var(--ant-motion-ease-in-out);
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active {
    color: var(--ant-tabs-item-selected-color);
    background: #ffffff00;
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-ink-bar {
    visibility: hidden;
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
    margin-left: var(--ant-tabs-card-gutter);
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
    border-radius: var(--ant-border-radius-lg) var(--ant-border-radius-lg) 0 0;
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: var(--ant-color-bg-container);
  }

  .ant-tabs {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--ant-color-text);
    font-size: var(--ant-font-size);
    line-height: var(--ant-line-height);
    list-style: none;
    font-family: var(--ant-font-family);
    display: flex;
  }

  .ant-tabs>.ant-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
    position: relative;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap::after {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity var(--ant-motion-duration-slow);
    content: '';
    pointer-events: none;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: opacity var(--ant-motion-duration-slow);
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations {
    display: flex;
    align-self: stretch;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations-hidden {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-more {
    position: relative;
    padding: var(--ant-tabs-card-padding);
    background: transparent;
    border: 0;
    color: var(--ant-color-text);
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-more::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: calc(var(--ant-control-height-lg) / 8);
    transform: translateY(100%);
    content: '';
  }

  .ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: var(--ant-tabs-ink-bar-color);
    pointer-events: none;
  }

  .ant-tabs .ant-tabs-tab {
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-flex;
    align-items: center;
    padding: var(--ant-tabs-horizontal-item-padding);
    font-size: var(--ant-tabs-title-font-size);
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: var(--ant-tabs-item-color);
  }

  .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
  .ant-tabs .ant-tabs-tab-btn:active {
    color: var(--ant-tabs-item-active-color);
  }

  .ant-tabs .ant-tabs-tab-btn {
    outline: none;
    transition: all var(--ant-motion-duration-slow);
  }

  .ant-tabs .ant-tabs-tab:hover {
    color: var(--ant-tabs-item-hover-color);
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--ant-tabs-item-selected-color);
    text-shadow: 0 0 0.25px currentcolor;
  }

  .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 var(--ant-tabs-horizontal-item-gutter);
  }

  /*! CSS Used from: Embedded */
  .anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon>* {
    line-height: 1;
  }

  .anticon svg {
    display: inline-block;
  }

  /*! CSS Used from: Embedded */
  .anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon>* {
    line-height: 1;
  }

  .anticon svg {
    display: inline-block;
  }

  /*! CSS Used from: Embedded */
  .anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon>* {
    line-height: 1;
  }

  .anticon svg {
    display: inline-block;
  }

  /*! CSS Used from: Embedded */
  .anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon>* {
    line-height: 1;
  }

  .anticon svg {
    display: inline-block;
  }

  div.ant-tabs-tab.ant-tabs-tab-active {
    border: none !important;
    border-bottom: 2px solid #542278 !important;
  }

  div.ant-tabs-tab.ant-tabs-tab-active::after {
    display: block;
    width: 100%;
    border-bottom-color: #f4f4f4 !important;
  }

  .ant-tabs>.ant-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    width: 100%;
    border: none;
  }

  div.ant-tabs-nav-list {
    width: 100%;
  }

  div.ant-tabs-tab.bbm {
    border-bottom: 2px solid #dfdfdf !important;
  }

  div.bg-none {
    background-color: transparent !important;
    border: none !important;
  }

  .ant-tabs .ant-tabs-tab-btn:active {
    color: #5a297c;
  }

  a.ant-tabs-tab-btn {
    margin-top: 8px !important;
  }

}

.category-dropdown.show-dropdown .dropdown-box:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 8rem;
  border: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
}

.edit-rfq-btn {
  margin-right: -10px;
  float: right;
  cursor: pointer;
}

.ant-message-notice-wrapper:first-child {
  opacity: 1 !important;
}

.ant-message-notice-wrapper:first-child+.ant-message-notice-wrapper {
  opacity: 0 !important;
}

.title-link-wrapper.top:before {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  width: 100%;
  border-top: 1px solid #cecece;
}

.title-link-wrapper.top:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid transparent;
}

.title-link-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #cecece;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #cccccc;
  content: '';
}

h2.title.ls-normal.mb-0 {
  font-size: 20px !important;
}

.MuiFilledInput-root {
  padding-top: 8px !important;
  padding-left: 8px !important;
  padding-bottom: 8px !important;
}

.MuiInputBase-root {
  border: 1px solid #ccc !important;
  background-color: transparent !important;
  border-radius: 8px !important;
}

.MuiInputBase-root::before {
  border: none !important;
}

.MuiInputBase-root::after {
  border: none !important;
}

.MuiChip-label {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.5;
  background-color: transparent !important;
}

.MuiAutocomplete-tag {
  margin: 3px;
  max-width: calc(100% - 6px);
  padding: 0 !important;
  border-radius: 8px !important;
}

.MuiInputBase-input {
  font-size: 16px !important;
}

.highest-zindex {
  z-index: inherit;
}

.ant-popover {
  z-index: 12000 !important;
}

.mt--3 {
  margin-top: -5px;
}

.ml--1 {
  margin-left: -2px;
  margin-right: 0px;
}

.d-flex-invite {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0;
}

.lh-1 {
  line-height: 1 !important;
}

.h-150 {
  height: 150px !important;
}

.m-auto {
  margin: auto !important;
}

.uniform-image {
  width: 100% !important;
  /* Set your desired width */
  height: 200px !important;
  /* Set your desired height */
  object-fit: contain !important;
  /* This will crop the image to fill the box */
}

.cat-item-card {
  border: 3px solid transparent !important;
  cursor: pointer;
}

.cat-item-card.active {
  border: 3px solid #542278 !important;
}

.fs-16 {
  font-size: 15px !important;
  margin: 5px auto;
  line-height: 2;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #542278;
  border-color: #542278;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #f6eaff;
  border-color: #f6eaff;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #542278;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #542278;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #542278;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 120px;
  margin-top: 12px;
  text-align: center;
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #542278;
}

.ant-input-outlined:hover {
  border-color: #542278;
}

.pricing-feature-item-bullet {
  width: 14px !important;
  margin-left: -15px !important;
  margin-right: 5px !important;
  display: inline-block !important;
}

.mh-664 {
  min-height: 860px !important;
}

.w-70 {
  width: 70% !important;
  margin: auto;
}

.plan-price {
  font-weight: 800 !important;
  font-size: 45px;
  /* Set the font size */
  font-family: Arial, Helvetica, sans-serif;
  /* Set the font family */
  background: linear-gradient(to right, #a30086, #542278, #a40087, #542278);
  /* Define the gradient */
  -webkit-background-clip: text;
  /* Clip the background to the text */
  -webkit-text-fill-color: transparent;
  /* Make the text color transparent */

  small {
    font-weight: 800 !important;
    font-size: 24px;
    background: transparent;
    -webkit-background-clip: unset;
  }

}

.switcher .ant-segmented {
  box-sizing: border-box;
  margin: 0;
  padding: 4px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 17px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: "Google Sans";
  display: inline-block;
  background: #ffffff;
  border-radius: 18px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 2px solid #542278;
  font-weight: 600;
}

.switcher .ant-segmented .ant-segmented-item {
  background-color: transparent;
  box-shadow: none;
  color: #000000;
  padding: 5px;
  border-radius: 13px;
}

.switcher .ant-segmented .ant-segmented-item-selected {
  background-color: #542278;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  color: #ffffff;
  padding: 5px;
  border-radius: 13px;
}

.tag-input.ant-input {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 3px 6px;
}

@media only screen and (max-width: 750px) {
  .title-link-wrapper .title.ls-normal.mb-0.black {
    font-size: 19px !important;
    font-weight: 600 !important;
    color: #000000;
    margin: 0;
    line-height: 52px;
    padding: 0;
    margin-top: 5px;
  }

  h2.mb-3 {
    font-size: 2.45rem;
  }
}

@media only screen and (max-width: 750px) {
  .swiper-slide.product.product-simple.text-center {
    margin-bottom: 20px !important;
    max-width: unset !important;
  }
}

.green {
  background-color: #4CAF50 !important;
  border-color: #4CAF50 !important;
}

.pricing-modal {
  max-width: calc(100vw - 40px) !important;
  width: 100% !important;



  .switcher .ant-segmented {
    width: 255px;
    margin: auto;
    display: block;
  }
}

.rfq-mk-iconic-txt {
  font-size: 15px;
  line-height: 15px;
  opacity: 1;
}

.rfq-mk-desc-txt {
  font-size: 15px;
  opacity: 1;
}

.purple-icon svg {
  color: #542278;
  width: 16px;
  height: 16px;
  margin: 0 !important;
}

.f-14 {

  font-size: 14px !important;

  .ant-tag {
    background: #ffffff;
    border: 1px solid #542278;
    color: #542278 !important;
    font-size: 13px !important;
    border-radius: 10px;
  }

}

.f-16 {

  font-size: 16px !important;

  .ant-tag {
    background: #ffffff;
    border: 1px solid #542278;
    color: #542278 !important;
    font-size: 13px !important;
    border-radius: 10px;
  }

}

svg.verification-icon {
  width: 22px;
  height: 22px;
  margin-top: -4px;
  fill: #542278;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 38px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 38px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-block-start-width: 1.02px;
  border-inline-start-width: 0;
  border-inline-end-width: 1px;
  cursor: pointer;
  transition: color 0.2s, background 0.2s, box-shadow 0.2s;
  width: 25%;
  text-align: center;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #ffffff;
  background-color: #542278;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #542278;
}

@media only screen and (max-width: 750px) {
  .mobile-hide {
    visibility: hidden !important;
    display: none !important;
  }

  .desktop-hide {
    visibility: visible !important;
    display: block !important;
  }

  .ant-popconfirm-buttons {
    display: flex !important;
    flex-direction: column !important;
    gap: 16px !important;
  }

  .ant-popconfirm .ant-popconfirm-buttons button {
    margin-inline-start: 8px;
    padding: 10px 20px;
    height: auto;
    border-radius: 10px;
    width: 95%;
  }

  .d-flex-2-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 0px;
    margin-top: 24px;

    button {
      width: 50% !important;

      span.anticon.anticon-appstore>svg {
        height: unset !important;
        width: unset !important;
        border: 1px solid #a2a2a2 !important;
        margin-top: unset !important;
        position: unset !important;
        left: unset !important;
        display: unset !important;
      }

    }

  }

  .cat-selection {
    background-color: #542278;
    color: #ffffff;
    margin: -6px;
    padding: 0px;
    font-size: 12px;
    display: block;
    position: absolute;
    width: 101%;
    z-index: 1234564 !important;
    border: 4px solid #542278;
    margin-top: -25px !important;
    margin-left: -20px !important;
  }

  .ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 38px;
    margin: 0;
    padding-inline: 15px;
    padding-block: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 12px;
    line-height: 16px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-block-start-width: 1.02px;
    border-inline-start-width: 0;
    border-inline-end-width: 1px;
    cursor: pointer;
    transition: color 0.2s, background 0.2s, box-shadow 0.2s;
    width: 25%;
    text-align: center;
    padding-top: 2px;
    vertical-align: middle;
  }

}

@media only screen and (max-width: 751px) {
  .ant-radio-button-wrapper {
    padding-inline: 0 !important;
    padding-block: 0;
    font-size: calc(100vw * 0.025) !important;
  }

  .ant-pagination-prev {
    display: none !important;
  }

  .ant-pagination-next {
    display: none !important;
  }

}

@media only screen and (max-width: 412px) {
  .ant-radio-button-wrapper {
    padding-inline: 0 !important;
    padding-block: 0;
    font-size: calc(100vw * 0.03) !important;
  }

  .ant-pagination {
    display: inline-block;
    float: right;
    margin-top: 20px;
    font-size: 12px;
  }

}

@media only screen and (min-width: 751px) {
  .mobile-hide {
    visibility: visible !important;
    display: block !important;
  }

  .desktop-hide {
    visibility: hidden !important;
    display: none !important;
  }
}

.grey-btn {
  color: #a2a2a2 !important;
  border-color: #d9d9d9 !important;
  background-color: #fff !important;
  border-width: 2px !important;
  font-weight: 500;
}

.ant-input-outlined,
.grey-input.ant-input,
.grey-input .ant-select-selector,
.grey-text {
  color: #333333 !important;
  border-color: #d9d9d9 !important;
  background-color: #fff !important;
  border-width: 2px !important;
  font-weight: 500;
}

.ant-input-outlined {
  background: #ffffff;
  border-width: 2px;
  border-style: solid;
  border-color: #d9d9d9;
}

.cat-selection {
  background-color: #542278;
  color: #ffffff;
  margin: -6px;
  padding: 0px;
  font-size: 12px;
  display: block;
  position: absolute;
  width: 101%;
  z-index: 1234564 !important;
  border: 4px solid #542278;
}

.cat-selected-tick {
  color: #542278;
  top: 30px;
  right: 3px;
  padding: 0px;
  font-size: 18px;
  display: inline-block;
  position: absolute;
}

.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #542278;
}

.ant-slider .ant-slider-track {
  background-color: #542278;
  border-radius: 2px;
}

.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px #542278;
}

.ant-slider:hover .ant-slider-track {
  background-color: #542278;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #542278;
  border-color: #542278;
}

.switcher {
  margin-right: auto;
}

.switcher {
  margin-left: auto;
}

.info-icon {
  width: 18px;
  height: 18px;
  margin-bottom: -4px;
  fill: #552278;
}

.stars {
  font-size: 160%;
  color: rgb(209, 18, 59) !important;
  padding: 4px 1px;
}

.stars-text {
  font-size: 160%;
  color: #333333;
  font-weight: 600;
}

.stars-text-small {
  font-size: 160%;
  color: #333333;
  font-weight: 600;
  display: block;
  clear: both;
  margin-top: 20px;
}

.ant-popover {
  max-width: 400px !important;
}

.ant-progress .ant-progress-bg {
  background-color: rgb(209 18 59) !important;
  border-radius: 0px;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 38px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 38px;
  background: #ffffff;
  border: 2px solid #d9d9d9;
  border-block-start-width: 2px;
  border-inline-start-width: 2px !important;
  border-inline-end-width: 2px;
  cursor: pointer;
  transition: color 0.2s, background 0.2s, box-shadow 0.2s;
  width: 25%;
  text-align: center;
}

.grey-outline {

  .ant-btn-default,
  .btn-default-sz {
    color: #d9d9d9 !important;
    border-color: #d9d9d9 !important;
    background-color: #fff !important;
    border-width: 2px !important;
    font-weight: 500;
    height: 42px;
  }

  .ant-select .ant-select-clear {
    position: absolute;
    margin-right: 37px;
    top: 50%;
    inset-inline-start: auto;
    inset-inline-end: 11px;
    z-index: 1;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-top: -11px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 24px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    cursor: pointer;
    opacity: 1;
    transition: color 0.2s ease, opacity 0.3s ease;
    text-rendering: auto;
  }

  .ant-input-outlined {
    background: #ffffff;
    border-width: 2px;
    border-style: solid;
    border-color: #d9d9d9;
  }

}

.ant-switch.ant-switch-checked {
  background: #542278;
}

.ant-pagination .ant-pagination-item-active a {
  color: #542278;
}

.ant-pagination .ant-pagination-item-active:hover a {
  color: #E91E63;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #ffffff;
  border-color: #542278;
}

.ant-pagination .ant-pagination-item-active:hover {
  font-weight: 600;
  background-color: #ffffff;
  border-color: #E91E63;
}

.ant-select .ant-select-arrow {
  font-size: 24px;
}

.grey-input.ant-input:hover,
.grey-input.ant-input:active,
.grey-input.ant-input:focus,
.grey-input .ant-select-selector:hover,
.grey-input .ant-select-selector:active,
.grey-input .ant-select-selector:focus,
.grey-text {
  color: #333333 !important;
  border-color: #542278 !important;
  background-color: #fff !important;
  border-width: 2px !important;
  font-weight: 500;
}

::placeholder,
::-ms-input-placeholder,
input::placeholder {
  color: #555555 !important;
}

.ant-select .ant-select-selection-placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  color: rgb(34 34 34);
  pointer-events: none;
}

.ant-input {
  font-family: unset !important;
  font-weight: unset !important;

}

hr {
  margin: 0.5rem 0 1.5rem;
  border: 0;
  border-top: 1px solid #8f8f8f;
}

a[disabled] {
  color: rgb(96 96 96);
  cursor: not-allowed;
}

.ZB3Ebc .ZAGvjd {
  border-radius: 30px !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-inline-end: 40px;
}

svg.highcharts-root {
  width: 100% !important;
}

.profile-wizard .form-control-lg {
  font-size: 1.65rem !important;
}

.kommand {

  .ant-drawer-close {
    display: none;
  }

  .ant-drawer-header {
    background-color: #542278;
    background-image: url(https://dev.supplyzone.ai/static/media/header.1d2c93e7b94790f8025c.png) !important;
    background-position-x: right !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    color: #ffffff;

    .ant-typography {
      color: #ffffff;
    }



    svg {
      fill: #ffffff;
    }

  }

}

.ant-drawer-content.message-thread {

  .ant-drawer-close {
    display: none;
  }

  .ant-drawer-header {
    background-color: #542278;
    background-image: url(https://dev.supplyzone.ai/static/media/header.1d2c93e7b94790f8025c.png) !important;
    background-position-x: right !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    color: #ffffff !important;

    .ant-typography,
    h5,
    .ant-drawer-title {
      color: #ffffff !important;
    }



    svg {
      fill: #ffffff !important;
    }

  }

}

.messenger-folders{
  position: absolute;
  top: 21px;
  right: -15px;
}

.rfq-description-wrapper > .col-md-12 {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 147px;
}

.truncate-3-lines {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  margin: 0 0 4px 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 15px;
  line-height: 1.5714285714285714;

  .text-grey{
    font-size: 12px;
  }
}