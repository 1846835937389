@import url(https://raw.githubusercontent.com/dmhendricks/bootstrap-grid-css/master/dist/css/bootstrap-grid.css);

.button-style {
  background-color: rgb(96, 0, 126);
  border-width: 0px;
}

.bg {
  background-color: rgb(245, 245, 245);
}

.intro {
  padding-top: 100px;
  padding-bottom: 50px;
}

nav .navbar .menu {
  display: flex;
}

.navbar .menu li {
  list-style: none;
  margin: 0 15px;
}

.navbar .menu li a {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
}

.header-text {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 48px !important;
  font-weight: 600 !important;
  color: rgb(255, 255, 255);
  line-height: 1;
}

.paragraph-text {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  color: rgb(255, 255, 255);
  font-size: 20px !important;
}

.section {
  margin-top: 0px;
  width: 100%px;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #414141;
  background-size: cover;
}

.colgap {
  column-gap: 10px;
}

.hover {
  background-color: #c76998;
}

.bg {
  background-color: rgb(245, 245, 245);
  background-size: cover;
}

.reward-column {
  margin-left: 18%;
  margin-top: 15px;

  width: 64%;
  height: 3px;
  padding: 2% 100px;
  flex-direction: row;
  align-items: "centergap: 5px";

  background-size: cover;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  display: inline-flex;
}

.reward-column2 {
  margin-left: 0px;
  margin-top: 0px;
  width: 100%;
  height: 400px;
  flex-direction: row;
  align-items: "centergap: 5px";
  background-size: cover;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
}

.reward-column3 {
  margin-left: 0px;
  margin-top: 0px;
  width: 180%;
  height: 60px;
  flex-direction: row;
  align-items: "centergap: 5px";
  background-size: cover;

  overflow: hidden;
  justify-content: flex-start;
  align-items: left;
}

.reward-column4 {
  margin-left: 0px;
  margin-top: 0px;
  width: 180%;
  height: 80px;
  flex-direction: row;
  align-items: "centergap: 5px";
  background-size: cover;

  overflow: hidden;
  justify-content: flex-start;
  align-items: left;
}

.earn-points {
  margin-left: 0px;
  margin-top: 20px;
  margin-right: 0px flex;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  flex-direction: column;
  background-color: #ffffff;
  background-size: cover;
  box-shadow: -5px 9px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: clip;
  justify-content: center;
  align-items: center;
  display: inline-block;
}

.earn-points-small {
  margin-left: 0px;
  margin-top: 20px;
  width: 100%;
  height: 150px;
  padding: 10px 10px;
  flex-direction: column;
  align-items: "centergap: 5px";
  background-color: #ffffff;
  background-size: cover;
  box-shadow: -5px 8px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.invite-supplier {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.po {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.share {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.share2 {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.email {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.feedback {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.inquiry {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.rfq {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}

.like {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.prize-card-text {
  text-align: center;
  color: #ffffff;
  font-size: 64px;
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
}

.header {
  background-size: auto;
  background-repeat: no-repeat;
}

.point-text {
  margin-top: 40px;
  margin-left: 95%;
  width: 1300px;
  height: 72px;
  text-align: left;
  color: #2d0357;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;
}

.service-text {
  margin-top: -90px;
  margin-left: 140px;
  width: 400px;
  height: 72px;
  text-align: left;
  color: #2d0357;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;
}

.intro-bar {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ffffff;
  padding: 50px;
}

.intro-bar2 {
  width: 100%;
  bottom: 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.register-now-btn {
  display: block;
  border: 2px solid white;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin: auto;
  margin-top: 40px;
  width: 170px;
  text-align: center;
  background-color: transparent;
}
.register-now-btn:hover {
  display: block;
  border: 2px solid white;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin: auto;
  margin-top: 40px;
  width: 170px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
}
