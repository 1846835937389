.suggested-categories  .ant-select-selector{
    padding-top: 4px !important;
}
.text-purple{
    color: #552278 !important;
}
.ant-picker-range{
    border: 2px solid #dadce3 !important;
    font-size: 21px;
    height: 43px;
}
.ant-picker-input input{
    font-size: 16px;
}