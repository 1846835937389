  
  .mod-rfq-bg.data-v-8070b792 { 
      width: 100%; 
      border-radius: 8px;
  } 
  
  .mod-wrapper.data-v-8070b792 { 
      display: -webkit-box; 
      display: -ms-flexbox; 
      display: flex; 
      margin-bottom: 20px; 
      -webkit-box-pack: justify; 
      -ms-flex-pack: justify; 
      justify-content: space-between;
  } 
  
  .content.data-v-8070b792 { 
      margin-top: 20px;
  } 
  
  @media screen and (max-width: 1550px){ 
    .content.data-v-8070b792 { 
      width: 1200px; 
      margin-top: 11px;
    } 
  }     
  
  .main-wrapper.data-v-47590bb2 { 
      position: relative; 
      margin: 0 auto;
  } 
  
  .main-wrapper.overHide.data-v-47590bb2 { 
      overflow: hidden;
  } 
  
  .body-wrapper.data-v-47590bb2 { 
      position: relative; 
      width: 100%; 
      min-width: 1200px; 
      min-height: 100%; 
      -webkit-box-sizing: border-box; 
      box-sizing: border-box; 
      margin: 0 auto;
  } 

  .mod-rfq.data-v-8070b792 { 
      display: -webkit-box; 
      display: -ms-flexbox; 
      display: flex; 
      padding: 40px; 
      height: 384px;
  } 

  .rfq-info.data-v-8070b792 { 
      color: #2d2d2d; 
      line-height: 28px;
  } 
  
  .mod-rfq .rfq-info.data-v-8070b792  { 
      font-size: 14px; 
      -webkit-box-flex: 1; 
      -ms-flex: 1; 
      flex: 1;
  } 
  
  .mod-rfq .rfq-form.data-v-8070b792  { 
      width: 680px; 
      background: #fff; 
      border-radius: 8px; 
      padding: 40px 80px 40px 40px;
  } 
  
  @media screen and (max-width: 1550px){ 
    .mod-rfq .rfq-form.data-v-8070b792  { 
      width: 530px; 
      padding: 40px;
    } 
  }     
  
  .rfq-form .tit-box.data-v-8070b792  { 
      display: -webkit-box; 
      display: -ms-flexbox; 
      display: flex; 
      -webkit-box-align: center; 
      -ms-flex-align: center; 
      align-items: center; 
      margin-bottom: 16px; 
      height: 40px;
  } 
  
  .rfq-form .form-group.data-v-8070b792  { 
      margin-bottom: 16px;
  } 
  
  .GS-btn { 
      display:  flex; 
      display:  -ms-flexbox; 
      display:  -webkit-box; 
      -webkit-box-align:  center; 
      -ms-flex-align:  center; 
      align-items:  center;
      -webkit-box-pack: center; 
      -ms-flex-pack: center; 
      justify-content: center; 
      padding: 0 12px; 
      height: 28px; 
      text-align: center; 
      border-radius: 100px; 
      border: 1px solid #ddd; 
      font-size: 12px; 
      color: #2d2d2d; 
      cursor: pointer; 
      background: #fff; 
      font-weight: 400;
  } 
  
  .GS-btn.on { 
      color: #fff; 
      border-color: transparent; 
      background: -webkit-gradient(linear,right top,left top,from(#fc573f),to(#e50113)); 
      background: linear-gradient(270deg,#fc573f,#e50113);
  } 
  
  .request-rfq.data-v-8070b792 { 
      background: -webkit-gradient(linear,right top,left top,from(#fc573f),to(#e50113))!important; 
      background: linear-gradient(270deg,#fc573f,#e50113)!important;
  } 
  
  .rfq-form .GS-btn.data-v-8070b792  { 
      display: -webkit-inline-box; 
      display: -ms-inline-flexbox; 
      display: inline-flex;
  } 
  
  .mod-rfq .rfq-form .GS-btn.data-v-8070b792  { 
      height: 40px; 
      line-height: 40px; 
      padding: 0 36px; 
      font-size: 16px; 
      font-weight: 500;
  } 
  
  .GS-btn:hover { 
      color: #fff; 
      border-color: transparent; 
      background: -webkit-gradient(linear,right top,left top,from(#fc573f),to(#e50113)); 
      background: linear-gradient(270deg,#fc573f,#e50113);
  } 
  
  .request-rfq.data-v-8070b792:hover { 
      background: -webkit-gradient(linear,right top,left top,from(#ff492f),to(#d10011))!important; 
      background: linear-gradient(270deg,#ff492f,#d10011)!important; 
      color: #fff!important;
  } 
  
  .rfq-info .tit.data-v-8070b792  { 
      color: #fff; 
      font-size: 24px; 
      font-weight: 500; 
      margin-bottom: 12px;
  } 
  
  .mod-rfq .rfq-info .tit.data-v-8070b792  { 
      margin-bottom: 30px;
  } 
  
  .rfq-info .from-txt.data-v-8070b792  { 
      color: #2d2d2d; 
      font-size: 14px; 
      font-weight: 400; 
      margin-bottom: 30px; 
      overflow: hidden; 
      position: relative;
  } 
  
  .rfq-info .rfq-list.data-v-8070b792  { 
      margin-bottom: 40px;
  } 
  
  .iconfont { 
      font-family: "iconfont" !important; 
      font-size: 16px; 
      font-style: normal; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
  } 
  
  .rfq-form .tit-box .iconfont.data-v-8070b792  { 
      font-size: 40px; 
      color: #e72528;
  } 
  
  .rfq-form .tit-box .tit.data-v-8070b792  { 
      font-size: 24px; 
      font-weight: 700;
  } 
  
  .form-group .form-item.data-v-8070b792  { 
      display: -webkit-box; 
      display: -ms-flexbox; 
      display: flex; 
      -webkit-box-flex: 1; 
      -ms-flex: 1; 
      flex: 1; 
      -webkit-box-align: center; 
      -ms-flex-align: center; 
      align-items: center; 
      height: 40px; 
      border-radius: 20px; 
      border: 1px solid #ddd; 
      margin-bottom: 16px; 
      padding: 0 16px;
  } 
  
  .form-group-inline.data-v-8070b792 { 
      display: -webkit-box; 
      display: -ms-flexbox; 
      display: flex;
  } 
  
  .carouse-component.data-v-daf61c1c { 
      position: relative; 
      overflow: hidden;
  } 
  
  .rfq-info .view-more.data-v-8070b792  { 
      cursor: pointer; 
      margin: 30px 0 14px; 
      color: #001389; 
      font-weight: 700; 
      font-size: 14px; 
      line-height: 36px; 
      background: -webkit-gradient(linear,left top,left bottom,from(hsla(0,0%,100%,.95)),to(hsla(0,0%,100%,.8))); 
      background: linear-gradient(180deg,hsla(0,0%,100%,.95),hsla(0,0%,100%,.8)); 
      border: 1px solid #fff; 
      border-radius: 4px; 
      width: 147px; 
      height: 36px; 
      text-align: center;
  } 
  
  .rfq-info .rfq-list .item.data-v-8070b792  { 
      margin-top: 4px; 
      font-size: 16px; 
      font-weight: 500; 
      color: #fff;
  } 
  
  .rfq-info .rfq-list .item.data-v-8070b792:before  { 
      display: none !important;
  } 
  
  input { 
      margin:    0; 
      color:  #2d2d2d;
      font-size:     100%; 
      font-family:     inherit; 
      line-height:   normal; 
      overflow:     visible;
      padding:   0; 
      -webkit-box-sizing:   border-box; 
      box-sizing:   border-box;
      outline:  none; 
      border:  none;
  } 
  
  .mod-rfq .rfq-form .text.data-v-8070b792  { 
      width: 100%;
  } 
  
  .form-group-inline .form-item.data-v-8070b792  { 
      margin-left: 16px;
  } 
  
  .form-group-inline .form-item.data-v-8070b792:first-child  { 
      margin-left: 0;
  } 
  
  .mod-dropdown.data-v-48065a1a { 
      display: -webkit-box; 
      display: -ms-flexbox; 
      display: flex; 
      -webkit-box-orient: vertical; 
      -webkit-box-direction: normal; 
      -ms-flex-direction: column; 
      flex-direction: column; 
      position: relative; 
      z-index: 2;
  } 
  
  .rfq-form .mod-dropdown.data-v-8070b792  { 
      width: 100%;
  } 
  
  .ant-carousel { 
      margin:  0; 
      padding:  0; 
      color:  rgba(0,0,0,.65); 
      font-size:  14px; 
      font-variant:  tabular-nums; 
      line-height:  1.5; 
      list-style:  none; 
      -webkit-font-feature-settings:  "tnum","tnum"; 
      font-feature-settings:  "tnum","tnum";
      -webkit-box-sizing: border-box; 
      box-sizing: border-box;
  } 
  
  .mod-dropdown .tit-box.data-v-48065a1a  { 
      display: -webkit-box; 
      display: -ms-flexbox; 
      display: flex; 
      -webkit-box-align: center; 
      -ms-flex-align: center; 
      align-items: center; 
      -webkit-box-pack: center; 
      -ms-flex-pack: center; 
      justify-content: center; 
      cursor: pointer;
  } 
  
  .rfq-form .mod-dropdown.data-v-8070b792 .tit-box  { 
      -webkit-box-pack: justify; 
      -ms-flex-pack: justify; 
      justify-content: space-between; 
      padding: 0;
  } 
  
  .mod-dropdown .dropdown-list.data-v-48065a1a  { 
      display: none; 
      position: absolute; 
      left: 0; 
      right: 0; 
      top: 100%; 
      border-radius: 4px; 
      background: #fff; 
      -webkit-box-shadow: 0 2px 8px 0 rgba(0,0,0,.12); 
      box-shadow: 0 2px 8px 0 rgba(0,0,0,.12); 
      overflow: hidden;
  } 
  
  .form-group .form-item.data-v-8070b792 .dropdown-list  { 
      max-height: 300px; 
      overflow: auto;
  } 
  
  .rfq-form .mod-dropdown.data-v-8070b792 .dropdown-list  { 
      margin-top: 15px;
  } 
  
  .ant-carousel .slick-slider  { 
      display: block; 
      -webkit-box-sizing:  border-box; 
      box-sizing:  border-box;
      position: relative; 
      -webkit-touch-callout: none; 
      -ms-touch-action: pan-y; 
      touch-action: pan-y; 
      -webkit-tap-highlight-color: transparent;
  } 
  
  .mod-dropdown .tit-box input.data-v-48065a1a  { 
      font-size: 14px; 
      color: #2d2d2d; 
      margin-right: 8px; 
      cursor: pointer;
  } 
  
  .mod-dropdown .tit-box .ico-drop.data-v-48065a1a  { 
      -webkit-transition: all .3s ease-in; 
      transition: all .3s ease-in;
  } 
  
  .ic_arrow_down_14:before { 
      content: "\e61b";
  } 
  
  .mod-dropdown .dropdown-list .dItem.data-v-48065a1a  { 
      display: -webkit-box; 
      display: -ms-flexbox; 
      display: flex; 
      -webkit-box-align: center; 
      -ms-flex-align: center; 
      align-items: center; 
      height: 32px; 
      padding-left: 15px;
  } 
  
  .mod-dropdown .dropdown-list .dItem.data-v-48065a1a:hover  { 
      background-color: #f6f6f6; 
      cursor: pointer;
  } 
  
  .ant-carousel .slick-list  { 
      position: relative; 
      display: block; 
      margin: 0; 
      padding: 0; 
      overflow: hidden;
  } 
  
  .ant-carousel .slick-slider .slick-list  { 
      -webkit-transform: translateZ(0); 
      transform: translateZ(0);
  } 
  
  .ant-carousel .slick-track  { 
      position: relative; 
      top: 0; 
      left: 0; 
      display: block;
  } 
  
  .ant-carousel .slick-slider .slick-track  { 
      -webkit-transform: translateZ(0); 
      transform: translateZ(0);
  } 
  
  .ant-carousel .slick-track:after ,.ant-carousel .slick-track:before  { 
      display: table; 
      content: "";
  } 
  
  .ant-carousel .slick-track:after  { 
      clear: both;
  } 
  
  .ant-carousel .slick-slide  { 
      display: none; 
      float: left; 
      height: 100%; 
      min-height: 1px;
  } 
  
  .ant-carousel .slick-list .slick-slide  { 
      pointer-events: none;
  } 
  
  .ant-carousel .slick-initialized .slick-slide  { 
      display: block;
  } 
  
  .ant-carousel .slick-vertical .slick-slide  { 
      display: block; 
      height: auto; 
      border: 1px solid transparent;
  } 
  
  .ant-carousel .slick-list .slick-slide.slick-active  { 
      pointer-events: auto;
  } 
  
  .rfq-info .from-txt .rfq-item.data-v-8070b792  { 
      display: -webkit-box; 
      display: -ms-flexbox; 
      display: flex;
  } 
  
  .rfq-info .from-txt .rfq-item .rfq-text.data-v-8070b792  { 
      color: #fff; 
      max-width: 418px; 
      font-size: 14px; 
      line-height: 20px; 
      word-wrap: break-word;
  } 
  
  .rfq-form .mod-dropdown.open .dropdown-list {
    margin: 15px -16px 0;
}



.mod-dropdown .dropdown-list.data-v-48065a1a {
    position: absolute;
    display: none;
    left: auto;
    right: 0;
    top: 28px;
    border-radius: 4px;
    background: #fff;
    -webkit-box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
    overflow: scroll;
    padding: 0;
    min-width: 150px;
}

.mod-dropdown.open .dropdown-list.data-v-48065a1a {
    display: block;
}

.form-group .form-item.data-v-8070b792 .dropdown-list {
    max-height: 300px;
    overflow: auto;
}

rfq-form .mod-dropdown.open .dropdown-list {
    margin: 15px -16px 0;
}

.mod-dropdown .dropdown-list .dItem.data-v-48065a1a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 32px;
    padding-left: 15px;
    list-style: none;
}

.dropdown-list-rfq {
    border: none !important;
    color: #757575;
}

.rfq-form.data-v-8070b792 {
    width: 100% !important;
    display: block;
}

@media screen and (min-width: 640px){
    .mod-rfq .rfq-form.data-v-8070b792 {
        padding: 40px !important;
        width: 530px !important;
    }
}